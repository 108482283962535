import { Inject, injectable } from 'inversify-props';
import { StoreS, StoreType } from '@/store/types';
import { VuexModule } from 'vuex-module-decorators';
import { DispatchOptions, SubscribeActionOptions, ActionPayload } from 'vuex';
import type { WatchOptions } from 'vue';

export const StoreFacadeS = Symbol.for('StoreFacadeS');
@injectable(StoreFacadeS as unknown as string)
export default class StoreFacade {
    @Inject(StoreS) private store!: StoreType;

    getState<T extends VuexModule>(vuexModuleName: string): T {
        return this.store.state[vuexModuleName];
    }

    dispatch(type: string, payload?: any, options?: DispatchOptions | undefined): Promise<any> {
        return this.store.dispatch(type, payload, options);
    }

    dispatchException(error: Error): Promise<any> {
        return this.dispatch('exception', error);
    }

    subscribeAction(handler: SubscribeActionOptions<ActionPayload, any>): () => void {
        return this.store.subscribeAction(handler);
    }

    watch(state: () => any, callback: ((value: any, oldValue: any) => any) | Array<(value: any, oldValue: any) => any>, opts?: WatchOptions) {
        return this.store.watch(state, async (value, oldValue) => {
            try {
                if (Array.isArray(callback)) {
                    await Promise.all(callback.map(async cb => {
                        await cb(value, oldValue);
                    }));
                } else {
                    await callback(value, oldValue);
                }
            } catch (error) {
                await this.dispatchException(error as Error);
            }
        }, opts);
    }
}
