import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '../common/services/api.service';
import TranslationsModel from './translations.model';

export const TranslationsApiServiceS = Symbol.for('TranslationsApiServiceS');
@injectable(TranslationsApiServiceS as unknown as string)
export default class TranslationsApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;

    async getTranslations() {
        const { data } = await this.apiService.get('/translations');
        return plainToClass(TranslationsModel, data, { excludeExtraneousValues: true });
    }

    async changeLocale(locale: string) {
        const { data } = await this.apiService.post('users/settings', {
            settings: { lang: locale },
        });

        return !!data;
    }
}
