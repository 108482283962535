import { injectable, Inject } from 'inversify-props';
import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import UserModel from '@/modules/user/models/user.model';
import { ZendeskWidget, ZendeskSettings, ZendeskEmbed } from './interfaces/zendesk.interface';

declare global {
    interface Window {
        zE: ZendeskWidget;
        zESettings: ZendeskSettings;
        zEmbed: ZendeskEmbed;
    }
}
export const ZendeskServiceS = Symbol.for('ZendeskServiceS');
@injectable(ZendeskServiceS as unknown as string)
export default class ZendeskService {
    @Inject(ConfigServiceS) private configService!: ConfigService;
    @Inject(UserServiceS) private userService!: UserService;
    zE: ZendeskWidget | null = null;
    chatButtonId: string = '';

    async init(chatButtonId: string) {
        const { isCarUser } = this.userService;
        const { zendeskKey, zendeskWidgetUrl } = this.configService;
        if (!zendeskKey || !chatButtonId) {
            return;
        }
        this.chatButtonId = chatButtonId;
        await this.loadScript(`${zendeskWidgetUrl}?key=${zendeskKey}`);
        const carForm = ['360000224298'];
        const diForm = ['360000180957'];
        const currentForm = isCarUser ? carForm : diForm;
        const forms: any = currentForm.map(form => ({ id: form }));
        const offset = {
            horizontal: '60px',
            vertical: '20px',
        };
        const color = '#51abff';
        window.zESettings = {
            webWidget: {
                offset,
                color: { theme: color },
                contactOptions: {
                    enabled: true,
                    contactButton: { '*': 'Get in touch' },
                },
                contactForm: {
                    subject: true,
                    ticketForms: forms,
                },
                chat: {
                    suppress: !!isCarUser,
                },
            },
        };
        window.zEmbed(this.embedInit.bind(this));
    }

    openChat() {
        if (window.zE) {
            window.zE('webWidget', 'open');
        }
    }

    private loadScript(src: string) {
        const scriptElement = document.createElement('script');
        scriptElement.id = 'ze-snippet';
        scriptElement.async = true;
        scriptElement.src = src;
        document.head.appendChild(scriptElement);
        return new Promise<void>(resolve => {
            scriptElement.addEventListener('load', () => {
                resolve();
            });
        });
    }

    private embedInit() {
        this.zE = window.zE;
        if (this.userService.user) {
            this.identifyUser(this.userService.user);
        }
        const chatButton = document.getElementById(this.chatButtonId);
        // Open/close widget by clicking on our chatButton
        this.zE('webWidget:on', 'open', () => {
            if (chatButton) {
                chatButton.classList.add('opened');
            }
        });
        this.zE('webWidget:on', 'close', () => {
            if (chatButton) {
                chatButton.classList.remove('opened');
            }
        });
        this.hideDefaultChatButton();
        this.showCustomChatButton();
    }

    private identifyUser(user: UserModel) {
        if (!this.zE) {
            return;
        }
        this.zE('webWidget', 'reset');
        const userName = `${user.firstName} ${user.lastName}`;
        const userEmail = user.email;
        window.zEmbed.identify({ name: userName, email: userEmail });
    }

    private hideDefaultChatButton(): void {
        const launcherInterval = setInterval(() => {
            const launcher = document.getElementById('launcher');
            if (launcher) {
                launcher.setAttribute('hidden', 'true');
                clearInterval(launcherInterval);
            }
        }, 50);
    }

    private showCustomChatButton(): void {
        const launcherInterval = setInterval(() => {
            const zendeskChatButton = document.getElementById(this.chatButtonId);
            if (zendeskChatButton) {
                zendeskChatButton.classList.remove('hidden');
                clearInterval(launcherInterval);
            }
        }, 50);
    }
}
