import { Inject, injectable } from 'inversify-props';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';

export const CustomNotificationApiServiceS = Symbol.for('CustomNotificationApiServiceS');
@injectable(CustomNotificationApiServiceS as unknown as string)
export default class CustomNotificationApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;

    async getExcelDocument(reportId: number) {
        const res = await this.apiService.get(
            `report/download/${reportId}`,
            {},
            { responseType: 'blob' },
        ).catch(() => null);

        if (!res || !res.data) {
            return null;
        }

        return res.data as Blob;
    }
}
