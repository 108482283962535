import { Inject, injectable } from 'inversify-props';
import { Route } from 'vue-router';
import { IMiddleware } from '@/router/interfaces/middleware.interface';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';

export const PaginationMiddlewareS = Symbol.for('PaginationMiddlewareS');
@injectable(PaginationMiddlewareS as unknown as string)
export default class PaginationMiddleware implements IMiddleware {
    @Inject(ClusterServiceS) private clusterService!: ClusterService;

    async canActivate(to: Route) {
        this.clusterService.skip = 0;
        return true;
    }
}
