import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import ProvidersModel from '@/modules/providers/models/providers.model';

export const ProvidersApiServiceS = Symbol.for('ProvidersApiServiceS');
@injectable(ProvidersApiServiceS as unknown as string)
export default class ProvidersApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;

    private filtersProvidersCache: Promise<any> | undefined;

    async getProviders(): Promise<ProvidersModel[] | null> {
        const { data } = await this.apiService.get('supported/providers');

        if (!data) {
            return [];
        }

        const providersDocument = plainToClass(ProvidersModel, <ProvidersModel[]> data, { excludeExtraneousValues: true });

        return providersDocument;
    }

    async getFilterProviders(force = false): Promise<string[]> {
        if (force) {
            this.filtersProvidersCache = undefined;
        }

        if (!this.filtersProvidersCache) {
            this.filtersProvidersCache = this.apiService.get('rate/filters/global?selected=providers');
        }

        const { data } = await this.filtersProvidersCache;

        if (!data || !data.providers) {
            return [];
        }

        return data.providers;
    }

    async getChainProviders(chainId: string, levelName: string | null) {
        const cluster = levelName ? `/${levelName}` : '';
        const { data } = await this.apiService.get(`supported/chain/provider/${chainId}${cluster}?filterType=clusters`);

        if (!data) {
            return null;
        }

        return {
            rates: data.rate_providers,
            markets: data.market_providers,
        };
    }
}
