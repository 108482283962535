import { Inject, injectable } from 'inversify-props';

import { i18n } from '@/modules/translations/translations.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';

import RatesStore from '@/modules/rates/store/rates.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';

import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '../room-types/room-types.service';
import RatesFiltersService, { RatesFiltersServiceS } from './rates-filters.service';
import DEFAULT_LOS from '../document-filters/constants/default-los.constant';
import DocumentFiltersModel from '../document-filters/models/document-filters.model';
import RatesSettingsModel from './models/rates-settings.model';

type Item = { value: string | number, name: string };

interface RatesAnalysisFiltersServicePublicInterface {
    /**
     * List of options for Past Period filter
     */
    comparisonDays: Item[];

    /**
     * Map of comparison fields and their names
     */
    comparisonFieldsDictionary: Map<string, string>;

    /**
     * List of providers for comparison filter
     */
    providerItems: Item[];

    /**
     * Readable name of comparison filter
     */
    filterComparisonName?: string;

    /**
     * Key of comparison filter
     */
    comparisonKey: string;

    /**
     * List of values for comparison filter
     */
    comparisonValues: { value: string | number, name: string }[];

    /**
     * List of filters
     */
    filterList: Item[];

    /**
     * Function that genereates options for current comparison filter
     */
    filterItems: Record<string, (wholeList: boolean) => Item[]>;

    /**
     * Returns main value label of selected comparison filter
     */
    mainCompareTitle: string | number | null;

    /**
     * Current options list of comparison filter
     */
    currentFilterItems: Item[];

    /**
     * Function that returns list of options for comparison filter except specified value
     */
    getFilterItemsExceptValue(filterKey: string, choosenValue: string | number | (string | number)[]): Item[];

    resetComparisonFilters(): void;
}

export const RatesAnalysisFiltersServiceS = Symbol.for('RatesAnalysisFiltersServiceS');
@injectable(RatesAnalysisFiltersServiceS as unknown as string)
export default class RatesAnalysisFiltersService implements Stateable, RatesAnalysisFiltersServicePublicInterface {
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @Inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @Inject(RatesFiltersServiceS) private ratesFilterService!: RatesFiltersService;

    readonly storeState: RatesStore = this.storeFacade.getState('RatesStore');

    comparisonDays: Item[] = [
        { value: 1, name: i18n.tc('titles.yesterday') },
        { value: 3, name: i18n.t('numDays', [3]).toString() },
        { value: 7, name: i18n.t('numDays', [7]).toString() },
        { value: 14, name: i18n.t('numDays', [14]).toString() },
    ];

    comparisonFieldsDictionary = new Map([
        ['diffDays', 'titles.pastPeriod'],
        ['provider', 'titles.provider'],
        ['mealTypeId', 'titles.mealType'],
        ['roomTypeId', 'titles.roomType'],
        ['los', 'titles.los'],
        ['pos', 'titles.pos'],
        ['numberOfGuests', 'titles.numberOfGuests'],
        ['priceType', 'titles.price'],
    ]);

    constructor() {
        this.storeFacade.watch(() => [
            this.documentFiltersService.storeState.settings.compsetId,
        ], this.resetComparisonFilters.bind(this));

        this.storeFacade.watch(() => [
            this.documentFiltersService.storeState.settings.pos,
            this.documentFiltersService.storeState.settings.los,

            this.ratesFilterService.storeState.settings.provider,
            this.ratesFilterService.storeState.settings.priceType,
            this.ratesFilterService.storeState.settings.roomTypeId,
            this.ratesFilterService.storeState.settings.mealTypeId,
            this.ratesFilterService.storeState.settings.numberOfGuests,
        ], this.reorderValueList.bind(this));

        this.storeFacade.watch(() => [
            this.storeState.analysis.settings.comparisonFilter.key,
        ], this.selectComparisonKey.bind(this));
    }

    get providerItems(): Item[] {
        const { currentCompset } = this.compsetsService;

        if (!currentCompset) {
            return [];
        }

        return this.providersService
            .toItemsList(currentCompset.rateProviders)
            .filter(({ value }) => value !== this.ratesFilterService.settings.provider);
    }

    get filterComparisonName() {
        return this.comparisonFieldsDictionary.get(this.comparisonKey);
    }

    get comparisonKey() {
        return this.storeState.analysis.settings.comparisonFilter.key;
    }

    set comparisonKey(value: string) {
        this.storeState.analysis.settings.comparisonFilter.key = value;
        this.comparisonValues = [];
    }

    get comparisonValues() {
        return this.storeState.analysis.settings.comparisonFilter.values;
    }

    set comparisonValues(values: { value: string | number, name: string }[]) {
        this.storeState.analysis.settings.comparisonFilter.values = values;

        document.dispatchEvent(new CustomEvent('compareValueChanged'));
    }

    get comparisonDayItems(): Item[] {
        return this.comparisonDays;
    }

    get filterList(): Item[] {
        return Array
            .from(this.comparisonFieldsDictionary)
            .filter(([key]) => this.filterItems[key]().length)
            .map(([value, name]) => ({ value, name: i18n.tc(name) }));
    }

    get filterItems(): Record<string, (wholeList?: boolean) => Item[]> {
        const { settings: ratesSettings } = this.ratesFilterService.storeState;
        const { settings: docSettings } = this.documentFiltersService.storeState;
        const { currentCompset } = this.compsetsService;

        const providers = Array.from(
            new Set(currentCompset ? currentCompset.rateProviders : []),
        );

        const itemLists: { [k: string]: () => Item[] } = {
            diffDays: () => this.comparisonDayItems,
            provider: (wholeList = false) => (wholeList
                ? this.providersService.toItemsList(providers)
                : this.providerItems).filter(p => !['all', 'cheapest'].includes(String(p.value))),

            mealTypeId: (wholeList = false) => this.mealTypesService.mealTypes
                .filter(m => wholeList || (m.id !== -1 && m.id !== ratesSettings.mealTypeId))
                .map(m => ({ value: m.id, name: i18n.tc(m.displayName) })),

            roomTypeId: (wholeList = false) => this.roomTypesService.rooms
                .filter(r => wholeList || (r.id !== -1 && r.id !== ratesSettings.roomTypeId))
                .map(r => ({ value: r.id, name: i18n.tc(r.name) })),

            los: (wholeList = false) => {
                const { losItems } = this.documentFiltersService;
                const defaultLosItems = DEFAULT_LOS.map(value => ({
                    value,
                    name: i18n.t('filters.los.num', [value]).toString(),
                })) as Item[];

                const { isScanDisabledProvider } = this.ratesFilterService;
                const targetList = isScanDisabledProvider
                    ? losItems
                    : defaultLosItems;

                if (wholeList) {
                    return targetList;
                }

                return targetList
                    .filter(l => l.value !== docSettings.los);
            },

            pos: (wholeList = false) => this.documentFiltersService.posRatesItems
                .filter(item => wholeList || item.value !== docSettings.pos),

            numberOfGuests: (wholeList = false) => Array.from({ length: 10 })
                .map((_, i) => ({
                    value: i + 1,
                    name: i18n.tc('filters.guests', i + 1, [i + 1]).toString(),
                }))
                .filter(nog => wholeList || nog.value !== ratesSettings.numberOfGuests),

            priceType: (wholeList = false) => Object
                .values(PRICE_TYPE)
                .filter(v => wholeList || v !== ratesSettings.priceType)
                .map(value => ({ value, name: i18n.tc(`price.${value}`) })),
        };

        return itemLists;
    }

    get mainCompareTitle() {
        const { comparisonKey } = this as {
            comparisonKey: keyof (DocumentFiltersModel & { diffDays: string } & RatesSettingsModel)
        };

        switch (comparisonKey) {
            case 'diffDays':
                return i18n.tc('titles.today');
            case 'los':
                return i18n.t('filters.los.num', [this.documentFiltersService.settings.los || 0]).toString();
            case 'pos':
                return this.documentFiltersService.settings.pos;
            case 'numberOfGuests':
                return i18n.tc('filters.guests', this.ratesFilterService.settings.numberOfGuests || 0, [this.ratesFilterService.settings.numberOfGuests || 0]).toString();
            default:
                return this.getMainComparisonFilterLabel();
        }
    }

    get currentFilterItems(): Item[] {
        return this.filterItems[this.comparisonKey]();
    }

    getFilterItemsExceptValue(filterKey: string, choosenValue: string | number | (string | number)[]) {
        if (filterKey === 'diffDays') {
            return this.filterItems[filterKey](true)
                .filter(({ value }) => value !== -1);
        }

        if (!Array.isArray(choosenValue)) {
            return this.filterItems[filterKey](true)
                .filter(({ value }) => value !== choosenValue && value !== -1);
        }

        return this.filterItems[filterKey](true)
            .filter(({ value }) => !choosenValue.includes(value) && value !== -1);
    }

    resetComparisonFilters() {
        // sessionStorage is used for see more functionality on insights page to set default value on reset.
        const key = sessionStorage.getItem('comparisonKey');
        const strValues = sessionStorage.getItem('comparisonValues');
        const values = strValues ? JSON.parse(strValues) : null;
        this.comparisonKey = key || 'diffDays';
        this.comparisonValues = values || this.comparisonDays.slice(0, 2);
        sessionStorage.removeItem('comparisonKey');
        sessionStorage.removeItem('comparisonValues');
        this.storeState.analysis.loading.reset();
    }

    private getComparisonValueLabel(needle: string | number) {
        const { comparisonKey } = this;

        const valueTypes: { [k: string]: () => any } = {
            diffDays: () => this.getItemLabel(needle),
            provider: () => this.providersService.getProviderLabel(needle.toString()),
            mealTypeId: () => {
                if (needle === -1) {
                    return i18n.tc('filters.any');
                }

                const typeName = this.mealTypesService.getMealType(Number(needle))!.displayName;

                return i18n.tc(typeName || 'unknown');
            },
            roomTypeId: () => {
                if (needle === -1) {
                    return i18n.tc('filters.any');
                }

                const typeName = this.roomTypesService.getRoomType(Number(needle))!.name;

                return i18n.tc(typeName || 'unknown');
            },
            los: () => this.getItemLabel(needle),
            pos: () => String(needle),
            numberOfGuests: () => i18n.tc('filters.guests', needle as number, [needle]),
            priceType: () => i18n.tc(`price.${needle as PRICE_TYPE}`, 0, [needle]),
        };

        return valueTypes[comparisonKey]();
    }

    private getMainComparisonFilterLabel() {
        const { comparisonKey } = this;
        const { settings } = this.documentFiltersService.ratesStoreState;

        switch (comparisonKey) {
            case 'provider':
                return settings.provider
                    ? this.providersService.getProviderLabel(settings.provider)
                    : '-';

            case 'mealTypeId': {
                const mealType = this.mealTypesService.getMealType(settings.mealTypeId);

                return mealType
                    ? i18n.tc(mealType.displayName)
                    : i18n.tc('filters.any');
            }

            case 'roomTypeId': {
                const roomType = this.roomTypesService
                    .getRoomType(settings.roomTypeId);

                return roomType
                    ? i18n.tc(roomType.name)
                    : i18n.tc('filters.any');
            }

            case 'numberOfGuests':
                return settings.numberOfGuests;

            case 'priceType':
                return i18n.tc(`price.${settings.priceType}`) as string;

            default: return '-';
        }
    }

    private selectComparisonKey() {
        const comparisonValues: { [k: string]: () => any } = {
            diffDays: () => this.comparisonDays[0].value,
            provider: () => {
                if (this.comparisonValues[0] && this.providerItems.find(item => item.value === this.comparisonValues[0].value)) {
                    return this.comparisonValues[0].value;
                }

                const providerItem = this.providerItems.shift();
                return providerItem ? providerItem.value : '';
            },
            mealTypeId: () => this.filterItems.mealTypeId().shift()!.value,
            roomTypeId: () => this.filterItems.roomTypeId().shift()!.value,
            los: () => this.filterItems.los().shift()!.value,
            pos: () => {
                const { pos: globalPos } = this.documentFiltersService.settings;
                const availablePoses = this.compsetsService.poses
                    .filter(pos => pos !== globalPos);

                return availablePoses[0] || 'US';
            },
            numberOfGuests: () => this.filterItems.numberOfGuests().shift()!.value,
            priceType: () => this.filterItems.priceType().shift()!.value,
        };
        const value = comparisonValues[this.comparisonKey]();
        const name = this.getComparisonValueLabel(value);

        // For insights `see more`. If comparisonKey is priceType and comarisonValues are correct then keep them.
        if (this.comparisonKey === 'priceType' && this.filterItems.priceType().find(item => item.value === this.comparisonValues[0]?.value)) {
            return;
        }

        this.comparisonValues = [{ name, value }];
    }

    private getItemLabel(needle: number | string) {
        const item = this.currentFilterItems.find(x => x.value === needle);
        return item ? item.name : null;
    }

    private reorderValueList(newValues: (string | number)[], oldValues: (string | number)[]) {
        const keys = ['pos', 'los', 'provider', 'priceType', 'roomTypeId', 'mealTypeId', 'numberOfGuests'];
        const changedField = keys.find((_, i) => newValues[i] !== oldValues[i]);
        this.storeState.analysis.loading.reset();

        if (!changedField) return;

        const isComparisonFieldChanged = this.comparisonKey === changedField;

        if (!isComparisonFieldChanged) return;

        const unionGlobalSettings: { [k: string]: any } = {
            ...this.documentFiltersService.storeState.settings,
            ...this.ratesFilterService.storeState.settings,
        };

        const globalValue = unionGlobalSettings[changedField];

        const isHaveGlobalPos = !!this.comparisonValues.find(item => item.value === globalValue);

        if (!isHaveGlobalPos) return;

        this.comparisonValues = this.comparisonValues
            .filter(item => item.value !== globalValue);

        if (!this.comparisonValues.length) {
            this.selectComparisonKey();
        }
    }
}
