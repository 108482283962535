import BadRequestException from '@/modules/common/modules/exception-handler/exceptions/bad-request.exception';
import RoomTypesApiService, { RoomTypesApiServiceS } from '@/modules/room-types/room-types-api.service';
import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import { validate, ValidationError } from 'class-validator';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import RoomTypesStore from '@/modules/room-types/store/room-types.store';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import { i18n } from '@/modules/translations/translations.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';

export const RoomTypesServiceS = Symbol.for('RoomTypesServiceS');
@injectable(RoomTypesServiceS as unknown as string)
export default class RoomTypesService implements Stateable {
    @Inject(RoomTypesApiServiceS) private roomTypesApiService!: RoomTypesApiService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;

    readonly storeState: RoomTypesStore = this.storeFacade.getState('RoomTypesStore');

    constructor() {
        this.storeFacade.watch(
            () => [this.userService.isUserLoaded, this.userService.currentHotelId],
            this.storeState.loading.reset.bind(this.storeState.loading),
        );
    }

    async loadData() {
        if (!this.userService.isCarUser) {
            const roomTypes = await this.roomTypesApiService.getRoomTypes();
            this.storeState.roomTypes = roomTypes || [];
            return true;
        }

        return false;
    }

    get realRooms(): RoomTypeModel[] | null {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));

        if (!this.storeState.roomTypes) {
            return null;
        }

        return this.storeState.roomTypes;
    }

    get rooms(): RoomTypeModel[] {
        return [ANY_ROOM_TYPE, ...(this.realRooms || [])];
    }

    get roomTypeItems() {
        if (!this.rooms) {
            return [];
        }
        return this.rooms.map(val => val.name).filter(val => val !== 'Any');
    }

    roomName(roomTypeId: number): string | null {
        if (!this.realRooms) {
            return null;
        }
        const room = this.realRooms.find(x => x.id === roomTypeId);
        return room ? room.name : null;
    }

    async deleteRoom(roomTypeId: number) {
        const roomTypes = await this.roomTypesApiService.deleteRoomType(roomTypeId);
        if (roomTypes) {
            this.storeState.roomTypes = roomTypes;
        }
    }

    async addRoomType(roomName: string): Promise<ValidationError[]> {
        const room = plainToClass(RoomTypeModel, { id: -1, name: roomName });
        let validationErrors: ValidationError[] = await validate(room);

        if (validationErrors.length > 0) {
            return validationErrors;
        }

        try {
            const roomType = await this.roomTypesApiService.addRoomType(roomName);

            if (roomType !== null && this.storeState.roomTypes) {
                this.storeState.roomTypes = [
                    ...this.storeState.roomTypes,
                    roomType,
                ];
            }
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }
        return validationErrors;
    }

    async editRoomType(roomType: RoomTypeModel): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = await validate(roomType);

        if (validationErrors.length > 0) {
            return validationErrors;
        }

        try {
            const updatedRooms = await this.roomTypesApiService.editRoomType(roomType);
            if (updatedRooms !== null) {
                this.storeState.roomTypes = updatedRooms;
            }
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }

        return validationErrors;
    }
    // TODo: check solution for validation
    updateValidationErrors(validationErrors: ValidationError[], message: string) :ValidationError[] {
        const error = new ValidationError();
        error.constraints = {
            message,
        };
        return [...validationErrors, ...[error]];
    }

    getRoomType(id: number | string) {
        if (typeof id === 'string') {
            return this.realRooms && (this.realRooms.find(item => item.name === id) || null);
        }

        return this.realRooms && (this.realRooms.find(item => item.id === id) || null);
    }

    getIdByName(name: string) {
        return this.realRooms && (this.realRooms.find(item => item.name.toLowerCase() === name.toLowerCase()) || null);
    }
}
