import { Inject, injectable } from 'inversify-props';
import CarsFilterApiService, { CarsFilterApiServiceS } from '@/modules/cars/cars-filter-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import {
    MILEAGE_ANY, TRANSMISSION_ANY, PAYMENT_TERMS_ANY, CAR_CLASS_ANY, COUNTRIES_ANY,
} from '@/modules/cars/constants/car-filter-types.constant';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import FleetFiltersStore from '@/modules/cars/modules/fleet/store/fleet-filters.store';
import FleetService, { FleetServiceS } from '@/modules/cars/modules/fleet/fleet.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import ParitySettingsService, { ParitySettingsServiceS } from '@/modules/cars/modules/parity/parity-settings.service';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-filters.service';
import restrictCarsFilters from '@/modules/cars/utils/restrict-cars-filters.util';

export const FleetFilterServiceS = Symbol.for('FleetFilterServiceS');
@injectable(FleetFilterServiceS as unknown as string)
export default class FleetFilterService {
    @Inject(CarsFilterApiServiceS) private carFilterApiService!: CarsFilterApiService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(FleetServiceS) private fleetService!: FleetService;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;
    @Inject(LocationAvailabilityFiltersServiceS) private lAvailabilityFiltersService!: LocationAvailabilityFiltersService;

    readonly storeState: FleetFiltersStore = this.storeFacade.getState('FleetFiltersStore');

    private tempHideLocations = [
        'Lyft Los Angeles 3',
        'Lyft Los Angeles 4',
        'Lyft Los Angeles 5',
        'Lyft Los Angeles 6',
        'Lyft Los Angeles 11',
        'Lyft Los Angeles 8',
        'Lyft Los Angeles 10',
        'Lyft Los Angeles 9',
        'Lyft Los Angeles 7',
        'Lyft Los Angeles 13',
        'Lyft Los Angeles 12',
        'Lyft Los Angeles 2',
        'Lyft San Francisco 1',
        'Lyft San Francisco 2',
        'Lyft Los Angeles 1',
        'Lyft New York 1',
        'Lyft New York 2',
        'Lyft New York 4',
        'Lyft Atlanta 1',
        'Lyft Chicago 3',
        'Lyft Chicago 2',
        'Lyft Chicago 1',
        'Lyft Atlanta 2',
    ];

    constructor() {
        if (this.userService.isCarUser) {
            this.storeFacade.watch(() => this.settings, this.initCurrentFilterValues.bind(this));
        }

        this.storeFacade.watch(() => this.carsService.storeState.settings.chain, async () => {
            await this.loadFilters();
            this.fleetService.storeState.loading.reset();
        });

        this.storeFacade.watch(() => this.fleetService.storeState.document, async () => {
            this.initCompetitorFilters();
            this.initCarClasses();
            this.initTransmissionFilter();
            this.initMileageFilter();
            this.initPaymentTermsFilter();
        });
    }

    async loadFilters() {
        const chain = this.carsService.currentChain;

        let filters = await this.carFilterApiService.getCarFilters(chain);

        if (!filters) {
            return false;
        }

        if (filters.locations) {
            filters.locations = filters.locations.filter(
                item => this.tempHideLocations.findIndex(hideItem => hideItem.toLowerCase() === item.locationName.toLowerCase()) === -1,
            );
        }

        const userRestrictions = this.userService.storeState.user || { countries: [], locations: [] };
        filters = restrictCarsFilters(filters, userRestrictions);

        this.storeState.settings = filters;
        return true;
    }

    get settings() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadFilters.bind(this));
        return this.storeState.settings;
    }

    get competitors() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadFilters.bind(this));
        return this.fleetService.storeState.settings.competitors;
    }

    async initCurrentFilterValues() {
        await this.initPickUpCity();
        await this.initDataSource();
        await this.initLor();
        await this.initPos();
        await this.initCountries();
    }

    async initPickUpCity() {
        const { locations } = this.settings;

        if (locations && locations.length) {
            const [defaultLocation] = locations;
            this.fleetService.storeState.settings.pickUpCityCode = defaultLocation.locationId;
        }
    }

    filterPickUpCitiesByCountry(country: string) {
        const {
            locations, countries, routes, availability,
        } = this.storeState.settings;

        if (!locations || !routes || !availability) {
            return [];
        }

        const availableLocations = locations.filter(location => {
            const isAvailableRoute = routes.find(route => route.pickUpLocationId === location.locationId);
            const isAvailableGroup = availability.find(group => group.id === location.locationId);

            return isAvailableGroup && isAvailableRoute;
        });

        if (!country || country === COUNTRIES_ANY || !countries) {
            return availableLocations;
        }

        const selectedCountryCodes = countries[country];

        return availableLocations.filter(location => selectedCountryCodes.find(code => code === location.locationId));
    }

    async initDataSource() {
        const { dataSources } = this.settings;

        if (dataSources && dataSources.length) {
            const sources = dataSources.filter(item => item !== 'Brand');
            const [defaultDataSource] = sources;
            this.fleetService.storeState.settings.dataSource = defaultDataSource;
            this.fleetService.storeState.settings.dataSources = dataSources;
        }
    }

    get availableDataSources() {
        const { pickUpCityCode } = this.fleetService.storeState.settings;
        const { availability } = this.storeState.settings;

        if (!pickUpCityCode || !availability) {
            return null;
        }

        const currentLocation = availability.find(location => location.id === pickUpCityCode);

        if (!currentLocation) {
            return [];
        }

        const availableDataSources = Object.keys(currentLocation.path);
        return availableDataSources;
    }

    async initLor() {
        const { lor } = this.settings;

        if (lor && lor.length) {
            const [defaultLor] = lor;
            this.fleetService.storeState.settings.lor = defaultLor;
        }
    }

    get availableLors() {
        const { pickUpCityCode, pos } = this.fleetService.storeState.settings;
        const { availability } = this.storeState.settings;
        const { dataSource } = this.fleetService.storeState.settings;

        if (!availability || !dataSource || !pos) {
            return [];
        }

        const currentLocation = availability.find(location => location.id === pickUpCityCode);

        if (!currentLocation) {
            return [];
        }

        const availableLoks = currentLocation.path[dataSource][pos];

        return Object.keys(availableLoks).map(lok => {
            if (availableLoks[lok]) {
                return lok;
            }

            return null;
        }).filter(lok => lok) as string[];
    }

    async initPos() {
        const { pos } = this.settings;

        if (pos && pos.length) {
            const [defaultPos] = pos;
            this.fleetService.storeState.settings.pos = defaultPos;
        }
    }

    get availablePos() {
        const carSettings = this.fleetService.storeState.settings;
        const { availability } = this.storeState.settings;

        if (!availability) {
            return null;
        }

        const currentDoc = availability.find(doc => doc.id === carSettings.pickUpCityCode);

        if (!currentDoc || !carSettings.dataSource) {
            return null;
        }

        const availablePos = Object.keys(currentDoc.path[carSettings.dataSource]);

        if (!availablePos) {
            return null;
        }

        return availablePos;
    }

    get countriesFilter(): string[] {
        const { countries } = this.storeState.settings;

        if (!countries) {
            return [COUNTRIES_ANY];
        }

        const countryNames = new Set([COUNTRIES_ANY].concat(Object.keys(countries)));

        return Array.from(countryNames);
    }

    async initCountries() {
        this.fleetService.storeState.settings.country = COUNTRIES_ANY;
    }

    get competitorsFilter(): string[] {
        const { currentCompany } = this.userService;

        const filter = new Set();
        const { document } = this.fleetService.storeState;

        if (!document || currentCompany === null) {
            return Array.from(filter) as string[];
        }

        const { providers } = document;

        if (!providers) {
            return Array.from(filter) as string[];
        }

        Object.entries(providers).forEach(([, pickup]) => {
            const { pickupDates } = pickup;
            Object.entries(pickupDates).forEach(([_, day]) => {
                if (!day) {
                    return;
                }

                Object.keys(day).forEach(companyName => {
                    filter.add(companyName);
                });
            });
        });
        const competitorsFilter = Array.from(filter) as string[];
        return competitorsFilter.filter(item => item !== currentCompany);
    }

    initCompetitorFilters() {
        this.fleetService.storeState.settings.competitors = this.competitorsFilter.length
            ? this.competitorsFilter
            : null;
    }

    get carClassFilter(): string[] {
        const filter = new Set();
        const { currentCompany } = this.userService;
        const { document } = this.fleetService.storeState;

        if (!document || !currentCompany === null) {
            return Array.from(filter) as string[];
        }

        const { providers } = document;

        if (!providers) {
            return Array.from(filter) as string[];
        }
        Object.entries(providers).forEach(([, pickup]) => {
            const { pickupDates } = pickup;
            if (pickupDates) {
                Object.entries(pickupDates).forEach(([, day]) => {
                    Object.entries(day).forEach(([, company]) => {
                        Object.keys(company).forEach(carClass => {
                            filter.add(carClass);
                        });
                    });
                });
            }
        });

        return Array.from(filter) as string[];
    }

    initCarClasses() {
        this.fleetService.storeState.settings.carClasses = this.carClassFilter.filter(carClass => carClass !== 'Commercial');
    }

    getLocationCodeByName(name: string) {
        const { locations } = this.storeState.settings;

        if (!locations || !locations.length) {
            return null;
        }

        const defaultLocation = locations.find(loc => loc.locationName === name);
        return defaultLocation ? defaultLocation.locationId : null;
    }

    get addPaymentTerms() {
        const { fleetDocument } = this.fleetService;
        if (!fleetDocument) {
            return [];
        }
        const { providers } = fleetDocument;
        if (!providers) {
            return [];
        }

        const paymentTerms = new Set([PAYMENT_TERMS_ANY]);
        Object.entries(providers).forEach(([, pickup]) => {
            const { pickupDates } = pickup;
            if (pickupDates) {
                Object.entries(pickupDates).forEach(([, day]) => {
                    Object.entries(day).forEach(([, company]) => {
                        Object.entries(company).forEach(([, carClass]) => {
                            Object.entries(carClass).forEach(([, transmisson]) => {
                                Object.entries(transmisson).forEach(([, miliage]) => {
                                    Object.entries(miliage).forEach(([paymentKey]) => {
                                        paymentTerms.add(paymentKey);
                                    });
                                });
                            });
                        });
                    });
                });
            }
        });
        return (Array.from(paymentTerms) as string[]).map(item => ({
            value: item,
            disabled: false,
        }));
    }

    initPaymentTermsFilter() {
        const currentFilterValue = this.fleetService.storeState.settings.paymentTerms;

        if (!this.addPaymentTerms.find(filterValue => filterValue.value === currentFilterValue)) {
            const [defaultPaymentTerm] = this.addPaymentTerms.filter(option => !option.disabled);
            this.fleetService.storeState.settings.paymentTerms = defaultPaymentTerm.value;
        }
    }

    get transmissionFilter() {
        const { fleetDocument } = this.fleetService;
        if (!fleetDocument) {
            return [];
        }
        const { providers } = fleetDocument;
        if (!providers) {
            return [];
        }

        const transmissions = new Set([TRANSMISSION_ANY]);
        Object.entries(providers).forEach(([, pickup]) => {
            const { pickupDates } = pickup;
            if (pickupDates) {
                Object.entries(pickupDates).forEach(([, day]) => {
                    Object.entries(day).forEach(([, company]) => {
                        Object.entries(company).forEach(([, carClass]) => {
                            Object.entries(carClass).forEach(([transmissionKey]) => {
                                transmissions.add(transmissionKey);
                            });
                        });
                    });
                });
            }
        });
        return (Array.from(transmissions) as string[]).map(item => ({
            value: item,
            disabled: false,
        }));
    }
    initTransmissionFilter() {
        const currentFilterValue = this.fleetService.storeState.settings.transmission;

        if (!this.transmissionFilter.find(filterValue => filterValue.value === currentFilterValue)) {
            const [defaultTransmissionFilter] = this.transmissionFilter;
            this.fleetService.storeState.settings.transmission = defaultTransmissionFilter.value;
        }
    }

    get mileageFilter() {
        const { fleetDocument } = this.fleetService;
        if (!fleetDocument) {
            return [];
        }
        const { providers } = fleetDocument;
        if (!providers) {
            return [];
        }

        const mileages = new Set([MILEAGE_ANY]);
        Object.entries(providers).forEach(([, pickup]) => {
            const { pickupDates } = pickup;
            if (pickupDates) {
                Object.entries(pickupDates).forEach(([, day]) => {
                    Object.entries(day).forEach(([, company]) => {
                        Object.entries(company).forEach(([, carClass]) => {
                            Object.entries(carClass).forEach(([, transmisson]) => {
                                Object.entries(transmisson).forEach(([miliageKey, miliage]) => {
                                    mileages.add(miliageKey);
                                });
                            });
                        });
                    });
                });
            }
        });
        return (Array.from(mileages) as string[]).map(item => ({
            value: item,
            disabled: false,
        }));
    }

    initMileageFilter() {
        const currentFilterValue = this.fleetService.storeState.settings.mileage;

        if (!this.mileageFilter.find(filterValue => filterValue.value === currentFilterValue)) {
            const [defaultMileageFilter] = this.mileageFilter.filter(option => !option.disabled);
            this.fleetService.storeState.settings.mileage = defaultMileageFilter.value;
        }
    }

    saveLor(value: number) {
        this.fleetService.storeState.settings.lor = value;
        this.carsService.storeState.settings.lor = value;
    }

    saveCarClasses(value: string[]) {
        this.carsService.storeState.settings.carClasses = value;
        this.fleetService.storeState.settings.carClasses = value;
        this.paritySettingsService.carClasses = value;
    }

    saveMileage(value: string) {
        this.fleetService.storeState.settings.mileage = value;
        this.carsService.storeState.settings.mileage = value;
        this.initMileageFilter();
    }

    savePaymentTerms(value: string) {
        this.fleetService.storeState.settings.paymentTerms = value;
        this.carsService.storeState.settings.paymentTerms = value;
        this.initPaymentTermsFilter();
    }

    saveTransmisson(value: string) {
        this.fleetService.storeState.settings.transmission = value;
        this.carsService.storeState.settings.transmission = value;
        this.initTransmissionFilter();
    }

    saveCompetitors(value: string[]) {
        this.carsService.storeState.settings.competitors = value;
        this.fleetService.storeState.settings.competitors = value;
    }
}
