import { ObjectDirective } from 'vue';

export enum TOOLTIP_DIRECTION {
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
    BOTTOM = 'bottom',
}

const DEFAULT_POSITION = TOOLTIP_DIRECTION.TOP;
const DEFAULT_OFFSET = 10;
const DEFAULT_DELAY = 300;

let tooltipTimer: NodeJS.Timeout | null = null;

type TooltipElement = HTMLElement & { tooltipHandler: (e: MouseEvent) => void };

type TooltipParams = {
    value: {
        header: string;
        text: string;
        position: TOOLTIP_DIRECTION;
    } | string;
};

const showTooltip = (params: TooltipParams, e: MouseEvent) => {
    if (tooltipTimer) {
        clearTimeout(tooltipTimer);
    }

    const element = e.target as HTMLElement;

    tooltipTimer = setTimeout(() => {
        const tooltip = document.getElementById('custom-tooltip-directive');
        const pos = (typeof params.value === 'string' || !params.value.position)
            ? DEFAULT_POSITION
            : params.value.position;

        if (!tooltip) {
            return;
        }

        const tooltipHeader = document.getElementById('custom-tooltip-directive-header');
        const tooltipBody = document.getElementById('custom-tooltip-directive-body');
        const tooltipArrow = tooltip.getElementsByClassName('arrow')[0] as HTMLElement;

        // Set params to tooltip header/body depends on params type
        if (typeof params.value === 'string') {
            if (tooltipBody) {
                tooltipBody.innerHTML = params.value;
            }

            if (tooltipHeader) {
                tooltipHeader.style.display = 'none';
            }
        } else {
            if (params.value.header && tooltipHeader) {
                tooltipHeader.innerHTML = params.value.header;
            } else if (tooltipHeader) {
                tooltipHeader.style.display = 'none';
            }

            if (params.value.text) {
                if (tooltipBody) {
                    tooltipBody.innerHTML = params.value.text;
                }
            }
        }

        tooltip.classList.add('visible');
        const {
            top,
            bottom,
            left,
        } = element.getBoundingClientRect();

        tooltip.style.top = 'auto';
        tooltip.style.left = 'auto';
        tooltip.style.bottom = 'auto';
        tooltip.style.right = 'auto';

        tooltipArrow.style.top = 'auto';
        tooltipArrow.style.left = 'auto';
        tooltipArrow.style.bottom = 'auto';
        tooltipArrow.style.right = 'auto';

        const getTooltipAverageLeftCoord = () => {
            const elWidth = element.offsetWidth;
            const tooltipWidth = tooltip.offsetWidth;
            return left - (tooltipWidth - elWidth) / 2;
        };

        const getArrowAverageLeftCoord = () => {
            const tooltipWidth = tooltip.offsetWidth;
            const arrowWidth = tooltipArrow.offsetWidth;
            return tooltipWidth / 2 - arrowWidth / 2;
        };

        switch (pos) {
            case TOOLTIP_DIRECTION.BOTTOM:
                tooltip.style.top = `${bottom + DEFAULT_OFFSET}px`;
                tooltip.style.left = `${getTooltipAverageLeftCoord()}px`;
                tooltipArrow.style.top = `${-DEFAULT_OFFSET / 2}px`;
                tooltipArrow.style.transform = 'rotate(-45deg)';
                tooltipArrow.style.left = `${getArrowAverageLeftCoord()}px`;
                break;
            case TOOLTIP_DIRECTION.LEFT:
                // [TODO] implement
                break;
            case TOOLTIP_DIRECTION.RIGHT:
                // [TODO] implement
                break;
            default:
                // Default is TOOLTIP_DIRECTION.TOP
                tooltip.style.left = `${getTooltipAverageLeftCoord()}px`;
                tooltip.style.bottom = `${window.innerHeight - top + DEFAULT_OFFSET}px`;
                tooltipArrow.style.bottom = `${-DEFAULT_OFFSET / 2}px`;
                tooltipArrow.style.transform = 'rotate(45deg)';
                tooltipArrow.style.left = `${getArrowAverageLeftCoord()}px`;
                break;
        }
    }, DEFAULT_DELAY);
};

const hideTooltip = () => {
    if (tooltipTimer) {
        clearTimeout(tooltipTimer);
    }

    const tooltip = document.getElementById('custom-tooltip-directive');
    if (!tooltip) {
        return;
    }

    tooltip.classList.remove('visible');
};

export default {
    inserted: (el: TooltipElement, params: Record<string, any>) => {
        // eslint-disable-next-line no-param-reassign
        el.tooltipHandler = showTooltip.bind(this, params as TooltipParams);
        el.addEventListener('mouseenter', el.tooltipHandler);
        el.addEventListener('mouseleave', hideTooltip);
    },
    unbind: (el: TooltipElement) => {
        el.removeEventListener('mouseenter', el.tooltipHandler);
        el.removeEventListener('mouseleave', hideTooltip);
    },
} as ObjectDirective;
