import { Inject, injectable } from 'inversify-props';
import CarsAlertsApiService, { CarsAlertsApiServiceS } from '@/modules/cars/alerts/cars-alerts-api.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import CarsNotificationsStore from '@/modules/cars/alerts/store/cars-notifications-store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';

export const CarsAlertsServiceS = Symbol.for('CarsAlertsServiceS');
@injectable(CarsAlertsServiceS as unknown as string)
export default class CarsAlertsService implements Stateable {
    @Inject(CarsAlertsApiServiceS) private alertsApiService!: CarsAlertsApiService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;

    readonly storeState: CarsNotificationsStore = this.storeFacade.getState('CarsNotificationsStore');

    constructor() {
        this.storeFacade.watch(
            () => this.userService.storeState.user,
            this.storeState.loading.reset.bind(this.storeState.loading),
        );
    }

    get alerts() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.alerts.concat().sort((a, b) => b.date.getTime() - a.date.getTime());
    }

    async loadData() {
        const alerts = this.userService.isCarUser ? await this.alertsApiService.getCarAlerts() : null;
        this.storeState.alerts = alerts || [];
        return true;
    }

    async markAlertAsRead(id: string) {
        await this.alertsApiService.markAlertAsRead(id);

        this.alerts
            .find(a => a.id === id)!
            .read = true;
    }

    async markAllAlertsAsRead() {
        const alertIds = this.alerts.map(a => a.id);
        await this.alertsApiService.markAlertsAsRead(alertIds);

        this.storeState.loading.reset();
    }

    isLoading() {
        return this.storeState.loading.isLoading();
    }
}
