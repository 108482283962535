import { container } from 'inversify-props';
import { Route } from 'vue-router';
import RatesFiltersMiddleware, { RatesFiltersMiddlewareS } from '@/router/middlewares/rates-filters.middleware';
import use from '@/router/use';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import { CommonMenuRoutes } from '../common.routes';
import {
    RatesAnalysisRoutes,
    RatesPriceHistory,
    DownloadExcelPopup,
} from '../rates.routes';

export function ScheduledReports(prefix: string) {
    return {
        name: `${prefix}.scheduled-reports`,
        path: 'scheduled-reports',
        component: () => import('@/modules/rates/pages/rates-report.modal.page.vue'),
        meta: { modal: true },
    };
}

export default function RatesHotelLevelRoutes(basePath: string, prefix: string) {
    const userType = basePath.split('/').shift();
    const CommonRoutes = (pref: string) => [
        RatesPriceHistory(pref),
        DownloadExcelPopup(pref),
        ScheduledReports(pref),
        ...CommonMenuRoutes(pref),
    ];

    return {
        path: basePath,
        component: () => import('@/modules/common/pages/rates-hotel-transition.page.vue'),
        props: (r: Route) => ({
            hotelId: Number(r.params.hotelId),
            clusterId: Number(r.params.clusterId),
            userType,
        }),
        beforeEnter: use([
            container.get<RatesFiltersMiddleware>(RatesFiltersMiddlewareS),
            container.get<FeaturesGuard>(FeaturesGuardS),
        ]),
        children: [
            {
                name: prefix,
                path: '',
                component: () => import('@/modules/rates/pages/rates-calendar.page.vue'),
                children: CommonRoutes(prefix),
            },

            {
                name: `${prefix}.table`,
                path: 'table',
                component: () => import('@/modules/rates/pages/rates-table.page.vue'),
                children: CommonRoutes(`${prefix}.table`),
            },
            {
                name: `${prefix}.graph-compset`,
                path: 'graph-compset',
                component: () => import('@/modules/rates/pages/rates-graph-compset.page.vue'),
                children: CommonRoutes(`${prefix}.graph-compset`),
            },
            {
                name: `${prefix}.graph-hotels`,
                path: 'graph-hotels',
                component: () => import('@/modules/rates/pages/rates-graph-hotels.page.vue'),
                children: CommonRoutes(`${prefix}.graph-hotels`),
            },

            ...RatesAnalysisRoutes(prefix),
        ],
    };
}
