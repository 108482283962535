import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import MarketsDocumentModel from '@/modules/markets/models/markets-document.model';
import MarketSettingsModel from '@/modules/markets/models/market-settings.model';
import MarketsHistoryModel from '../models/markets-history.model';

@Module
export default class MarketsHistoryStore extends VuexModule {
    document: MarketsHistoryModel | null = null;
    loading: LoadingModel = new LoadingModel(false);

    dayIndex: number = 0;
    lastScanDate: Date | null = null;

    // last chartJs tooltip position, to save it between price history openings
    lastTooltipPos: string | null = null;

    marketsDocument: MarketsDocumentModel | null = null;
    marketSettings: MarketSettingsModel | null = null;
}
