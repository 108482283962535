import { snakeCase } from 'lodash';
import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';

import Percent from '@/modules/common/types/percent.type';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import CompsetModel from '@/modules/compsets/models/compset.model';
import CacheService, { CacheServiceS, MODULES, MODULE_METHODS } from '../common/services/cache/cache.service';

import { CompsetCreationBody } from './interfaces/compset-creation-body.interface';
import { NetCalculationSettings } from './interfaces';

export const CompsetsApiServiceS = Symbol.for('CompsetsApiServiceS');

@injectable(CompsetsApiServiceS as unknown as string)
export default class CompsetsApiService {
    @Inject(ApiServiceS)
    private apiService!: ApiService;

    @Inject(CacheServiceS)
    private cacheService!: CacheService;

    constructor() {
        this.getCompsetsReq = this.cacheService
            .memorize(
                MODULES.COMPSETS,
                MODULE_METHODS[MODULES.COMPSETS].getCompsetsReq,
                this.getCompsetsReq.bind(this),
            );
    }

    async getCompsetsReq(fornovaId: number) {
        const { data } = await this.apiService.get(`users/compsets/${fornovaId}`);

        if (!data) {
            return null;
        }

        return data || null;
    }

    async getCompsets(fornovaId: number): Promise<CompsetModel[] | null> {
        const data = await this.getCompsetsReq(fornovaId);

        if (!data) return null;

        const compsetDocument = plainToClass(
            CompsetModel,
            <CompsetModel[]> data.compSet,
            { excludeExtraneousValues: true },
        );

        return compsetDocument;
    }

    async updateCompsets(updatedCompsets: CompsetModel[]): Promise<CompsetModel[] | null> {
        const body = updatedCompsets.map(compset => ({
            _id: compset.id,
            name: compset.name,
            type: compset.type,
            competitors: compset.competitors,
        }));

        const { data } = await this.apiService.put('users/compsets', body);

        if (!data) {
            return null;
        }

        this.cacheService.erase(MODULES.COMPSETS, MODULE_METHODS[MODULES.COMPSETS].getCompsetsReq);

        return plainToClass(CompsetModel, <CompsetModel[]> data, { excludeExtraneousValues: true });
    }

    async updateCompsetLos(updatedCompset: CompsetModel): Promise<CompsetModel[] | null> {
        const body = [{
            _id: updatedCompset.id,
            name: updatedCompset.name,
            type: updatedCompset.type,
            competitors: updatedCompset.competitors,
            los: updatedCompset.los,
        }];

        const { data } = await this.apiService.put('users/compsets', body);

        if (!data) {
            return null;
        }

        return plainToClass(CompsetModel, <CompsetModel[]> data, { excludeExtraneousValues: true });
    }

    async updateThresholds(compsetId: string, minThreshold: Percent, maxThreshold: Percent) {
        await this.apiService.put(`/compsets/${compsetId}/thresholds/`, {
            min_threshold: Math.floor(minThreshold * 100),
            max_threshold: Math.floor(maxThreshold * 100),
        });
    }

    async createCompset(compsetData: CompsetCreationBody) {
        const snakeCasedEntries = Object
            .entries(compsetData)
            .map(([key, value]) => [snakeCase(key), value]);

        const compsetBody = Object.fromEntries(snakeCasedEntries);
        await this.apiService.post('/compsets', compsetBody);
    }

    async updateNetCalculationSettings(hotelId: number, compsetId: string, payload: NetCalculationSettings) {
        const { data } = await this.apiService
            .put(`users/compsets-settings/${hotelId}/${compsetId}`, {
                settings: {
                    netRateVat: payload,
                },
            });

        return data;
    }
}
