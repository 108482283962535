import { Inject, injectable } from 'inversify-props';

import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import arraysAreIdentical from '@/modules/common/filters/arrays-are-identical';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import type Day from '@/modules/common/types/day.type';
import Stateable from '@/modules/common/interfaces/stateable.interface';

import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import ClusterApiService, { ClusterApiServiceS } from '@/modules/cluster/cluster-api.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import ClusterStore from './store/cluster.store';
import DocumentFiltersModel from '../document-filters/models/document-filters.model';
import ProvidersService, { ProvidersServiceS } from '../providers/providers.service';
import PRICE_TYPE from '../document-filters/constants/price-type.constant';
import RatesDocumentItemModel from '../rates/models/rates-document-item.model';
import PRICE from '../common/modules/rates/constants/price.enum';
import PRICE_SHOWN from '../rates/constants/price-shown.constant';
import HotelRooms from '../common/interfaces/hotelRooms.interface';
import ChainService, { ChainServiceS } from '../chain/chain.service';
import CustomNotificationService, { CustomNotificationServiceS } from '../common/modules/custom-notification/custom-notification.service';
import DownloadExcelModel from '../rates/models/download-excel.model';
import PAGES from '../common/constants/pages.constant';
import RatesSettingsModel from '../rates/models/rates-settings.model';
import UserSettingsService, { UserSettingsS } from '../user/user-settings.service';
import ClusterHotelsRatesModel from './models/cluster-rates.model';
import RatesStore from '../rates/store/rates.store';
import { PriceScheme } from '../rates/interfaces/price-scheme.interface';
import { SCAN_STATUS } from '../rates/constants';
import RatesDocumentModel from '../rates/models/rates-document.model';
import MealTypesService, { MealTypesServiceS } from '../meal-types/meal-types.service';

export const ClusterRatesServiceS = Symbol.for('ClusterRatesServiceS');
@injectable(ClusterRatesServiceS as unknown as string)
export default class ClusterRatesService implements Stateable {
    @Inject(ClusterCompsetsServiceS) private clusterCompsetsService!: ClusterCompsetsService;
    @Inject(ClusterApiServiceS) private clusterApiService!: ClusterApiService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ProvidersServiceS) private providerService!: ProvidersService;
    @Inject(ChainServiceS) private chainService!: ChainService;
    @Inject(CustomNotificationServiceS) private customNotificationService!: CustomNotificationService;
    @Inject(UserSettingsS) private userSettingsService!: UserSettingsService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;

    readonly storeState: ClusterStore = this.storeFacade.getState('ClusterStore');
    readonly ratesState: RatesStore = this.storeFacade.getState('RatesStore');

    constructor() {
        // NOTE Applying default filters
        const { settings } = this.storeState;

        this.storeFacade.watch(
            () => [
                this.userSettingsService.defaultFilters.price,
                this.userSettingsService.defaultFilters.numberOfGuests,
                this.userSettingsService.defaultFilters.mealType,
            ],
            (n, o) => {
                if (JSON.stringify(n) === JSON.stringify(o)) {
                    return;
                }
                const { defaultFilters } = this.userSettingsService;

                settings.priceType = defaultFilters.price;
                settings.numberOfGuests = defaultFilters.numberOfGuests;

                const defaultMealType = this.mealTypesService.getMealType(defaultFilters.mealType);
                settings.mealTypeId = defaultMealType ? defaultMealType.id : -1;
            },
            { immediate: true },
        );

        this.storeFacade.watch(
            () => this.mealTypesService.mealTypes,
            () => {
                const { mealTypes } = this.mealTypesService;
                const { defaultFilters } = this.userSettingsService;

                if (mealTypes.length <= 1 && settings.mealTypeId !== -1) { return; }

                const neededMealType = this.mealTypesService.getMealType(defaultFilters.mealType)!;
                settings.mealTypeId = neededMealType ? neededMealType.id : -1;
            },
        );

        this.storeFacade.watch(() => [
            this.storeState.settings.mealTypeId,
            this.storeState.settings.roomTypeId,
            this.storeState.settings.numberOfGuests,
            this.storeState.settings.priceType,
            this.storeState.ratesSorting,
            this.storeState.provider,
            this.storeState.hotelNameSorting,
            this.documentFiltersService.settings.month,
            this.documentFiltersService.settings.year,
            this.documentFiltersService.settings.los,

            this.chainService.settings.country,
            this.chainService.settings.region,
            this.chainService.settings.city,
            this.chainService.settings.brand,

            this.providerService.storeState.providers,
        ], (n: string[], o: string[]) => {
            if (arraysAreIdentical(n, o)) {
                return;
            }

            this.clusterService.resetLoading();
            this.ratesCommonService.loadRemainingScans(
                this.storeState.currentHotelId,
                this.storeState.provider,
            );
        });
    }

    get currentDocument() {
        return this.storeState.ratesClusterCurrentDocument;
    }

    get currentSettings() {
        return this.storeState.settings;
    }

    get currentHotelId() {
        return this.storeState.currentHotelId;
    }

    get hotels() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.RATES),
        );

        return this.storeState.clusterHotels as ClusterHotelsRatesModel[];
    }

    get hotelsCount() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.RATES),
        );

        return this.storeState.totalCount as number;
    }

    get priceType() {
        return this.storeState.settings.priceType || this.userSettingsService.defaultFilters.price;
    }

    set priceType(value: PRICE_TYPE) {
        this.storeState.settings.priceType = value;
    }

    get priceShown() {
        return this.documentFiltersService.priceShown;
    }

    set priceShown(value: PRICE_SHOWN) {
        this.documentFiltersService.priceShown = value;
    }

    get providers() {
        const providers = new Set<string>();

        if (!this.hotels || !this.storeState.loading.finishDate) {
            return Array.from(providers) as string[];
        }

        this.hotels.forEach(hotel => {
            hotel.compsets.forEach(compset => {
                compset.rateProviders.forEach(provider => providers.add(provider));
            });
        });

        return Array.from(providers) as string[];
    }

    get hotelNameSorting() {
        return this.storeState.hotelNameSorting;
    }

    get scoreSorting() {
        return this.storeState.ratesSorting;
    }

    get colorThresholds() {
        return this.storeState.ratesColorThresholds;
    }

    set colorThresholds(value: [number, number]) {
        this.storeState.ratesColorThresholds = value;
    }

    async triggerScan(day: Day, compsetId: string) {
        if (!this.providerService.chainRatesProviders.length) {
            return false;
        }

        const compset = this.compsetsService.getCompset(compsetId);

        if (!this.isScanAvailable(day) || !compset || !this.currentSettings) {
            return false;
        }

        const { mainPos, pos } = compset;

        const documentFilters: DocumentFiltersModel = {
            ...this.documentFiltersService.storeState.settings,
            compsetId: compset.id,
            los: compset.los.length ? compset.los[0] : null,
            pos: mainPos || (pos.length ? pos[0] : null),
        };

        const { docId } = await this.ratesCommonService.triggerScan(this.currentSettings, documentFilters, day) || {};

        const hotelData = this.hotels.find(data => {
            if (data.compsetMain) {
                return data.compsetMain.id === compsetId;
            }

            return !!data.compsets
                .find(compset => compset.id === compsetId);
        });

        if (hotelData) {
            if (!hotelData.compsetMain && docId) {
                hotelData.compsetMain = new RatesCompsetMainModel();
                hotelData.compsetMain.documentId = docId;
                hotelData.compsetMain.id = compsetId;
            }

            hotelData.compsetMain!.scanStatus = SCAN_STATUS.IN_PROGRESS;
        }

        return true;
    }

    getScreenshot(day: Day, hotelId: number, mainHotelId: number) {
        const doc = this.clusterService.getMainCompsetData<RatesCompsetMainModel>(mainHotelId);
        if (!doc) return null;

        return this.ratesCommonService.getScreenshot(day, hotelId, doc);
    }

    getAssessment(day: Day, hotelId: number) {
        if (!this.hotels) {
            return ASSESSMENT_TYPES.NO_DATA;
        }

        const ratesAssessment = this.getCardAssessment(day, hotelId);

        if (ratesAssessment === null) {
            const isNoData = this.isNoData(day, hotelId);

            return isNoData
                ? ASSESSMENT_TYPES.NO_DATA
                : ASSESSMENT_TYPES.SOLD_OUT;
        }

        return ratesAssessment;
    }

    getCompetitionPercent(day: Day, hotelId: number) {
        const hotelPrice = this.getPrice(day, hotelId);
        const comparePrice = this.getCompsetPrice(day, hotelId);

        if (!hotelPrice || !comparePrice) {
            return null;
        }

        return (hotelPrice - comparePrice) / comparePrice;
    }

    getCardAssessment(day: Day, hotelId: number) {
        const competitionPercent = this.getCompetitionPercent(day, hotelId);
        const compset = this.clusterService.getMainCompset(hotelId);

        if (typeof competitionPercent !== 'number' || !compset) {
            return null;
        }

        return this.ratesCommonService.getCardAssessment(competitionPercent, compset, this.colorThresholds);
    }

    getRoomPrice(room: RatesDocumentItemModel | null, otherPriceShown?: PRICE_SHOWN) {
        if (!room || !room.price) return PRICE.NA;
        const priceShown = otherPriceShown || this.documentFiltersService.priceShown;

        const key = `${priceShown.toLowerCase()}Price` as keyof PriceScheme;
        return (room.price as PriceScheme)[key];
    }

    getCompsetPrice(day: Day, hotelId: number, otherPriceShown?: PRICE_SHOWN) {
        const compset = this.clusterService.getMainCompset(hotelId);

        if (!compset) return null;

        const competitorRooms = this.getCompetitorsHotelRooms(day, hotelId);

        if (!competitorRooms) return null;

        return this.ratesCommonService
            .getCompsetPrice(competitorRooms, compset.type, otherPriceShown || this.documentFiltersService.priceShown);
    }

    getHotelRoom(day: Day, refHotelId: number, hotelId: number = refHotelId) {
        const checkinDay = this.getCheckinDay(day, refHotelId);

        if (!checkinDay || !checkinDay[hotelId]) return null;

        const [roomList] = Object.values(checkinDay[hotelId].rooms);
        const [room] = roomList;

        if (!room) return null;

        return room;
    }

    getCompsetIdByHotel(hotelId: number) {
        const doc = this.clusterService.getMainCompsetData<RatesCompsetMainModel>(hotelId);
        return doc?.id || null;
    }

    getCompetitorsHotelRooms(day: Day, hotelId: number) {
        const rooms = this.getAllHotelRooms(day, hotelId);

        if (!rooms) return null;

        delete rooms[hotelId];

        return rooms as HotelRooms;
    }

    getAllHotelRooms(day: Day, currHotelId: number, includeNull = false) {
        const compset = this.clusterService.getMainCompset(currHotelId);
        if (!compset) return null;

        const { competitors } = compset;

        let entries = [...competitors, currHotelId]
            .map(compHotelId => [+compHotelId, this.getHotelRoom(day, currHotelId, compHotelId)] as [number, RatesDocumentItemModel | null]);

        if (!includeNull) {
            entries = entries.filter(entry => !!entry[1]) as [number, RatesDocumentItemModel][];
        }

        return Object.fromEntries(entries);
    }

    getPrice(day: Day, hotelId: number, competitorId?: number) {
        const room = this.getHotelRoom(day, hotelId, competitorId);

        return room
            ? this.getRoomPrice(room)
            : null;
    }

    getCheckinDay(day: Day, hotelId: number) {
        const doc = this.clusterService.getMainCompsetData<RatesCompsetMainModel>(hotelId);

        if (!doc) return null;

        const { checkinDates } = doc;

        if (!checkinDates || !checkinDates[day]) return null;

        const checkinDay = checkinDates[day]!;

        return checkinDay.hotels;
    }

    getSettings(hotelId: number) {
        const data = this.clusterService.getMainCompsetData<RatesCompsetMainModel>(hotelId);

        if (!data) return null;

        this.storeState.settings.provider = data.providerName;

        return this.storeState.settings;
    }

    getCompetitors(hotelId: number) {
        const mainCompsetData = this.clusterService.getMainCompsetData<RatesCompsetMainModel>(hotelId);
        return mainCompsetData ? this.clusterCompsetsService.getCompetitors(mainCompsetData.id) : null;
    }

    getColor(day: Day, hotelId: number) {
        const color = this.getCardAssessment(day, hotelId);

        switch (color) {
            case ASSESSMENT_TYPES.GOOD:
                return 'green';
            case ASSESSMENT_TYPES.NORMAL:
                return 'yellow';
            case ASSESSMENT_TYPES.BAD:
                return 'red';
            default:
                return 'grey';
        }
    }

    getCurrency(hotelId: number) {
        const doc = this.clusterService.getMainCompsetData<RatesCompsetMainModel>(hotelId);

        if (!doc) {
            return null;
        }

        return this.helperService.currencySymbol(doc.currency || 'USD');
    }

    getHotelLink(day: Day, currHotelId: number, hotelId: number) {
        const checkinDay = this.getCheckinDay(day, currHotelId);

        if (!checkinDay || !checkinDay[hotelId]) return null;
        if (this.isNa(day, currHotelId, hotelId)) return null;

        return checkinDay[hotelId].link;
    }

    getUpdateDate(day: Day, currHotelId: number) {
        const doc = this.clusterService.getMainCompsetData<RatesCompsetMainModel>(currHotelId);
        if (doc === null) {
            return null;
        }

        return this.ratesCommonService.getUpdateDate(day, doc!);
    }

    getCheckinDate(day: Day, hotelId?: number) {
        const doc = hotelId
            ? this.clusterService.getHotelBy({ hotelId })!.compsetMain! as RatesCompsetMainModel
            : this.currentDocument;

        const checkinDate = doc
            && doc.checkinDates
            && doc.checkinDates[day];
        return checkinDate || null;
    }

    getOccupancy(day: Day, hotelId?: number) {
        const checkinDate = this.getCheckinDate(day, hotelId);
        if (checkinDate?.occupancy === null || checkinDate?.occupancy === undefined) {
            return null;
        }
        return checkinDate.occupancy;
    }

    getDemand(day: Day, hotelId?: number) {
        const checkinDate = this.getCheckinDate(day, hotelId);
        if (checkinDate?.demand === null || checkinDate?.demand === undefined) {
            return null;
        }

        return checkinDate.demand;
    }

    getHotelLosRestriction(day: Day, currHotelId: number, hotelId: number) {
        const checkinDay = this.getCheckinDay(day, currHotelId);

        if (!checkinDay) {
            return false;
        }

        if (!checkinDay[hotelId]) return false;

        return checkinDay[hotelId].losRestriction;
    }

    saveCurrentProvider(provider: string | null) {
        this.storeState.provider = provider;
        this.storeState.settings.provider = provider;
    }

    toggleScoreSort() {
        this.storeState.hotelNameSorting = 0;

        if (this.storeState.ratesSorting === 1) {
            this.storeState.ratesSorting = -1;
        } else {
            this.storeState.ratesSorting = 1;
        }
    }

    toggleHotelNameSort() {
        this.storeState.ratesSorting = 0;

        if (this.storeState.hotelNameSorting === 1) {
            this.storeState.hotelNameSorting = -1;
        } else {
            this.storeState.hotelNameSorting = 1;
        }
    }

    setCurrentRatesClusterData() {
        if (this.currentHotelId) {
            this.storeState.ratesClusterCurrentDocument = this.clusterService.getMainCompsetData<RatesCompsetMainModel>(this.currentHotelId);
        }

        const newSettings = this.getSettings(this.currentHotelId!) || new RatesSettingsModel();

        if (JSON.stringify(newSettings) !== JSON.stringify(this.storeState.settings)) {
            this.storeState.settings = newSettings;
        }
    }

    switchCurrentHotel(hotelId: number) {
        this.storeState.currentHotelId = hotelId;
        this.ratesCommonService.loadRemainingScans(
            hotelId,
            this.storeState.provider,
        );
    }

    isScanAvailable(day?: Day): boolean {
        return this.ratesCommonService.isScanAvailable(this.currentSettings, day);
    }

    isNoData(day: Day, hotelId: number) {
        const doc = this.clusterService.getMainCompsetData<RatesCompsetMainModel>(hotelId);

        if (!doc || !doc.checkinDates) {
            return true;
        }

        const rooms = this.getAllHotelRooms(day, hotelId);
        const isNoRooms = !Object.keys(rooms!).length;

        if (isNoRooms) return true;

        return !doc.checkinDates[day];
    }

    isNa(day: Day, hotelId: number, competitorId?: number) {
        if (this.isNoData(day, hotelId)) return false;

        const price = this.getPrice(day, hotelId, competitorId);

        return price === PRICE.NA || price === null;
    }

    isSoldOut(day: Day, hotelId: number, competitorId?: number) {
        if (this.isNoData(day, hotelId) || this.isNa(day, hotelId)) {
            return false;
        }

        return this.getPrice(day, hotelId, competitorId) === PRICE.SOLD_OUT;
    }

    isOutOfRange(hotelId: number) {
        const hotel = this.clusterService.getHotelData<ClusterHotelsRatesModel>(hotelId);

        if (!hotel) return true;

        return this.ratesCommonService.isOutOfRange(hotel.compsetMain as unknown as RatesDocumentModel);
    }

    isWholeHotelNoData(mainHotelId: number) {
        return this.documentFiltersService.days
            .map(day => this.isNoData(day, mainHotelId))
            .filter(isNoData => !isNoData).length === 0;
    }

    async downloadExcel(monthrange: string[], provider: string, columns: { name: string, value: boolean }[], sendToEmail?: boolean) {
        const excelData = await this.clusterApiService.getClusterExcelDocument(provider, monthrange, columns, sendToEmail);

        // Don't show notification/download file, if report sent via email
        if (excelData && !sendToEmail) {
            await this.customNotificationService.handleExcel(excelData as unknown as DownloadExcelModel);
        }

        return true;
    }
}
