import { injectable } from 'inversify-props';
import RankingDocument from '@/modules/ranking/models/ranking-document.model';
import RankingDocumentItem from '@/modules/ranking/models/ranking-document-item.model';

interface RankingCommonPublicInterface {
    /**
     * Get names of all providers inside provided document.
     * @param doc ranking document.
     * @returns string of names or empty array if no providers in the document.
     */
    providers: (doc: RankingDocument) => string[];

    /**
     * Get IDs of all hotels inside provided document.
     * @param doc ranking document
     * @param provider ranking provider (source)
     * @returns array of IDs or null if there is no this provider in the document.
     */
    hotelIds: (doc: RankingDocument, provider: string) => number[] | null;

    /**
     * Get hotel document items from provided document for specific provider.
     * @param doc ranking document.
     * @param provider ranking provider (source).
     * @returns object with key as hotel id and value as document item or null if there are no hotels for the provider.
     */
    hotels: (doc: RankingDocument, provider: string) => {
        [hotelId: number]: RankingDocumentItem;
    } | null;

    /**
     * Get minimum and maximum rating inside provided hotels.
     * @param hotels result of hotels method if it is not null.
     * @returns array with two numbers: first as minimum rating, second as maximum, null if hotels is empty.
     */
    minMaxRating: (hotels: { [hotelId: number]: RankingDocumentItem }) => [number, number] | null;

    /**
     * Get minimum and maximum reviews inside provided hotels.
     * @param hotels result of hotels method if it is not null.
     * @returns array with two numbers: first as minimum reviews, second as maximum, null if hotels is empty.
     */
    minMaxReview: (hotels: { [hotelId: number]: RankingDocumentItem }) => [number, number] | null;
}

export const RankingCommonServiceS = Symbol.for('RankingCommonServiceS');
@injectable(RankingCommonServiceS as unknown as string)
export default class RankingCommonService implements RankingCommonPublicInterface {
    providers(document: RankingDocument) {
        return Object.keys(document.providers);
    }

    hotelIds(document: RankingDocument, provider: string) {
        const rankingHotels = this.hotels(document, provider);

        if (rankingHotels === null) {
            return null;
        }

        return Object.keys(rankingHotels).map(key => Number(key));
    }

    hotels(document: RankingDocument, provider: string) {
        if (!document.providers[provider]) {
            return null;
        }

        return { ...document.providers[provider] };
    }

    minMaxRating(hotels: { [hotelId: number]: RankingDocumentItem }): [number, number] | null {
        if (!Object.keys(hotels).length) {
            return null;
        }

        const competitiveRatings = Object.entries(hotels).map(([, hotel]) => hotel.rating);
        return [Math.min(...competitiveRatings), Math.max(...competitiveRatings)];
    }

    minMaxReview(hotels: { [hotelId: number]: RankingDocumentItem }): [number, number] | null {
        if (!Object.keys(hotels).length) {
            return null;
        }

        const competitiveReviews = Object.entries(hotels).map(([, hotel]) => hotel.reviews);
        return [Math.min(...competitiveReviews), Math.max(...competitiveReviews)];
    }
}
