import { trace, Tracer } from '@opentelemetry/api';
import { injectable, Inject } from 'inversify-props';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider, Span } from '@opentelemetry/sdk-trace-web';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { Resource } from '@opentelemetry/resources';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-proto';
import VueRouter from 'vue-router';
import ConfigService, { ConfigServiceS } from '../config/config.service';
import SocketService, { SocketServiceS } from '../common/modules/socket/socket.service';

import { XMLHttpRequestInstrumentation } from './xhr-instrumentation/index.js';
import { SpanName } from './types';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import UserStore from '../user/store/user.store';

interface OpenTelemetryPublicInterface {
    /**
     * Initialize OpenTelemetry service
     * After this all registered instrumentation start working,
     * xhr requests and send traces.
     * Should be called after sso request.
     * @param user
     */
    init(router: VueRouter): void;

    /**
     * Start OTEL span. Have to be manually closed
     * @param name SpanName instace. It is used to close opened span
     * @param payload additional fields, which will be sent in trace,
     * only cx.action.error, cx.action.filterBy will be sent to logs.
     */
    startSpan(name: SpanName, payload: Record<string, any>): void;

    /**
     * Closes previously opened span and send logs to BE if second parameter true.
     * Otherwise only send trace to coralogix.
     * @param name SpanName which was provided when span was opened.
     * @param options payload on span close and additional options.
     * OTEL logs to BE will be sent only if sendLogss is true.
     */
    endSpan(name: SpanName, options?: { sendLogss?: boolean, payload?: Record<string, any> }): void;

    /**
     * Method for instant actions such as click.
     * Start and end span in one method.
     * @param name
     * @param options span payload and additional options.
     */
    instantSpan(name: SpanName, options?: { sendLogss?: boolean, payload?: Record<string, any> }): void;

    /**
     * Add new event to span.
     * Event is a stage of span.
     * @param name SpanName which was provided when span was opened.
     * @param eventName name of new event.
     */
    addEvent(name: SpanName, eventName: string): void;

    /**
     * Add cx.action.error field to all active spans.
     * @param err any Error instance
     * @param closeSpan should span be closed after it
     */
    setErrorToActiveSpans(err: Error, closeSpan?: boolean): void;

    /**
     * Utility method for OTEL logs, which help to build filterBy string.
     * @param settings any key value settings
     */
    buildFilterBy(settings: Record<string, any>): string;
}

/**
 * Service to work with open telemetry.
 * Disabled on localhost.
 */
export const OpenTelemetryServiceS = Symbol.for('OpenTelemetryServiceS') as unknown as string;
@injectable(OpenTelemetryServiceS)
export default class OpenTelemetryService implements OpenTelemetryPublicInterface {
    @Inject(ConfigServiceS) private configService!: ConfigService;
    @Inject(SocketServiceS) private socketService!: SocketService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;

    readonly userStoreState: UserStore = this.storeFacade.getState('UserStore');

    private tracer: Tracer | null = null;
    private router: VueRouter | null = null;
    private activeSpans: { [spanId: string]: Span } = {};

    init(router: VueRouter) {
        if (this.isLocal) {
            return;
        }

        this.router = router;

        const connectionDetails: Record<string, any> = {
            url: this.configService.otelURL,
            headers: this.configService.otelHeaders,
        };

        let env = '';
        const hostSplit = window.location.hostname.split('.')[0];
        switch (hostSplit) {
            case 'localhost':
                env = 'local';
                break;
            case 'ci':
                env = 'prod';
                break;
            default:
                [, env] = hostSplit.split('-');
        }

        const exporter = new OTLPTraceExporter(connectionDetails);
        const provider = new WebTracerProvider({
            resource: new Resource({
                [SemanticResourceAttributes.SERVICE_NAME]: this.configService.otelServiceName,
                'cx.application.name': 'CI',
                'deployment.environment': env.toUpperCase(),
                'cx.subsystem.name': this.configService.otelServiceName,
            }),
        });

        registerInstrumentations({
            instrumentations: [
                new XMLHttpRequestInstrumentation({
                    ignoreUrls: [
                        /zdassets/,
                        /zendesk/,
                        /maps\/api/,
                        /google/,
                    ],
                    applyCustomAttributesOnSpan: span => {
                        const { attributes } = span as any;

                        const isXhr = attributes && !!attributes['http.url'];

                        if (!isXhr) {
                            return;
                        }

                        const url = attributes['http.url'];
                        // Take first parameter in the path as span name
                        const name = this.mapXHRSpanUrlToName(url);

                        // name is replaced with http.url in coralogix, so use it as name
                        span.updateName(name);
                        span.setAttributes({
                            'http.url': name,
                            'http.full_url': url,
                        });
                    },
                }),
            ],
        });

        provider.addSpanProcessor(new BatchSpanProcessor(exporter));

        provider.register({
            contextManager: new ZoneContextManager(),
        });

        this.tracer = trace.getTracer('fe-tracer');
    }

    startSpan({ name, prefix }: SpanName, payload: Record<string, any> = {}) {
        if (this.isLocal || !this.tracer) {
            return;
        }

        const spanName = prefix ? `${prefix}:${name}` : name;

        this.activeSpans[spanName] = this.tracer.startSpan(spanName, { attributes: { ...payload } }) as Span;
    }

    endSpan({ name, prefix }: SpanName, options?: { sendLogs?: boolean, payload?: Record<string, any>}) {
        const spanName = prefix ? `${prefix}:${name}` : name;
        const { user } = this.userStoreState;

        if (!user) {
            throw new Error('No user provided.');
        }

        const userAttributes = {
            'cx.user.email': user.email,
            'cx.user.id': user.id,
            'cx.user.chain': user.chainName,
            'cx.user.test': !!user.isTestUser,
        } as Record<string, string | number | boolean>;

        if (this.isLocal) {
            return;
        }

        if (!this.activeSpans[spanName]) {
            return;
        }

        if (this.router!.currentRoute.name?.includes('hotel')) {
            userAttributes['cx.user.fornovaId'] = user?.currentHotelId || '';
        }

        this.activeSpans[spanName].setAttributes({
            ...userAttributes,
            ...options?.payload,
        });

        this.activeSpans[spanName].end();

        if (options?.sendLogs) {
            this.sendOTELogs(this.activeSpans[spanName]);
        }

        delete this.activeSpans[spanName];
    }

    instantSpan(name: SpanName, options?: { sendLogs?: boolean, payload?: Record<string, any>}) {
        this.startSpan(name, options?.payload);
        this.endSpan(name, options);
    }

    addEvent({ name, prefix }: SpanName, eventName: string) {
        const spanName = prefix ? `${prefix}:${name}` : name;

        if (this.isLocal || !this.activeSpans[spanName]) {
            return;
        }

        this.activeSpans[spanName].addEvent(eventName);
    }

    setErrorToActiveSpans(err: Error, closeSpan?: boolean) {
        Object.keys(this.activeSpans).forEach(spanName => {
            this.activeSpans[spanName].setAttribute('cx.action.error', err.message);
            if (closeSpan) {
                this.endSpan({ name: spanName });
            }
        });
    }

    buildFilterBy(settings: Record<string, any>) {
        const allowedKeys = [
            'compsetId',
            'los',
            'pos',
            'year',
            'month',
            'provider',
            'numberOfGuests',
            'priceType',
            'mealTypeId',
            'roomTypeId',
            'dateRange',
        ];

        return allowedKeys.reduce((acc, key) => {
            if (settings[key]) {
                const value = key === 'month'
                    ? settings[key] + 1
                    : settings[key];
                return `${acc},${key}=${value}`;
            }
            return acc;
        }, '');
    }

    /**
     * Send OTEL logs to BE via web sockets
     * DON'T USE IT DIRECTLY.
     * Only via endSpan, because we do need information from span
     */
    private sendOTELogs(span: Span) {
        const context = span.spanContext();
        const startTime = Number(`${span.startTime[0]}${String(span.startTime[1]).concat('00').slice(0, 3)}`);
        const endTime = Number(`${span.endTime[0]}${String(span.endTime[1]).concat('00').slice(0, 3)}`);
        const delta = Number((Math.abs(endTime - startTime) / 1000).toFixed(2)); // Should be in seconds, 2 digits after point

        const message = {
            start_time: startTime,
            end_time: endTime,
            execution_time: delta,
            action: span.name,
            span: `${context.traceId}_${context.spanId}`,
            user_email: span.attributes['cx.user.email'],
            customer_name: span.attributes['cx.user.chain'],
            fornova_id: span.attributes['cx.user.fornovaId'],
            is_test_user: span.attributes['cx.user.test'],
            agent: navigator.userAgent,
            error: Boolean(span.attributes['cx.action.error']),
            filter_by: String(span.attributes['cx.action.filterBy']),
        };

        if (this.isLocal) {
            return;
        }

        this.socketService.emit('LogsMessage', message);
    }

    private get isLocal() {
        return /localhost/.test(window.location.hostname);
    }

    // Set pretty names for Coralogix
    private mapXHRSpanUrlToName(url: string) {
        const splitedUrl = url.replace(/http(s?):\/\//, '').split('/');
        const firstParam = splitedUrl[1];

        switch (firstParam) {
            case 'supported':
            case 'users':
                return `${splitedUrl[1]}-${splitedUrl[2]}`;
            default:
                return firstParam.split('?')[0];
        }
    }
}
