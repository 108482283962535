
import { Inject } from 'inversify-props';

import {
    Component,
    Prop, Vue,
} from 'vue-property-decorator';

import sanitizeHTML from 'sanitize-html';

import CustomNotificationService, { CustomNotificationServiceS } from '../custom-notification.service';
import NotificationItemModel from '../models/notification-item.model';
import NotificationWrapper from './notification-wrapper.vue';

@Component({
    components: {
        NotificationWrapper,
    },
})
export default class RegularNotification extends Vue {
    @Inject(CustomNotificationServiceS)
    private customNotificationService!: CustomNotificationService;

    @Prop({
        required: true,
        type: Object,
    })
    data!: NotificationItemModel;

    handleClose() {
        this.customNotificationService.close(this.data.id);
    }

    get message() {
        return sanitizeHTML(this.data.message || '');
    }
}
