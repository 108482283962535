
import { Component, Vue } from 'vue-property-decorator';

/**
 * Is used as a directive.
 * Don't use it in a compoents directly.
 * Only for simple tooltips to show some message, for more complex logic use custom-tooltip-v2.vue
 */
@Component
export default class CustomTooltipDirective extends Vue {}
