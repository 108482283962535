import { container } from 'inversify-props';
import { RouteConfig } from 'vue-router';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import use from '@/router/use';
import PaginationMiddleware, { PaginationMiddlewareS } from '@/router/middlewares/pagination.middleware';
import { CommonMenuRoutes } from '../common.routes';
import { RankingHistory } from '../hotel/ranking.routes';

export function ScheduledReports(prefix: string) {
    return {
        name: `${prefix}.scheduled-reports`,
        path: 'scheduled-reports',
        component: () => import('@/modules/cluster/pages/ranking-cluster-reports.modal.page.vue'),
        meta: { modal: true },
    };
}

export default function RankingClusterLevelRoutes(basePath: string, prefix: string): RouteConfig {
    const userType = basePath.split('/').shift();

    return {
        path: basePath,
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS), container.get<PaginationMiddleware>(PaginationMiddlewareS)]),
        children: [
            {
                name: prefix,
                path: '',
                props: () => ({ userType }),
                component: () => import('@/modules/cluster/pages/ranking-cluster.page.vue'),
                children: [
                    ...CommonMenuRoutes(prefix),
                    RankingHistory(prefix),
                    ScheduledReports(prefix),
                ],
            },
        ],
    };
}
