import { Route } from 'vue-router';
import { CommonMenuRoutes } from './common.routes';

export const DownloadExcelPopup = (prefix: string) => ({
    name: `${prefix}.download-excel`,
    path: 'download-excel',
    meta: { modal: true },
    component: () => import('@/modules/rates/pages/download-excel.page.vue'),
});

export function RatesPriceHistory(prefix: string) {
    return {
        name: `${prefix}.day-rate`,
        path: 'day/:day',
        props: (route: Route) => ({ historyDay: route.params.day }),
        component: () => import('@/modules/rates/price-history/pages/rates-price-history.modal.vue'),
        meta: { modal: true },
    };
}

export function RatesScreenshot(prefix: string) {
    return {
        name: `${prefix}.screenshot`,
        path: 'screenshot',
        props: (route: Route) => ({
            url: route.query.url,
            rectangleUniqueness: route.query.rectangleUniqueness,
        }),
        meta: { modal: true },
        component: () => import('@/modules/rates/pages/rates-screenshot.modal.vue'),
    };
}

export function ScheduledAnalysisReports(prefix: string) {
    return {
        name: `${prefix}.scheduled-reports`,
        path: 'scheduled-reports',
        component: () => import('@/modules/rates/pages/analysis/analysis-report.modal.page.vue'),
        meta: { modal: true },
    };
}

export function RatesAnalysisRoutes(prefix: string) {
    const rootPrefix = `${prefix}.analysis`;
    const rootPrefixTable = `${prefix}.analysis.table`;
    return [
        {
            name: rootPrefix, // NOTE: Default calendar mode
            path: 'analysis',
            component: () => import('@/modules/rates/pages/analysis/rates-analysis-calendar.page.vue'),
            children: [
                RatesPriceHistory(rootPrefix),
                DownloadExcelPopup(rootPrefix),
                ScheduledAnalysisReports(rootPrefix),
                ...CommonMenuRoutes(rootPrefix),
            ],
        },
        {
            name: rootPrefixTable,
            path: 'analysis/table',
            component: () => import('@/modules/rates/pages/analysis/rates-analysis-table.page.vue'),
            children: [
                RatesPriceHistory(rootPrefixTable),
                DownloadExcelPopup(rootPrefixTable),
                ScheduledAnalysisReports(rootPrefixTable),
                ...CommonMenuRoutes(rootPrefixTable),
            ],
        },
    ];
}
