import { plainToClass } from 'class-transformer';
import { injectable, Inject } from 'inversify-props';
import { ClassType } from 'class-transformer/ClassTransformer';
import ApiService, { ApiServiceS } from '../common/services/api.service';
import InsightTypeModel from './models/insight-type.model';
import InsightGroupsListModel from './models/insight-groups-list.model';
import InsightGroupPopupModel from './models/insight-group-popup.model';
import InsightGroupModel from './models/insight-group.model';
import { Insight, InsightGroupBE, RoomsInsight } from './types';

interface InsightsApiPublicInterface {
    /**
     * Method to get all enabled insight types for the user
     * @returns promise resolved with collection of insight types
     */
    getInsightTypes: () => Promise<InsightTypeModel[]>;

    /**
     * Method to get all user's insight groups for specific hotel and filters
     * @param settings object with related filters
     * @param pagination skip limit object
     * @returns promise resolved with collection of insight groups
     */
    getInsightGroups: (
        settings: { fornovaId: number, providers: string[], insightTypes: string[], dateRange: string },
        pagination: { skip: number, limit: number },
    ) => Promise<InsightGroupsListModel>;

    /**
     * Method to get one user's insight group by its id
     * @param id id of the group
     * @returns promise resolved with insight group
     */
    getInsightGroup: <T extends Insight | RoomsInsight>(id: string) => Promise<InsightGroupModel<T>>;

    /**
     * Method to get specific insight for specific date, with provided day insight model.
     * @param dayClass insight day model (InsightDay(Rate/Market/...)Model)
     * @param params requested insight params
     * @returns filters, hotels names and data according to <T>
     */
    getDayInsights: <T>(
        dayClass: ClassType<T>,
        { groupId, insightId, date }: { groupId: string, insightId: string, date: string }
    ) => Promise<T | null>;

    /**
     * Method to set provided insight's group as viewed
     * @param groupId id of insight's group
     */
    setGroupViewed: (groupId: string) => Promise<boolean>;

    /**
     * Method to remove viewed flag from insight's group
     * @param groupId id of insight's group
     */
    setGroupUnviewed: (groupId: string) => Promise<boolean>;

    /**
     * Method to delete whole insight's group
     * @param groupId id of insight's group
     */
    deleteGroup: (groupId: string) => Promise<boolean>;
}

export const InsightsApiServiceS = Symbol.for('InsightsApiServiceS');
@injectable(InsightsApiServiceS as unknown as string)
export default class InsightsApiService implements InsightsApiPublicInterface {
    @Inject(ApiServiceS) private apiService!: ApiService;

    async getInsightTypes() {
        const { data } = await this.apiService.get('targeted-insights/get-insight-types');

        if (!data) {
            return [] as InsightTypeModel[];
        }

        return plainToClass(InsightTypeModel, <InsightTypeModel[]> data, { excludeExtraneousValues: true });
    }

    async getInsightGroups(
        settings: { fornovaId: number, providers: string[], insightTypes: string[], dateRange: string },
        pagination: { skip: number, limit: number } = { skip: 0, limit: 12 },
    ) {
        const {
            fornovaId, providers, insightTypes, dateRange,
        } = settings;
        const { skip, limit } = pagination;
        const joinedProviders = providers.join('&providerName=');
        const iTypes = insightTypes.join('&insightType=');
        const fromDate = Date.now();
        const url = `targeted-insights/alerts/${fornovaId}?range=${dateRange}&fromDate=${fromDate}&providerName=${joinedProviders}&insightType=${iTypes}&skip=${skip}&limit=${limit}`;
        const { data } = await this.apiService.get(url);

        return plainToClass(InsightGroupsListModel, <{ data: InsightGroupBE[], count: number }> data, { excludeExtraneousValues: true });
    }

    async getInsightGroup<T extends Insight | RoomsInsight>(id: string): Promise<InsightGroupModel<T>> {
        const url = `/targeted-insights/alert/${id}`;
        type Response = { data: InsightGroupBE; hotelNames: { [fId: string]: string; }};
        const { data }: { data: Response } = await this.apiService.get(url);
        return (plainToClass(InsightGroupPopupModel, <Response> data, { excludeExtraneousValues: true }) as { data: InsightGroupModel<T> }).data;
    }

    async getDayInsights<T>(dayClass: ClassType<T>, { groupId, insightId, date }: { groupId: string, insightId: string, date: string }) {
        const { data } = await this.apiService.get(`/targeted-insights/alerts-popups/${groupId}/${date}/${insightId}`);

        if (!data) {
            return null;
        }

        return plainToClass(dayClass, <T> data, { excludeExtraneousValues: true });
    }

    async setGroupViewed(groupId: string) {
        await this.apiService.put(`/targeted-insights/mark-alert-as-read/${groupId}`);
        return true;
    }

    async setGroupUnviewed(groupId: string) {
        await this.apiService.put(`/targeted-insights/mark-alert-as-unread/${groupId}`);
        return true;
    }

    async deleteGroup(groupId: string) {
        await this.apiService.delete(`/targeted-insights/alert/${groupId}`);
        return true;
    }
}
