import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import CarsRateAlertConfigurationModel from './models/cars-rate-alert-configuration.model';
import { ICarRateAlertConfig } from './modules/settings/interfaces/alert-interfaces';

export const CarsRateAlertApiServiceS = Symbol.for('CarsRateAlertApiServiceS');
@injectable(CarsRateAlertApiServiceS as unknown as string)
export default class CarsRateAlertApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    private url = 'cr-insight';

    async getRateAlertsConfiguration() : Promise<CarsRateAlertConfigurationModel | null | any> {
        const { data } = await this.apiService.get(`${this.url}/alert-rate-config`);

        if (!data) {
            return null;
        }

        const carsDocument = plainToClass(CarsRateAlertConfigurationModel, data, { excludeExtraneousValues: true });
        return carsDocument;
    }

    async saveRateAlertConfiguration(configuration: ICarRateAlertConfig) {
        return this.apiService.post(`${this.url}/alert-rate-config`, configuration);
    }

    async updateRateAlertConfiguration(configuration: ICarRateAlertConfig, id: string) {
        return this.apiService.post(`${this.url}/alert-rate-config/${id}`, configuration);
    }

    async updateRateAlertConfigurationStatus(id: string, status: boolean) {
        return this.apiService.put(`${this.url}/alert-rate-config/update-status/${id}`, { is_active: status });
    }

    async deleteRateAlertConfiguration(id: string) {
        return this.apiService.delete(`${this.url}/alert-rate-config/${id}`);
    }
}
