import { Inject, injectable } from 'inversify-props';
import VueRouter from 'vue-router';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { routerData } from '@/router';
import CompsetsService, { CompsetsServiceS } from '../compsets/compsets.service';
import HomeFiltersService, { HomeFiltersServiceS } from '../home/home-filters.service';
import AuthService, { AuthServiceS } from '../auth/auth.service';
import { USER_LEVELS } from './constants';
import PAGES from '../common/constants/pages.constant';
import RatesService, { RatesServiceS } from '../rates/rates.service';
import RatesFiltersService, { RatesFiltersServiceS } from '../rates/rates-filters.service';
import MarketsService, { MarketsServiceS } from '../markets/markets.service';
import RankingService, { RankingServiceS } from '../ranking/ranking.service';
import CompsetModel from '../compsets/models/compset.model';
import DiLiteAllChannelsService, { DiLiteAllChannelsServiceS } from '../di-lite/di-lite-all-channels.service';
import DeepCompsetAnalysisClusterService, { DeepCompsetAnalysisClusterServiceS } from '../cluster/cluster-deep-analysis.service';
import DeepCompsetAnalysisService, { DeepCompsetAnalysisServiceS } from '../deep-analysis/deep-analysis.service';
import PromotionsService, { PromotionsServiceS } from '../promotions/promotions.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '../document-filters/document-filters.service';
import ClusterService, { ClusterServiceS } from '../cluster/cluster.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '../rates/rates-analysis-filters.service';

interface UserViewServicePublicInterface {
    /**
     * Method to switch level to hotel or change currently selected hotel.
     * @param hotelId id of the hotel to move.
     * @param compset compset parameter should be provided if moved from cluster page and it is needed to keep filter from cluster on hotel page.
     */
    goToHotelPage: (
        hotelId: number,
        compset?: CompsetModel,
    ) => Promise<void>;

    /**
     * Method to switch level to cluster.
     * @param clusterId should be enabled, when chain level will be released
     */
    goToClusterPage: (/* clusterId: string */) => Promise<void>;
}

export const UserViewServiceS = Symbol.for('UserViewServiceS');
@injectable(UserViewServiceS as unknown as string)
export default class UserViewService implements UserViewServicePublicInterface {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(HomeFiltersServiceS) private homeFiltersService!: HomeFiltersService;
    @Inject(AuthServiceS) private authService!: AuthService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(RatesServiceS) private ratesService!: RatesService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(MarketsServiceS) private marketsService!: MarketsService;
    @Inject(RankingServiceS) private rankingService!: RankingService;
    @Inject(DiLiteAllChannelsServiceS) private diLiteAllChannelsService!: DiLiteAllChannelsService;
    @Inject(DeepCompsetAnalysisClusterServiceS) private dcaClusterService!: DeepCompsetAnalysisClusterService;
    @Inject(DeepCompsetAnalysisServiceS) private dcaService!: DeepCompsetAnalysisService;
    @Inject(PromotionsServiceS) private promotionsService!: PromotionsService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    private router = routerData.router as VueRouter;

    /**
     * Method to switch level to hotel or change currently selected hotel.
     * @param hotelId id of the hotel to move.
     * @param options compsetId parameter should be provided if moved from cluster page and it is needed to keep filter from cluster on hotel page.
     * query parameter to keep any query from the path.
     */
    async goToHotelPage(
        hotelId: number,
        compset?: CompsetModel,
    ) {
        if (this.userService.viewAs === USER_LEVELS.HOTEL && this.userService.currentHotelId === hotelId) {
            return;
        }

        await this.userService.setViewAs(USER_LEVELS.HOTEL, hotelId);
        await this.resetHotelData();

        const [_, page] = this.router.currentRoute.name!.split('.');

        // Set compset related filters if compsetId is provided
        // compsetId is provided only if moved from cluster page
        if (compset) {
            if (compset.id) {
                this.documentFiltersService.compsetIdFromHighLevel = compset.id;
                this.documentFiltersService.compsetId = compset.id;
                this.documentFiltersService.savePos(compset.mainPos || compset.pos[0]);
            }

            this.setHotelPageFilters(page as PAGES);
        }

        this.authService.socketHandshake();

        await this.router.push({ name: `hotel.${page}`, params: { hotelId: String(hotelId) } });
    }

    /**
     * Method to switch level to cluster.
     * @param clusterId should be enabled, when chain level will be released
     */
    async goToClusterPage(/* clusterId: string */) {
        if (this.userService.isViewAsChain) {
            return;
        }

        // [TODO] add cluster id check when chain level will be enabled
        if (this.userService.isViewAsCluster) {
            return;
        }

        const { level } = this.userService.user;
        await this.userService.setViewAs(level);

        // No cluster pages
        if (this.router.currentRoute.name === 'hotel.home' || this.router.currentRoute.name === 'hotel.insights') {
            await this.router.push({ name: 'cluster.rates' });
        } else {
            // [TODO] as cluster and chain are the same for now. When chain will be enabled,
            // replace this.userService.user.level with `cluster`, and add separate method for chain
            const [_, page] = this.router.currentRoute.name!.split('.');
            await this.router.push({ name: `${this.userService.user.level}.${page}` });
        }
    }

    private setHotelPageFilters(page: PAGES) {
        // Set filters from cluster to hotel
        switch (page) {
            case PAGES.RATES:
                this.ratesFiltersService.saveProvider(this.clusterService.currentRatesProvider);
                this.ratesFiltersService.providerFromHighLevel = this.clusterService.currentRatesProvider;
                this.ratesFiltersService.settings.roomTypeId = this.clusterService.ratesSettings.roomTypeId;
                this.ratesFiltersService.settings.priceType = this.clusterService.ratesSettings.priceType!;
                this.ratesFiltersService.settings.mealTypeId = this.clusterService.ratesSettings.mealTypeId;
                if (!this.ratesAnalysisFiltersService.comparisonValues.length) {
                    this.ratesAnalysisFiltersService.resetComparisonFilters();
                }
                break;
            case PAGES.MARKETS:
                // [TODO] set markets table provider from providerFromHighLevel
                break;
            case PAGES.RANKING:
                break;
            case PAGES.DILITE:
                this.diLiteAllChannelsService.settings.priceType = this.clusterService.ratesSettings.priceType!;
                this.diLiteAllChannelsService.settings.roomTypeId = this.clusterService.ratesSettings.roomTypeId;
                this.diLiteAllChannelsService.settings.mealTypeId = this.clusterService.ratesSettings.mealTypeId;
                break;
            case PAGES.DEEP_ANALYSIS: {
                this.dcaService.showBy = this.dcaClusterService.showBy;
                this.dcaService.provider = this.dcaClusterService.provider;
                this.dcaService.compareTo = this.dcaClusterService.compareTo;
                break;
            }
            case PAGES.PROMOTION_DETECTION:
                break;
            default:
                break;
        }
    }

    private async resetHotelData() {
        // Remove documents on level/hotel change
        // [TODO] check is it needed
        this.ratesService.saveDocument(null);
        this.marketsService.storeState.providersMarketsDocuments = {};
        this.rankingService.storeState.document = null;
        this.promotionsService.resetDocument();
        // Remove hotel compsets data
        this.compsetsService.storeState.compsets = null;
        this.documentFiltersService.compsetId = null;
        this.documentFiltersService.competitors = [];
        this.ratesFiltersService.resetFilters();
        await this.homeFiltersService.resetRatesProvider();
    }
}
