import { Module, VuexModule } from 'vuex-module-decorators';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import LoadingModel from '@/modules/common/models/loading.model';
import RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import type Day from '@/modules/common/types/day.type';
import RatesAnalysisData from '../models/rates-analysis-data.model';
import RatesDocumentIntradayModel from '../models/rates-document-intraday.model';

@Module
export default class RatesStore extends VuexModule {
    settings: RatesSettingsModel = new RatesSettingsModel();
    document: RatesDocumentModel | RatesDocumentAllModel | null = null;
    analysis: RatesAnalysisData = new RatesAnalysisData();
    loading: LoadingModel = new LoadingModel();
    disabledFilters: (keyof (RatesSettingsModel & DocumentFiltersModel))[] = [];
    providerFomHighLevel: string | null = null;
    isPriceShownSwitchDisabled = true;
    showPriceDiff: boolean = false;

    scanLimitation: {
        remaining: number;
        provider: string | null;
        hotelId: number | null;
        isLoading: boolean;
    } = {
        remaining: -1,
        provider: null,
        hotelId: null,
        isLoading: false,
    };

    intraday: {
        document: RatesDocumentIntradayModel | null
        loading: LoadingModel
        day: Day | null
    } = {
        document: null,
        loading: new LoadingModel(),
        day: null,
    };
}
