import { Route } from 'vue-router';
import use from '@/router/use';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import { container } from 'inversify-props';
import PaginationMiddleware, { PaginationMiddlewareS } from '@/router/middlewares/pagination.middleware';
import { CommonMenuRoutes } from '../common.routes';

function PriceHistory(prefix: string) {
    return {
        name: `${prefix}.day-rate`,
        path: 'day-rate/:day/:hotelId/:compsetId',
        props: (route: Route) => ({ historyDay: route.params.day }),
        component: () => import('@/modules/rates/price-history/pages/rates-cluster-history.modal.vue'),
        meta: { modal: true },
    };
}

export default function DiLiteClusterLevelRoutes(basePath: string, prefix: string) {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS), container.get<PaginationMiddleware>(PaginationMiddlewareS)]),
        children: [
            {
                name: prefix,
                path: '',
                component: () => import('@/modules/cluster/pages/di-lite-cluster.page.vue'),
                children: [
                    PriceHistory(prefix),
                    ...CommonMenuRoutes(prefix),
                ],
            },
        ],
    };
}
