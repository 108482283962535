import CarsApiService, { CarsApiServiceS } from '@/modules/cars/cars-api.service';
import CarsPriceHistoryService, { CarsPriceHistoryServiceS } from '@/modules/cars/cars-price-history.service';
import ASSESSMENTS_ITEMS from '@/modules/cars/constants/car-assessment-items.constant';
import {
    BRAND_AS_BROKER_ANY,
    BROKER_TO_BRAND,
    BROKER_TO_BROKER,
    DOORS_ANY,
    MILEAGE_ANY,
    PAYMENT_TERMS_ANY,
    TRANSMISSION_ANY,
} from '@/modules/cars/constants/car-filter-types.constant';
import CAR_PRICE_TYPE from '@/modules/cars/constants/car-price-type.constant';
import BranchesModel from '@/modules/cars/models/branches.model';
import CarsDocumentItemModel from '@/modules/cars/models/cars-document-item.model';
import { IncreaseType } from '@/modules/cars/models/cars-extra-expenses.model';
import CarsPriceHistoryDocumentItemModel from '@/modules/cars/models/cars-price-history-document-item.model';
import FleetAvailabilityItemModel from '@/modules/cars/models/fleet-availability-item.model';
import { setCachedAssessmentSettings } from '@/modules/cars/utils/assessment-settings-cache.util';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import PROVIDER_COLORS from '@/modules/common/constants/providers.colors.constant';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import type Day from '@/modules/common/types/day.type';
import Percent from '@/modules/common/types/percent.type';
import Price from '@/modules/common/types/price.type';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { plainToClass } from 'class-transformer';
import { Inject, injectable } from 'inversify-props';
import _ from 'lodash';
import moment from 'moment';
import { DEFAULT_GRAPH_COLORS as GRAPH_COLORS } from '../common/constants/default-graph-colors.constant';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import DocumentFiltersService, { DocumentFiltersServiceS } from '../document-filters/document-filters.service';
import CALENDAR_DATA_SOURCE from './constants/calendar-data-source.constant';
import { BRAND, BROKER } from './constants/data-source-mode.constant';
import VEHICLE_TYPE_FILTER from './constants/vehicle-type-filter.constant';
import CarRatesPriceHistoryModel from './models/car-rates-price-history.model';
import CarsAnalysisDocumentModel from './models/cars-analysis-document.model';
import CarsDocumentModel from './models/cars-document.model';
import CarsFiltersStore from './store/cars-filters.store';
import CarsStore from './store/cars.store';

export const CarsServiceS = Symbol.for('CarsServiceS');
@injectable(CarsServiceS as unknown as string)
export default class CarsService {
    @Inject(CarsApiServiceS) protected carApiService!: CarsApiService;
    @Inject(UserServiceS) protected userService!: UserService;
    @Inject(StoreFacadeS) protected storeFacade!: StoreFacade;
    @Inject(DocumentFiltersServiceS) protected documentFiltersService!: DocumentFiltersService;
    @Inject(HelperServiceS) protected helperService!: HelperService;
    @Inject(CarsPriceHistoryServiceS) carsPriceHistoryService!: CarsPriceHistoryService;

    readonly storeState: CarsStore = this.storeFacade.getState('CarsStore');
    readonly carFiltersStoreState: CarsFiltersStore = this.storeFacade.getState('CarsFiltersStore');

    constructor() {
        // this.storeFacade.watch(() => this.storeState.settings.pickUpCity, this.loadCars.bind(this));
        // this.storeFacade.watch(() => this.storeState.settings.dataSource, this.loadCars.bind(this));
        // this.storeFacade.watch(() => this.storeState.settings.lor, this.loadCars.bind(this));
        // this.storeFacade.watch(() => this.storeState.settings.pos, this.loadCars.bind(this));
        // this.storeFacade.watch(() => this.documentFiltersService.storeState.settings.month, this.loadCars.bind(this));
        // this.storeFacade.watch(() => this.documentFiltersService.storeState.settings.year, this.loadCars.bind(this));
        this.storeFacade.watch(() => [
            this.storeState.settings.pickUpCityCode,
            this.storeState.settings.dataSource,
            this.storeState.settings.lor,
            this.storeState.settings.pos,
            this.storeState.settings.chain,
            this.storeState.settings.currentDataSourceMode,
            this.documentFiltersService.storeState.settings.year,
            this.documentFiltersService.storeState.settings.month,
            this.storeState.settings.isAvgPrice,
            this.storeState.settings.cluster,
        ], this.resetLoadings.bind(this));
    }

    resetLoadings() {
        this.storeState.fleetAvailability = null;
        this.storeState.loading.reset();
    }

    async loadData() {
        if (!this.userService.isCarUser) {
            return false;
        }

        const { settings } = this.documentFiltersService.storeState;
        const carSettings = this.storeState.settings;

        if (
            settings.month === null
            || settings.year === null
            || carSettings.pickUpCityCode === null
            || carSettings.dataSource === null
            || carSettings.lor === null
            || carSettings.pos === null
        ) {
            return false;
        }

        const chain = this.currentChain ? this.currentChain : carSettings.chain;
        const { clusterMode } = this.carFiltersStoreState.settings.features;

        const pickUpCityCodes = (carSettings.isAvgPrice && clusterMode) ? this.currentClusterLocationIds.join(',') : carSettings.pickUpCityCode;
        const categories = [...(carSettings.carClasses || [])];

        const [grouped, fleetAvailability] = await Promise.all([
            this.carApiService.getCars(settings, carSettings, chain, pickUpCityCodes, categories),
            this.carApiService.getFleetAvailability(settings, carSettings, pickUpCityCodes, categories),
        ]);
        this.storeState.fleetAvailability = fleetAvailability as any;
        this.storeState.settings.allBrands = this.getBrandsForBroker(grouped);

        this.storeState.document = grouped as CarsDocumentModel;

        return true;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.document;
    }

    get groupedId() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));

        const { document } = this.storeState;
        if (!document) {
            return null;
        }

        return document.id;
    }

    get currency() {
        if (!this.data) {
            return null;
        }

        return this.data.currency || null;
    }

    fleetAvailability(day: Day) {
        const data = this.storeState.fleetAvailability;
        const defaultData = {
            countCars: null,
            totalCars: null,
        };
        if (!data) {
            return defaultData;
        }
        if (!data.occupancyDates) {
            return defaultData;
        }

        const { carClasses } = this.storeState.settings;
        let countCars: number | null = null;
        let totalCars: number | null = null;

        const jsonData = JSON.stringify(data.occupancyDates);
        const occupancyDates = JSON.parse(jsonData);
        Object.keys(occupancyDates).forEach(carClassesItem => {
            if (carClasses && !carClasses.includes(carClassesItem)) {
                delete occupancyDates[carClassesItem];
            } else {
                const days = Object.keys(occupancyDates[carClassesItem]);
                days.forEach(item => {
                    if (Number(item) !== day) {
                        delete occupancyDates[carClassesItem][item];
                    }
                });
            }
        });
        Object.keys(occupancyDates).forEach(carClassesItem => {
            const fleetItem: FleetAvailabilityItemModel = occupancyDates[carClassesItem][day];
            if (fleetItem) {
                countCars = !countCars ? fleetItem.availableCars : countCars + fleetItem.availableCars;
                totalCars = !totalCars ? fleetItem.fleetSize : totalCars + fleetItem.fleetSize;
            }
        });

        return {
            countCars,
            totalCars,
        };
    }

    occupancy(day: Day) {
        const { countCars, totalCars } = this.fleetAvailability(day);
        if (countCars === null && totalCars === null) {
            return null;
        }
        if (!totalCars) {
            return 0;
        }
        return Math.round((Number(countCars) * 100) / Number(totalCars));
    }

    filterPaymentTerm(companyCars: { [company: string]: CarsDocumentItemModel[] } | null, calendarDataSource?: CALENDAR_DATA_SOURCE) {
        const dataFrom = calendarDataSource || CALENDAR_DATA_SOURCE.GROUPED;

        if (companyCars === null) {
            return null;
        }

        const { paymentTerms } = this.storeState.settings;

        if (!paymentTerms || paymentTerms === PAYMENT_TERMS_ANY || dataFrom === CALENDAR_DATA_SOURCE.HISTORY) {
            return companyCars;
        }

        return Object.keys(companyCars).reduce((acc: { [company: string]: CarsDocumentItemModel[] }, company) => {
            acc[company] = companyCars[company].filter(car => car.paymentTerms === paymentTerms);
            return acc;
        }, {});
    }

    filterDoors(companyCars: { [company: string]: CarsDocumentItemModel[] } | null) {
        if (companyCars === null) {
            return null;
        }

        const { doors } = this.storeState.settings;
        if (!doors || doors === DOORS_ANY) {
            return companyCars;
        }

        return Object.keys(companyCars).reduce((acc: { [company: string]: CarsDocumentItemModel[] }, company) => {
            acc[company] = companyCars[company].filter(car => car.doors === doors);
            return acc;
        }, {});
    }

    filterMileage(companyCars: { [company: string]: CarsDocumentItemModel[] } | null, calendarDataSource?: CALENDAR_DATA_SOURCE) {
        const dataFrom = calendarDataSource || CALENDAR_DATA_SOURCE.GROUPED;

        if (companyCars === null) {
            return null;
        }

        const { mileage } = this.storeState.settings;
        if (!mileage || mileage === MILEAGE_ANY || dataFrom === CALENDAR_DATA_SOURCE.HISTORY) {
            return companyCars;
        }

        return Object.keys(companyCars).reduce((acc: { [company: string]: CarsDocumentItemModel[] }, company) => {
            acc[company] = companyCars[company].filter(car => car.mileage === mileage);
            return acc;
        }, {});
    }

    filterTransmission(companyCars: { [company: string]: CarsDocumentItemModel[] } | null) {
        if (companyCars === null) {
            return null;
        }

        const { transmission } = this.storeState.settings;
        if (!transmission || transmission === TRANSMISSION_ANY) {
            return companyCars;
        }
        return Object.keys(companyCars).reduce((acc: { [company: string]: CarsDocumentItemModel[] }, company) => {
            acc[company] = companyCars[company].filter(car => car.transmission === transmission);
            return acc;
        }, {});
    }

    filterFuelType(companyCars: { [company: string]: CarsDocumentItemModel[] } | null) {
        if (companyCars === null) {
            return null;
        }

        const { carFuelTypes, carFuelTypeChange } = this.storeState.settings;
        if (!carFuelTypes || !carFuelTypeChange) {
            return companyCars;
        }
        return Object.keys(companyCars).reduce((acc: { [company: string]: CarsDocumentItemModel[] }, company) => {
            acc[company] = companyCars[company].filter(car => !car.fuelType || carFuelTypes.includes(car.fuelType));
            return acc;
        }, {});
    }

    filterVehicleType(companyCars: { [company: string]: CarsDocumentItemModel[] } | null) {
        if (companyCars === null) {
            return null;
        }

        const { vehicleType } = this.storeState.settings;

        if (!vehicleType) {
            return companyCars;
        }
        if (vehicleType.filter === VEHICLE_TYPE_FILTER.INCLUDE) {
            return companyCars;
        }

        return Object.keys(companyCars).reduce((acc: { [company: string]: CarsDocumentItemModel[] }, company) => {
            const currentCompany = companyCars[company].filter(carsDocument => {
                if (vehicleType.filter === VEHICLE_TYPE_FILTER.ONLY) {
                    return carsDocument.vehicleType === vehicleType.type;
                }

                if (vehicleType.filter === VEHICLE_TYPE_FILTER.WITHOUT) {
                    return carsDocument.vehicleType !== vehicleType.type;
                }

                return true;
            }) || [];
            acc[company] = currentCompany;
            return acc;
        }, {});
    }

    getCompetitorsByBrokersSelected(companyCars: { [company: string]: CarsDocumentItemModel[]; }) {
        const { brokersCompetitors, currentBrandAsBroker } = this.storeState.settings;
        const brokers = [...(brokersCompetitors || [])];

        if (currentBrandAsBroker === BRAND_AS_BROKER_ANY) {
            return brokers.map(broker => this.getNameOfCompanyHasMinCarPrice(companyCars, broker)).filter(item => item);
        }

        return _.keys(companyCars).filter(company => {
            const [broker, brand] = company.split(',');
            const [, currentBrand] = currentBrandAsBroker.split(',');
            return brokers.includes(broker) && currentBrand === brand;
        });
    }

    resolveCompetitorsSelected(companyCars: { [company: string]: CarsDocumentItemModel[]}) {
        const { currentDocumentCompetitors } = this.storeState.settings;
        const { competitors } = this.storeState.settings;

        if (this.isBrokerToBroker) {
            return this.getCompetitorsByBrokersSelected(companyCars);
        }

        if (!competitors || !currentDocumentCompetitors) {
            return [];
        }

        const selectedCompetitors = currentDocumentCompetitors.filter(item => competitors.includes(item));
        const competitorsArray = [...new Set(selectedCompetitors)];

        return _.intersection(_.keys(companyCars), competitorsArray);
    }

    filterCompetitors(companyCars: { [company: string]: CarsDocumentItemModel[] } | null, filteredKeys: string[]) {
        if (companyCars === null || filteredKeys.length === 0) {
            return null;
        }

        return filteredKeys.reduce((acc: { [company: string]: CarsDocumentItemModel[] }, company) => {
            acc[company] = companyCars[company] || [];
            return acc;
        }, {});
    }

    getNameOfCompanyHasMinCarPrice(companyCars: { [company: string]: CarsDocumentItemModel[]; } | null, broker: string | null) {
        if (!companyCars || !broker) {
            return '';
        }

        const brandsAsBroker = _.keys(companyCars).filter(company => {
            const [brokerAsBrand] = company.split(',');
            return brokerAsBrand === broker && company !== broker;
        });

        const allCompanies: Array<{ company: string, price: number }> = [];

        brandsAsBroker.forEach(company => {
            const car = _.minBy(companyCars[company].filter(item => item.isAvailable), thisCar => this.getCarPrice(thisCar));
            if (car) {
                allCompanies.push({ company, price: this.getCarPrice(car, company) });
            }
        });

        const minCompany = _.minBy(allCompanies, 'price');
        return minCompany ? minCompany.company : '';
    }

    filterCarClasses(day: Day | string, calendarDataSource?: CALENDAR_DATA_SOURCE) {
        const dataFrom = calendarDataSource || CALENDAR_DATA_SOURCE.GROUPED;
        let checkinDate: any = {};

        const data = (dataFrom === CALENDAR_DATA_SOURCE.GROUPED) ? this.data : this.carsPriceHistoryService.data;
        if (!data) {
            return null;
        }
        const { carClasses } = this.storeState.settings;// Current document possible car classes or selected car classes from filter
        const { currentDocumentCarClasses } = this.storeState.settings;
        if (carClasses === null || currentDocumentCarClasses === null) {
            return null;
        }
        const selectedCarClasses = currentDocumentCarClasses.filter(item => carClasses.includes(item));

        if (typeof day === 'number' && (data instanceof CarsDocumentModel || data instanceof CarsAnalysisDocumentModel)) {
            checkinDate = data.checkinDates[day];
        }

        if (typeof day === 'string' && data instanceof CarRatesPriceHistoryModel) {
            checkinDate = data.checkinDates[day];
        }

        if (checkinDate === null) {
            return null;
        }

        if (!checkinDate) {
            return null;
        }

        const companyCars: { [company: string]: CarsDocumentItemModel[] } = (dataFrom === CALENDAR_DATA_SOURCE.GROUPED)
            ? this.getCompanyCarsFromGroupDocuments(checkinDate, selectedCarClasses) : this.getCompanyCarsFromTrends(checkinDate, selectedCarClasses);

        return companyCars;
    }

    getCompanyCarsFromGroupDocuments(checkinDate: Record<string, any>, selectedCarClasses: any) {
        const companyCars: { [company: string]: CarsDocumentItemModel[] } = Object.keys(checkinDate).reduce((acc: { [company: string]: CarsDocumentItemModel[] }, company) => {
            acc[company] = Object.keys(checkinDate[company]).reduce((carsDocumentItems: CarsDocumentItemModel[], companyCarClass) => {
                if (selectedCarClasses.find((carClass: string) => carClass === companyCarClass)) {
                    return carsDocumentItems.concat(checkinDate[company][companyCarClass]);
                }
                return carsDocumentItems;
            }, []);

            return acc;
        }, {});
        return companyCars;
    }

    getCompanyCarsFromTrends(checkinDate: Record<string, any>, selectedCarClasses: any) {
        if (!Object.keys(checkinDate).length) {
            return {};
        }
        const phase = Object.keys(checkinDate.phase).slice(-1)[0];
        const checkinDateLastPhase = checkinDate.phase[phase];
        const companyCars: { [company: string]: CarsDocumentItemModel[] } = Object.keys(checkinDateLastPhase).reduce((acc: { [company: string]: CarsDocumentItemModel[] }, company) => {
            acc[company] = Object.keys(checkinDateLastPhase[company]).reduce((carsDocumentItems: CarsDocumentItemModel[], companyCarClass) => {
                if (selectedCarClasses.find((carClass: string) => carClass === companyCarClass)) {
                    return carsDocumentItems.concat(checkinDateLastPhase[company][companyCarClass]);
                }
                return carsDocumentItems;
            }, []);

            return acc;
        }, {});
        return companyCars;
    }

    currentCompanyCars(companyCars: { [company: string]: CarsDocumentItemModel[];} | null) {
        const { currentBrandAsBroker } = this.storeState.settings;
        const { chainMode } = this.carFiltersStoreState.settings;
        const { currentCompany } = this.userService;

        if (chainMode === BROKER) {
            if (companyCars && currentBrandAsBroker === BRAND_AS_BROKER_ANY) {
                return this.getNameOfCompanyHasMinCarPrice(companyCars, currentCompany);
            }
            return currentBrandAsBroker;
        }

        return this.userService && this.userService.currentCompany;
    }

    getAllBrandsByBroker(companyCars: { [company: string]: CarsDocumentItemModel[]; }, byBroker: string): string[] {
        return _.keys(companyCars).filter(company => {
            const [broker] = company.split(',');
            return byBroker === broker && company !== byBroker;
        });
    }

    allCars(
        day: Day,
        isNeedFilter = true,
        broker?: string,
        calendarDataSource?: CALENDAR_DATA_SOURCE,
    ): { [company: string]: CarsDocumentItemModel | CarsPriceHistoryDocumentItemModel | null } | null | false {
        const dataFrom = calendarDataSource || CALENDAR_DATA_SOURCE.GROUPED;
        const currentDay = (dataFrom === CALENDAR_DATA_SOURCE.GROUPED)
            ? day
            : this.getPreviousDateByDay(day);
        const companyCars = this.filterCarClasses(currentDay, dataFrom);
        const { isAvgPrice } = this.storeState.settings;
        if (!companyCars) {
            return null;
        }
        const currentCompany = this.currentCompanyCars(companyCars);
        let selectedCompetitors = this.resolveCompetitorsSelected(companyCars);

        if (currentCompany) {
            selectedCompetitors.push(currentCompany);
        }

        if (broker) {
            selectedCompetitors = this.getAllBrandsByBroker(companyCars, broker);
        }

        let filteredCars: { [company: string]: CarsDocumentItemModel[] } | null;

        if (!isAvgPrice) {
            filteredCars = this.filterFuelType(companyCars);
            filteredCars = this.filterVehicleType(filteredCars);
            filteredCars = this.filterCompetitors(filteredCars, selectedCompetitors);
            filteredCars = this.filterTransmission(filteredCars);
            filteredCars = this.filterMileage(filteredCars, calendarDataSource);
            filteredCars = this.filterDoors(filteredCars);
            filteredCars = this.filterPaymentTerm(filteredCars, calendarDataSource);
        } else {
            filteredCars = this.filterCompetitors(companyCars, selectedCompetitors);
        }

        if (!filteredCars) {
            return null;
        }
        Object.keys(filteredCars).forEach(company => {
            if (filteredCars && filteredCars[company]) {
                filteredCars[company] = this.getRecentShopByDate(filteredCars[company]);
            }
        });

        if (isNeedFilter || isAvgPrice) {
            Object.keys(filteredCars).forEach(company => {
                if (filteredCars && filteredCars[company]) {
                    filteredCars[company] = filteredCars[company].filter(car => car.isAvailable);
                }
            });
        }
        const allCars = (isNeedFilter) ? this.getAllCars(filteredCars, currentCompany) : this.getAllCarsPopup(filteredCars, currentCompany);
        return Object.keys(allCars).length ? allCars : false;
    }

    getPreviousDateByDay(day: Day) {
        const now = new Date();
        const { month, year } = this.documentFiltersService.storeState.settings;
        const initialDay = this.carsPriceHistoryService.documentDay || new Date().getDate() as Day;

        const isFutureDateSelected = moment({ month: now.getMonth(), year: now.getFullYear(), day: now.getDate() })
            .diff({ day: initialDay, month, year }, 'days') < 0;

        const startMoment = isFutureDateSelected
            ? moment()
            : moment({ day: initialDay, month, year });

        return startMoment.subtract(day, 'd').format('DD-MM-YYYY');
    }

    getAllCars(filteredCars: any, currentCompany: string | null) {
        return Object.keys(filteredCars).reduce((acc: { [company: string]: CarsDocumentItemModel }, company) => {
            if (filteredCars[company] && filteredCars[company].length) {
                acc[company] = filteredCars[company].reduce((cheapestCar: CarsDocumentItemModel, car: CarsDocumentItemModel) => {
                    const price = this.getCarPrice(car, company);
                    const cheapestPrice = this.getCarPrice(cheapestCar, company);
                    return price < cheapestPrice ? car : cheapestCar;
                });
                acc[company].isMainCar = (company === currentCompany);
            }
            return acc;
        }, {});
    }

    getAllCarsPopup(filteredCars: any, currentCompany: string | null) {
        return Object.keys(filteredCars).reduce((acc: { [company: string]: CarsDocumentItemModel | null }, company) => {
            if (filteredCars[company] && filteredCars[company].length) {
                acc[company] = filteredCars[company].reduce((
                    cheapestCar: CarsDocumentItemModel,
                    car: CarsDocumentItemModel,
                ) => {
                    if (!cheapestCar.isAvailable) {
                        return car;
                    }
                    if (!car.isAvailable) {
                        return cheapestCar;
                    }
                    const price = this.getCarPrice(car, company);
                    const cheapestPrice = this.getCarPrice(cheapestCar, company);
                    const selectedCar = price <= cheapestPrice ? car : cheapestCar;
                    return selectedCar;
                });
                if (acc[company]) {
                    acc[company]!.isMainCar = (company === currentCompany);
                }
            } else {
                delete acc[company];
            }
            return acc;
        }, {});
    }

    getRecentShopByDate(offers: any) {
        const groupedOffersByShopDate = _.groupBy(offers, offer => offer.shopDate);
        const recentShopDate = Object.keys(groupedOffersByShopDate).sort((a, b) => new Date(b).valueOf() - new Date(a).valueOf())[0];
        return groupedOffersByShopDate[recentShopDate];
    }

    competitorCars(day: Day, calendarDataSource?: CALENDAR_DATA_SOURCE) {
        const { currentCompany } = this.userService;
        if (!currentCompany) {
            return null;
        }

        const allCars = this.allCars(day, true, undefined, calendarDataSource);

        if (!allCars) {
            return null;
        }

        if (!currentCompany) {
            return allCars;
        }

        if (this.isBrokerToBrand || this.isBrokerToBroker) {
            const search = _.keys(allCars).find(item => item.split(',')[0] === currentCompany) || '';
            delete allCars[search];
            return allCars;
        }

        delete allCars[currentCompany];

        return allCars;
    }

    currentCar(day: Day, calendarDataSource?: CALENDAR_DATA_SOURCE) {
        const allCars = this.allCars(day, true, undefined, calendarDataSource);

        if (!allCars) {
            return null;
        }

        const currentCompany = Object.keys(allCars).reduce((prev, company) => {
            const comp = allCars[company];
            return (comp && comp.isMainCar) ? company : prev;
        }, '');

        if (calendarDataSource === CALENDAR_DATA_SOURCE.HISTORY) {
            return allCars[currentCompany] as CarsPriceHistoryDocumentItemModel || null;
        }
        return allCars[currentCompany] as CarsDocumentItemModel || null;
    }

    getPrice(day: Day, nameOfCompany: string) {
        const allCars = this.allCars(day) as {
            [company: string]: CarsDocumentItemModel
        };

        const { chainMode } = this.carFiltersStoreState.settings;
        let company = nameOfCompany;

        if (!allCars) {
            return null;
        }

        if (chainMode === BROKER) {
            company = _.keys(allCars).find(brand => brand.split(',')[0] === nameOfCompany) || '';
        }

        return allCars[company] ? this.getCarPrice(allCars[company], company) : null;
    }

    getCarDataTable(day: Day, nameOfCompany: string) {
        const { chainMode } = this.carFiltersStoreState.settings;
        const allCars = this.allCars(day, false);
        let company = nameOfCompany;

        this.isNoData(day);
        const defaultValue: {price?: number, isAvailable?: boolean} = {
            price: undefined,
            isAvailable: undefined,
        };

        if (chainMode === BROKER) {
            company = _.keys(allCars).find(brand => brand.split(',')[0] === nameOfCompany) || '';
        }

        if (!allCars || !allCars[company]) {
            defaultValue.isAvailable = true;
            return defaultValue;
        }

        return allCars[company];
    }

    competitorPrice(day: Day, calendarDataSource?: CALENDAR_DATA_SOURCE) {
        switch (this.storeState.settings.priceType) {
            case CAR_PRICE_TYPE.HIGHEST: {
                return this.competitorMax(day, calendarDataSource);
            }
            case CAR_PRICE_TYPE.MEDIAN: {
                return this.competitorMedian(day, calendarDataSource);
            }
            case CAR_PRICE_TYPE.LOWEST: {
                return this.competitorMin(day, calendarDataSource);
            }
            default: {
                return null;
            }
        }
    }

    competitorMedian(day: Day, calendarDataSource?: CALENDAR_DATA_SOURCE) {
        const competitorCars = this.competitorCars(day, calendarDataSource) as {
            [company: string]: CarsDocumentItemModel
        };

        if (!competitorCars) {
            return null;
        }

        const companyKeys = Object.keys(competitorCars);

        if (!companyKeys.length) {
            return null;
        }

        const sortedKeys = companyKeys.sort((companyOne: string, companyTwo: string) => (
            this.getCarPrice(competitorCars[companyOne], companyOne) - this.getCarPrice(competitorCars[companyTwo], companyTwo)))
            .filter(company => this.getCarPrice(competitorCars[company], company));

        if (!sortedKeys.length) {
            return null;
        }

        if (sortedKeys.length % 2 === 1) {
            const middleIndex = Math.ceil((sortedKeys.length - 1) / 2);
            return this.getCarPrice(competitorCars[sortedKeys[middleIndex]], sortedKeys[middleIndex]);
        }

        const middleIndex = sortedKeys.length / 2;
        const firstMidPrice = this.getCarPrice(competitorCars[sortedKeys[middleIndex]], sortedKeys[middleIndex]);
        const secondMidPrice = this.getCarPrice(competitorCars[sortedKeys[middleIndex - 1]], sortedKeys[middleIndex - 1]);
        return (Number(firstMidPrice) + Number(secondMidPrice)) / 2;
    }

    competitorMin(day: Day, calendarDataSource?: CALENDAR_DATA_SOURCE) {
        const competitorCars = this.competitorCars(day, calendarDataSource) as {
            [company: string]: CarsDocumentItemModel | CarsPriceHistoryDocumentItemModel
        };

        if (!competitorCars) {
            return null;
        }

        const companyKeys = Object.keys(competitorCars);

        if (!companyKeys.length) {
            return null;
        }

        return companyKeys.reduce((acc, company) => {
            const price = Number(this.getCarPrice((competitorCars[company] as CarsDocumentItemModel), company));
            return acc > price ? price : acc;
        }, Infinity);
    }

    competitorMax(day: Day, calendarDataSource?: CALENDAR_DATA_SOURCE) {
        const competitorCars = this.competitorCars(day, calendarDataSource) as {
            [company: string]: CarsDocumentItemModel
        };

        if (!competitorCars) {
            return null;
        }

        const companyKeys = Object.keys(competitorCars);

        if (!companyKeys.length) {
            return null;
        }

        return companyKeys.reduce((acc, company) => {
            const price = Number(this.getCarPrice(competitorCars[company], company));
            return acc < price ? price : acc;
        }, 0);
    }

    competitorPercent(day: Day, calendarDataSource?: CALENDAR_DATA_SOURCE) {
        const currentCar = this.currentCar(day) as CarsDocumentItemModel;
        const { currentCompany } = this.userService;

        if (!currentCar || !currentCompany) {
            return null;
        }

        const competitorPrice = this.competitorPrice(day, calendarDataSource);
        if (!competitorPrice) {
            return null;
        }
        return competitorPrice ? this.getCarPrice(currentCar, currentCompany) / competitorPrice - 1 : null;
    }

    isCompetitorsSoldOut(day: Day, calendarDataSource?: CALENDAR_DATA_SOURCE) {
        const competitorPrice = this.competitorPrice(day, calendarDataSource);
        return !competitorPrice;
    }

    get calendarAssessment() {
        const { priceType } = this.storeState.settings;
        return this.storeState.settings.assessmentSettings[priceType];
    }

    async updateAssessment(assessment: [Percent, Percent]) {
        const { priceType } = this.storeState.settings;

        if (assessment[0] > assessment[1]) {
            return;
        }

        this.storeState.settings = {
            ...this.storeState.settings,
            assessmentSettings: {
                ...this.storeState.settings.assessmentSettings,
                [priceType]: assessment,
            },
        };

        setCachedAssessmentSettings(this.storeState.settings.assessmentSettings);
    }

    getCardAssessment(_percent: Percent | null): ASSESSMENTS_TYPES | null {
        const { priceType } = this.storeState.settings;

        if (_percent === null || !priceType) {
            return null;
        }

        const percent = Number(_percent.toFixed(2));

        const { assessmentSettings } = this.storeState.settings;
        const [from, to] = assessmentSettings[priceType];

        switch (priceType) {
            case CAR_PRICE_TYPE.LOWEST: {
                if (percent > to) {
                    return ASSESSMENTS_TYPES.GOOD;
                }
                if (percent < from) {
                    return ASSESSMENTS_TYPES.BAD;
                }
                return ASSESSMENTS_TYPES.NORMAL;
            }
            case CAR_PRICE_TYPE.MEDIAN: {
                if (percent < from) {
                    return ASSESSMENTS_TYPES.GOOD;
                }
                if (percent > to) {
                    return ASSESSMENTS_TYPES.BAD;
                }
                return ASSESSMENTS_TYPES.NORMAL;
            }
            case CAR_PRICE_TYPE.HIGHEST: {
                if (percent < from) {
                    return ASSESSMENTS_TYPES.GOOD;
                }
                if (percent > to) {
                    return ASSESSMENTS_TYPES.BAD;
                }
                return ASSESSMENTS_TYPES.NORMAL;
            }
            default: {
                return null;
            }
        }
    }

    getTableAssessment(price: Price, day: Day): ASSESSMENTS_TYPES | null {
        const { priceType } = this.storeState.settings;
        const currentCar = this.currentCar(day) as CarsDocumentItemModel;

        if (currentCar === null) {
            return null;
        }

        if (priceType === CAR_PRICE_TYPE.HIGHEST || priceType === CAR_PRICE_TYPE.MEDIAN) {
            return price > this.getCarPrice(currentCar) ? ASSESSMENTS_TYPES.GOOD : ASSESSMENTS_TYPES.BAD;
        }

        return price <= this.getCarPrice(currentCar) ? ASSESSMENTS_TYPES.GOOD : ASSESSMENTS_TYPES.BAD;
    }

    get fromAssessmentItems() {
        const { priceType } = this.storeState.settings;
        return ASSESSMENTS_ITEMS[priceType].from;
    }

    get toAssessmentItems() {
        const { priceType } = this.storeState.settings;
        return ASSESSMENTS_ITEMS[priceType].to;
    }

    calculateShopDate(day: Day, calendarDataSource?: CALENDAR_DATA_SOURCE) {
        const cars = this.allCars(day, true, undefined, calendarDataSource) as {
            [company: string]: CarsDocumentItemModel
        };

        if (!cars) {
            return null;
        }

        return Object.entries(cars).reduce(
            (acc: null | Date, [, car]) => (car.shopDate && (!acc || car.shopDate > acc) ? car.shopDate : acc),
            null,
        );
    }

    get carsGraphColor(): { [company: string]: string } {
        const colors: { [company: string]: string } = {};
        const { dataSources } = this.carFiltersStoreState.settings;
        const { competitors } = this.storeState.settings;
        const companiesList = _.union(dataSources, competitors);

        companiesList.forEach(companyName => {
            colors[companyName] = PROVIDER_COLORS[companyName] || GRAPH_COLORS.pop() || '#123456';
        });

        return colors;
    }

    get FleetFileName() {
        if (!this.storeState.fleetAvailability) {
            return '';
        }
        return this.storeState.fleetAvailability.fileName;
    }

    get FleetUpdatedDate() {
        if (!this.storeState.fleetAvailability) {
            return '';
        }
        return this.storeState.fleetAvailability.updatedDate;
    }

    isNoData(day: Day) {
        if (!this.storeState.document) {
            return true;
        }

        if (!this.storeState.document.checkinDates[day]) {
            return true;
        }

        return false;
    }

    set currentChain(value: BranchesModel | null | undefined) {
        localStorage.setItem('currentChain', JSON.stringify(value));
    }

    get currentChain(): BranchesModel | null | undefined {
        const chain = JSON.parse(localStorage.getItem('currentChain') || '{}');

        if (chain.chainId) {
            return plainToClass(BranchesModel, <unknown> chain, { excludeExtraneousValues: true });
        }

        return this.storeState.settings.chain || this.carFiltersStoreState.branches[0];
    }

    get isBroker() {
        return !!(this.currentChain && this.currentChain.isBroker);
    }

    get currentClusterLocationIds() {
        const { country, cluster } = this.storeState.settings;
        if (country && cluster && this.carFiltersStoreState.settings.clusters) {
            return this.carFiltersStoreState.settings.clusters[country][cluster];
        }
        return [];
    }

    reloadDocument() {
        if (this.storeState.settings.isAvgPrice) {
            this.storeState.loading.reset();
        }
    }

    get numberOfCompetitors() {
        if (this.storeState.settings.competitors) {
            return this.storeState.settings.competitors.length;
        }
        return 0;
    }

    positionOfRanking(day: Day) {
        const allCars = this.allCars(day, false) as {
            [company: string]: CarsDocumentItemModel
        };
        const { currentCompany } = this.userService;
        const position = Object.entries(allCars || { }).map((value: [string, CarsDocumentItemModel]) => (
            { company: value[0], price: this.getCarPrice(value[1]), isAvailable: value[1].isAvailable }
        )).filter(item => (item.isAvailable)).sort((a, b) => {
            const secondPrice = b.price ? b.price : -1;
            const firstPrice = a.price ? a.price : -1;
            if (this.storeState.settings.isAvgPrice) {
                return firstPrice - secondPrice;
            }
            return secondPrice - firstPrice;
            // eslint-disable-next-line newline-per-chained-call
        }).findIndex(item => item.company === currentCompany);

        return {
            position: position === -1 ? 0 : position + 1,
            numberOfCompetitors: this.numberOfCompetitors + 1,
        };
    }

    getBrandsForBroker(data: CarsDocumentModel | null) {
        const { currentCompany } = this.userService;
        if (data && currentCompany) {
            let brands: string[] = [];
            Object.entries(data.checkinDates).forEach(([day, company]) => {
                const companies = _.keys(company).filter(item => item !== currentCompany && item.split(',')[0] === currentCompany);
                brands = _.union(brands, companies);
            });
            return _.uniq(brands).sort();
        }
        return [];
    }

    getCarPrice(carItem: CarsDocumentItemModel| CarsPriceHistoryDocumentItemModel, provider?: string) {
        if ((carItem as CarsPriceHistoryDocumentItemModel).price !== undefined) {
            return (carItem as CarsPriceHistoryDocumentItemModel).price;
        }
        const car = carItem as CarsDocumentItemModel;
        switch (this.priceShown) {
            case PRICE_SHOWN.CALCULATED: {
                return !this.isAvgPrice ? this.getCalculatedPrice(car.priceTotal, provider) : car.priceTotal;
            }
            case PRICE_SHOWN.TOTAL:
                return car.priceTotal;
            case PRICE_SHOWN.NET:
                return car.priceNet;
            case PRICE_SHOWN.SHOWN:
                return car.priceShown;
            default:
                return car.priceTotal;
        }
    }

    getCalculatedPrice(price: number, provider?: string) {
        const { currentCompany } = this.userService;
        let calculatedPrice = price;

        if (!provider && !currentCompany !== null) {
            return calculatedPrice;
        }

        const expenses = this.getExtraExpensesByProvider(provider);

        expenses.forEach(item => {
            const { increaseType, value } = item;
            calculatedPrice += (increaseType === IncreaseType.Percentage ? calculatedPrice * value : value);
        });

        return calculatedPrice;
    }

    getExtraExpensesByProvider(provider?: string) {
        const { extraExpenses } = this.carFiltersStoreState.settings;
        const { settings } = this.storeState;
        const { pos, pickUpCityCode } = settings;
        const { currentCompany } = this.userService;
        return extraExpenses
            .filter(item => (item.pos === pos
                && item.fornovaLocationId === Number(pickUpCityCode)
                && item.providerName === (provider || currentCompany)
                && this.isDataSourceMatchExtraExpense(item.source || 'Brand')))
            .sort((a, b) => {
                if (a.increaseType > b.increaseType) {
                    return -1;
                }
                if (a.increaseType < b.increaseType) {
                    return 1;
                }
                return 0;
            });
    }

    isDataSourceMatchExtraExpense(source: string) {
        return this.storeState.settings.dataSource === source;
    }

    get isShownPricesAvailable() {
        let isAvailable = false;
        this.documentFiltersService.days.forEach(day => {
            const allCars = (this.allCars(day as Day, false) || {}) as {
                [company: string]: CarsDocumentItemModel
            };
            Object.values(allCars).forEach(car => {
                if (car.priceShown !== undefined) {
                    isAvailable = true;
                }
            });
        });
        return isAvailable;
    }

    get isAvgPrice() {
        return this.storeState.settings.isAvgPrice;
    }

    set isAvgPrice(value) {
        this.storeState.settings.isAvgPrice = value;
    }

    get allBrandsAsBroker() {
        return this.storeState.settings.allBrands;
    }

    get currentDataSourceMode() {
        return this.storeState.settings.currentDataSourceMode;
    }

    get currentBrandAsBroker() {
        return this.storeState.settings.currentBrandAsBroker;
    }

    get isBrokerToBroker() {
        const { chainMode } = this.carFiltersStoreState.settings;
        const { currentDataSourceMode } = this.storeState.settings;
        return chainMode === BROKER && currentDataSourceMode === BROKER_TO_BROKER;
    }

    get isBrokerToBrand() {
        const { chainMode } = this.carFiltersStoreState.settings;
        const { currentDataSourceMode } = this.storeState.settings;
        return chainMode === BROKER && currentDataSourceMode === BROKER_TO_BRAND;
    }

    get isBrokerMode() {
        const { chainMode } = this.carFiltersStoreState.settings;
        return chainMode === BROKER;
    }

    get isBrandMode() {
        const { chainMode } = this.carFiltersStoreState.settings;
        return !chainMode || chainMode === BRAND;
    }

    get brokersCompetitors() {
        return this.storeState.settings.brokersCompetitors;
    }

    resolveCompanyName(company: string) {
        const { isBrokerToBrand, isBrokerToBroker, currentBrandAsBroker } = this;
        const { currentCompany } = this.userService;
        if (isBrokerToBrand && company.split(',')[0] === currentCompany) {
            const [broker, brand] = currentBrandAsBroker.split(',');
            if (currentBrandAsBroker === BRAND_AS_BROKER_ANY) {
                return `${company} (All)`;
            }
            return `${broker} (${brand})`;
        }
        if (isBrokerToBroker) {
            const [, brand] = currentBrandAsBroker.split(',');
            if (currentBrandAsBroker === BRAND_AS_BROKER_ANY) {
                return `${company} (All)`;
            }
            return `${company} (${brand})`;
        }
        return company;
    }

    get firstAvailableDate() {
        if (!this.carsPriceHistoryService.get30Days) {
            return 0;
        }
        let currentDay = 0;
        [...this.carsPriceHistoryService.get30Days, 0].forEach(day => {
            const allCars = this.allCars(day as Day, true, undefined, CALENDAR_DATA_SOURCE.HISTORY);
            if (allCars) {
                currentDay = day;
            }
        });
        return currentDay;
    }

    get dataSourcesFromDoc() {
        const { document } = this.storeState;
        return document ? document.dataSource : [];
    }

    get showDiff() {
        return this.storeState.showPriceDiff;
    }

    set showDiff(value: boolean) {
        this.storeState.showPriceDiff = value;
    }

    get priceShown() {
        return this.storeState.settings.priceShown;
    }

    set priceShown(value: PRICE_SHOWN) {
        this.storeState.settings.priceShown = value;
    }
}
