
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import CustomNotificationService, { CustomNotificationServiceS } from '@/modules/common/modules/custom-notification/custom-notification.service';
import NOTIFICATION_TYPES from '@/modules/common/modules/custom-notification/constants/notification-types.constant';
import RegularNotification from './regular-notification.vue';
import ExcelNotification from './excel-notification.vue';

@Component({
    components: {
        RegularNotification,
        ExcelNotification,
    },
})
export default class NotificationsContainer extends Vue {
    @Inject(CustomNotificationServiceS)
    private customNotificationService!: CustomNotificationService;

    componentMap = {
        [NOTIFICATION_TYPES.EXCEL]: ExcelNotification,
        [NOTIFICATION_TYPES.REGULAR]: RegularNotification,
    };

    get notifications() {
        return this.customNotificationService.notifications;
    }
}
