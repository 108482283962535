import { Inject, injectable } from 'inversify-props';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import MarketsStore from '@/modules/markets/store/markets.store';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import UserSettingsService, { UserSettingsS } from '../user/user-settings.service';

export const MarketsFiltersServiceS = Symbol.for('MarketsFiltersServiceS');
@injectable(MarketsFiltersServiceS as unknown as string)
export default class MarketsFiltersService implements Stateable {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(UserSettingsS) private userSettingsService!: UserSettingsService;

    readonly storeState: MarketsStore = this.storeFacade.getState('MarketsStore');

    constructor() {
        this.reset();

        this.storeFacade.watch(() => [
            this.documentFiltersService.storeState.settings.compsetId,
        ], this.reset.bind(this));
    }

    reset() {
        if (!this.compsetsService || this.compsetsService.currentCompset === null) {
            return;
        }

        const { marketProviders, pos, mainPos } = this.compsetsService.currentCompset;

        if (this.storeState.settings.provider === null
            || !marketProviders.find(item => item === this.storeState.settings.provider)
        ) {
            this.storeState.settings.provider = (marketProviders && marketProviders.length)
                ? marketProviders[0]
                : this.storeState.settings.provider;
        }

        if (mainPos) {
            this.documentFiltersService.savePos(mainPos);
            // this.pos = 'US';
        } else if (this.documentFiltersService.settings.pos === null
            || !pos.find(item => item === this.documentFiltersService.settings.pos)) {
            this.documentFiltersService.savePos(pos && pos.length
                ? pos[0]
                : this.documentFiltersService.settings.pos);
        }

        this.verifyPos();
    }

    verifyPos() {
        const { pos } = this.documentFiltersService.settings;

        if (!pos) {
            return;
        }

        const isPosInList = !!this.documentFiltersService.getPosItems(null).find(item => item.value === pos);

        if (isPosInList) {
            return;
        }

        const [item] = this.documentFiltersService.posMarketItems;

        this.documentFiltersService.savePos((item && item.value) ? String(item.value) : null);
    }

    async saveProvider(provider: string | null): Promise<void> {
        this.storeState.settings.provider = provider;
    }

    get currentProvider() {
        if (this.storeState.settings.provider === null) {
            this.reset();
        }

        return this.storeState.settings.provider;
    }
}
