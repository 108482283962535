import { plainToClass } from 'class-transformer';
import { Inject, injectable } from 'inversify-props';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import LocationAvailabilityDocumentModel from '@/modules/cars/modules/location-availability/models/location-availability-document.model';
import LocationAvailabilitySettingsModel from '@/modules/cars/modules/location-availability/models/location-availability-settings.model';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';

export const LocationAvailabilityApiServiceS = Symbol.for('LocationAvailabilityApiServiceS');
@injectable(LocationAvailabilityApiServiceS as unknown as string)
export default class LocationAvailabilityApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(ValidatorServiceS) private validatorService!: ValidatorService;

    async getNotAvailableDocs(settings: DocumentFiltersModel, lAvailabilitySettings: LocationAvailabilitySettingsModel) {
        const { month, year } = settings;
        const { pos, pickUpLocationIds } = lAvailabilitySettings;
        const codes = pickUpLocationIds!.toString();
        const { data } = await this.apiService.get(`car/get-not-available-docs/${year}/${month + 1}/${pos}/${codes}`);
        return plainToClass(LocationAvailabilityDocumentModel, <LocationAvailabilityDocumentModel[]> data, { excludeExtraneousValues: true });
    }
}
