import { Expose, plainToClass, Transform } from 'class-transformer';
import { IsNumber, IsOptional, Validate } from 'class-validator';
import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import type Day from '@/modules/common/types/day.type';
import PriceHistoryTrendDataValidator from './validators/trend-data.validator';

export default class RatesPriceHistoryModel {
    @Expose()
    @Transform((_, plain) => plain.day)
    @IsNumber()
    @IsOptional()
    requestDay: Day | null = null;

    @Expose()
    @Transform((_, plain) => {
        const trendData: any = {};

        if (!plain.trend_data) {
            return plain.trend_data;
        }

        Object.keys(plain.trend_data).forEach(date => {
            trendData[date] = { hotels: {} };
            Object.keys(plain.trend_data[date]).forEach(hotelId => {
                if (hotelId !== 'day_statistics') {
                    trendData[date].hotels[hotelId] = { rooms: {}, link: null };

                    if (plain.trend_data[date][hotelId].rooms) {
                        Object.keys(plain.trend_data[date][hotelId].rooms).forEach(roomTypeId => {
                            trendData[date].hotels[hotelId].rooms[roomTypeId] = [];
                            const ratesDocumentItems: RatesDocumentItemModel[] = [];

                            Object.entries(plain.trend_data[date][hotelId].rooms[roomTypeId]).forEach(([__, ratesDocumentItem]) => {
                                ratesDocumentItems.push(
                                    plainToClass(RatesDocumentItemModel, <RatesDocumentItemModel> ratesDocumentItem, { excludeExtraneousValues: true }),
                                );
                            });

                            trendData[date].hotels[hotelId].updateDate = plain.trend_data[date][hotelId].update_date;
                            trendData[date].hotels[hotelId].losRestriction = plain.trend_data[date][hotelId].los_restricted;
                            trendData[date].hotels[hotelId].link = plain.trend_data[date][hotelId].link;
                            trendData[date].hotels[hotelId].rooms[roomTypeId] = ratesDocumentItems;
                        });
                    }
                }

                const { day_statistics: dayStatistics } = plain.trend_data[date];
                trendData[date].occupancy = null;
                trendData[date].demand = null;

                if (!dayStatistics) {
                    return;
                }

                if (Number.isFinite(dayStatistics.demand)) {
                    if (dayStatistics.demand < 0) {
                        trendData[date].demand = 0;
                    } else {
                        trendData[date].demand = Math.round(dayStatistics.demand * 100);
                    }
                }

                if (Number.isFinite(dayStatistics.occupancy)) {
                    if (dayStatistics.occupancy < 0) {
                        trendData[date].occupancy = 0;
                    } else {
                        trendData[date].occupancy = Math.round(dayStatistics.occupancy);
                    }
                }
            });
        });

        return trendData;
    })
    @Validate(PriceHistoryTrendDataValidator)
    trendData!: {
        [date: string]: {
            hotels: {
                [hotelId: number]: {
                    rooms: {
                        [roomTypeId: number]: RatesDocumentItemModel[],
                    }
                    updateDate?: Date,
                    link: string | null,
                    losRestriction?: number | null,
                },
            },
            demand: number | null,
            occupancy: number | null,
        } | null
    };
}
