import { Inject, injectable } from 'inversify-props';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import InsightFiltersStore from './store/insight-fiters.store';
import { InsightFilterSettingsModel, InsightFilterOptionsModel } from './models/insight-filters.model';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import InsightsApiService, { InsightsApiServiceS } from '../insights/insights-api.service';

export const InsightFiltersServiceS = Symbol.for('InsightFiltersServiceS');
@injectable(InsightFiltersServiceS as unknown as string)
export default class InsightFiltersService {
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(InsightsApiServiceS) private insightsApiService!: InsightsApiService;

    private readonly storeState: InsightFiltersStore = this.storeFacade.getState('InsightFiltersStore');

    get settings() {
        return this.storeState.settings;
    }

    get options() {
        return {
            insightTypes: this.insightTypeOptions,
            dateRange: this.dateRangeOptions,
        };
    }

    get loading() {
        return this.storeState.loading;
    }

    updateSettings(s: Partial<InsightFilterSettingsModel>) {
        this.storeState.settings = {
            ...this.storeState.settings,
            ...s,
        };
    }

    updateOptions(o: Partial<InsightFilterOptionsModel>) {
        this.storeState.options = {
            ...this.storeState.options,
            ...o,
        };
    }

    resetOptions() {
        this.storeState.options = new InsightFilterOptionsModel();
    }

    resetSettings() {
        this.storeState.settings = new InsightFilterSettingsModel();
    }

    private get insightTypeOptions() {
        this.helperService.dynamicLoading(this.storeState.loading.insightTypes, async () => {
            const insightTypes = await this.insightsApiService.getInsightTypes();
            this.updateSettings({ insightTypes: insightTypes.filter(t => t.isActive) });
            this.updateOptions({ insightTypes });
            return true;
        });
        return this.storeState.options.insightTypes;
    }

    private get dateRangeOptions() {
        return this.storeState.options.dateRange;
    }
}
