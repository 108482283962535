import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import { event } from 'vue-gtag';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import MarketsDocumentModel from '@/modules/markets/models/markets-document.model';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import DownloadExcelModel from '@/modules/rates/models/download-excel.model';
import MarketSettingsModel from './models/market-settings.model';
import MarketsTriggerScanModel from './models/market-trigger-scan.model';
import Item from '../common/interfaces/item.interface';
import UserService, { UserServiceS } from '../user/user.service';
import OpenTelemetryService, { OpenTelemetryServiceS } from '../open-telemetry/open-telemetry.service';
import { LOGTYPE } from '../open-telemetry/constants';
import Day from '../common/types/day.type';

const marketsDocumentCache: { [k: string]: any } = {};

export interface DownloadExcelForm {
    choosedProviders: Item[];
    providers: (string | number)[];
    provider: string | number | null;
    los: number | null;
    pos: string | null;
    month: number;
    year: number;
    compsetId: string | null;
    date?: Date;
    columns: Record<string, boolean>;
}

export interface DemandRequestConfig {
    marketId: number;
    pos: string;
    year: number;
    month: number;
    ignoreCache?: boolean;
}

export const MarketsApiServiceS = Symbol.for('MarketsApiServiceS');
@injectable(MarketsApiServiceS as unknown as string)
export default class MarketsApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(OpenTelemetryServiceS) private openTelemetryService!: OpenTelemetryService;

    async getMarketsDocument(
        documentSettings: DocumentFiltersModel,
        marketSettings: MarketSettingsModel,
        force?: boolean,
    ): Promise<MarketsDocumentModel | null> {
        const { provider } = marketSettings;
        const {
            year, month, compsetId, los, pos,
        } = documentSettings;

        const { los: defaultLos } = this.userService.user.settings.defaultFilters;
        const requestRoute = `/market/${compsetId}/${year}/${month + 1}/${provider}/${los || defaultLos}/${pos}?statistics=true`;

        let promise: Promise<any> | null = null;

        if (!force && marketsDocumentCache[requestRoute]) {
            if (marketsDocumentCache[requestRoute] instanceof Promise) {
                promise = marketsDocumentCache[requestRoute];
            } else {
                return marketsDocumentCache[requestRoute];
            }
        }

        if (!promise) {
            promise = this.apiService.get(requestRoute);
        }

        marketsDocumentCache[requestRoute] = promise;

        const response = await promise;

        if (!response.data) {
            return null;
        }

        marketsDocumentCache[requestRoute] = plainToClass(
            MarketsDocumentModel,
            <MarketsDocumentModel> response.data,
            { excludeExtraneousValues: true },
        );

        return marketsDocumentCache[requestRoute];
    }

    async getExcelDocument(settings: DownloadExcelForm, toEmail?: boolean) {
        const {
            provider, los, pos, compsetId, year, month, columns,
        } = settings;
        const numericalValueMap: Record<string, number> = {
            three: 3,
            seven: 7,
            fourteen: 14,
        };
        const params = [compsetId, year, month + 1, provider, los, pos].join('/');
        const columnsOptions = Object.entries(columns).reduce((acc, [key, value]) => {
            if (!value) return acc;
            const numericalText = key.split('_')[0];
            const numericalValue = numericalValueMap[numericalText];
            return [...acc, numericalValue];
        }, [] as number[]);
        const columnsQuery = this.apiService.generateQueryByArray('columnsOptions[diff_days]', Object.values(columnsOptions));

        event('download_excel', {
            chainId: this.userService.chainId,
            userId: this.userService.id,
        });

        this.openTelemetryService.startSpan({ name: `market-${provider}`, prefix: LOGTYPE.DOWNLOAD });

        const { data } = await this.apiService.get(
            `market/excel/${params}?fornova_id=${this.userService.currentHotelId}${columnsOptions?.length ? `&${columnsQuery}` : ''}`,
            { send_to_email: toEmail },
        );
        this.openTelemetryService.endSpan({ name: `market-${provider}`, prefix: LOGTYPE.DOWNLOAD }, { sendLogs: true });

        if (!data) {
            return null;
        }

        return plainToClass(DownloadExcelModel, <DownloadExcelModel>data, { excludeExtraneousValues: true });
    }

    async triggerScan(compsetId: string, los: number, pos: string, provider: string, startDate: Date, endDate?: Date) {
        this.openTelemetryService.startSpan({ name: 'market', prefix: LOGTYPE.ONDEMAND });
        try {
            const { data } = await this.apiService.post('/scan/mv-trigger/by-compset-id', {
                ondemand: 'true',
                compSetIds: [compsetId],
                pos: [pos],
                los: [los],
                providers: [provider],
                start_date: `${startDate.getFullYear()}-${(`0${startDate.getMonth() + 1}`).slice(-2)}-${(`0${startDate.getDate()}`).slice(-2)}`,
                end_date: endDate && `${endDate.getFullYear()}-${(`0${endDate.getMonth() + 1}`).slice(-2)}-${(`0${endDate.getDate()}`).slice(-2)}`,
            });
            this.openTelemetryService.endSpan({ name: 'market', prefix: LOGTYPE.ONDEMAND }, { sendLogs: true });

            if (!data) return null;

            return plainToClass(MarketsTriggerScanModel, <MarketsTriggerScanModel> data, { excludeExtraneousValues: true });
        } catch (err) {
            this.openTelemetryService.endSpan(
                { name: 'market', prefix: LOGTYPE.ONDEMAND },
                { sendLogs: true, payload: { 'cx.action.error': (err as Error).message } },
            );
            throw err;
        }
    }

    async resendScheduledReport(level: 'hotel' | 'cluster', schedulerId: number) {
        const endpoint = {
            hotel: `/market/excel/by-scheduler-config-id/${schedulerId}`,
            cluster: `/market/cluster-excel/by-scheduler-config-id/${schedulerId}`,
        };

        const { data } = await this.apiService.post(endpoint[level], {});

        if (!data) return null;

        return data;
    }

    async getDemandData(config: DemandRequestConfig) {
        const { marketId, pos, year } = config;
        const { month, ignoreCache } = config;

        const requestRoute = `/market/demand/${marketId}/${pos}/${year}/${month + 1}/${!!ignoreCache}`;

        try {
            const { data } = await this.apiService.get(requestRoute);
            return Object.fromEntries(Object.entries(data.checkin_dates as Record<Day, number>).map(([key, value]) => (
                [key, Math.round(value * 100)]
            )));
        } catch {
            return null;
        }
    }
}
