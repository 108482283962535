import UserNavigationService, { UserNavigationServiceS } from '@/modules/user/user-navigation.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';

import { Inject, injectable } from 'inversify-props';
import { IMiddleware } from '@/router/interfaces/middleware.interface';
import { Route } from 'vue-router';

export const HomepageRedirectMiddlewareS = Symbol.for('HomepageRedirectMiddlewareS');
@injectable(HomepageRedirectMiddlewareS as unknown as string)
export default class HomepageRedirectMiddleware implements IMiddleware {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(UserNavigationServiceS) private userNavigationService!: UserNavigationService;

    // @demo Replace homepage to rates for chain for dem
    private readonly carHomePath = '/cars-rates';

    async canActivate(from: Route) {
        if (this.userService.isCarUser) {
            return this.carHomePath;
        }

        const targetRoute = this.userNavigationService.firstEnabledRoute;
        const haveOneEnabledRoute = !!targetRoute;

        if (haveOneEnabledRoute) {
            if (from.path.includes(targetRoute)) {
                return true;
            }

            return this.userNavigationService.firstEnabledRoute!;
        }

        return true;
    }
}
