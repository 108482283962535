import { Inject, injectable } from 'inversify-props';
import { IMiddleware } from '@/router/interfaces/middleware.interface';
import UserService, { UserServiceS } from '@/modules/user/user.service';

const TARGET_PATH = '/onboarding';
export const OnboardingMiddlewareS = Symbol.for('OnboardingMiddlewareS');

@injectable(OnboardingMiddlewareS as unknown as string)
export default class OnboardingMiddleware implements IMiddleware {
    @Inject(UserServiceS) private userService!: UserService;

    async canActivate() {
        await this.userService.storeState
            .loading.whenLoadingFinished();

        const { pathname } = window.location;
        const { isNewUser } = this.userService;
        const isOnboardingPage = pathname.includes(TARGET_PATH);

        if (isNewUser && !isOnboardingPage) {
            window.location.href = TARGET_PATH;
            return true;
        }

        if (!isNewUser && isOnboardingPage) {
            window.location.href = '/';
        }

        return true;
    }
}
