import moment from 'moment';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';

export const checkDateIsFuture = (month: Month | undefined, year: Year | undefined, compareMonth: Month | undefined, compareYear: Year | undefined)
: number => {
    const date = moment({ year, month, day: 1 });
    const compareDate = moment({ year: compareYear, month: compareMonth, day: 1 });
    if (date.isSame(compareDate)) {
        return 0;
    } if (date.isBefore(compareDate)) {
        return -1;
    } if (date.isAfter(compareDate)) {
        return 1;
    }
    return 2;
};
