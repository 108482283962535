import { Inject, injectable } from 'inversify-props';
import { cloneDeep } from 'lodash';

import RoomsTypeManagerApiService, { RMSStatePayload, RoomsTypeManagerApiServiceS } from '@/modules/rooms-type-manager/rooms-type-manager-api.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import HotelsRoomTypeStore from '@/modules/rooms-type-manager/store/hotels-room-type.store';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import CompsetsService, { CompsetsServiceS } from '../compsets/compsets.service';
import UserService, { UserServiceS } from '../user/user.service';
import RoomsRMSStateModel from './models/rooms-rms-state.model';
import downloadBlobAsFile from '../common/filters/download-file';
import OpenTelemetryService, { OpenTelemetryServiceS } from '../open-telemetry/open-telemetry.service';
import { LOGTYPE } from '../open-telemetry/constants';
import HotelsRoomTypeModel from './models/hotels-room-type.model';
import type { MergeGroupParams, RoomsGroup, UnflagGroupParams } from './types';
import ArchivedRoomsModel from './models/archived-rooms.model';

interface RoomsTypeManagerPublicInterface {
    /** Getter with dynamic loading. Launch loadData method if data not actual, to load room types data. */
    readonly data: HotelsRoomTypeModel | null;

    /** Return list of provider from data, excluding all and cheapest */
    readonly providers: string[];

    /** Get hotel ids from data and map it with hotel names */
    readonly hotels: {
        id: number;
        name: string;
    }[];

    /** Show is archiving process running */
    readonly isArchiveListUpdating: boolean;

    /** Shows are there any changes, which not applied on BE */
    isDocumentChanged: boolean;

    /**
     * Load room type data
     * @returns true in case successfull loading
     */
    loadData: () => Promise<boolean>;

    /**
     * Get list of room names
     * @param hotelId any fornova id available in the compset
     * @param provider available in the compset source
     * @param roomTypeId id of room type
     * @returns room groups from data by specified parameters
     */
    getRoomGroups: (hotelId: number, provider: string, roomTypeId: number) => RoomsGroup[];

    /**
     * Get list of room names
     * @param hotelId any fornova id available in the compset
     * @param provider available in the compset source
     * @returns archived room group by specified parameters
     */
    getArchivedRoomGroups: (hotelId: number, provider: string) => RoomsGroup[];

    /** Set provided source in the store */
    saveSource: (source: string | null) => void;

    /** Are there any rooms in room type. */
    isRoomTypeHaveRooms: (roomTypeId: number) => boolean;

    /**
     * Returns list of archived groups
     * @param mainHotelId id of one user's main hotel
     * @returns list of names.
     */
    getArchivedRoomList: (mainHotelId: number) => Promise<ArchivedRoomsModel | null>;

    /**
     * Move provided room groups to archive.
     * @param groups model of groups to be archived.
    */
    addRoomGroupsToArchive: (groups: ArchivedRoomsModel) => Promise<void>

    /**
     * Restore from archive provided room groups.
     * @param groups model of groups to be restored.
    */
    restoreRoomGroupsFromArchive: (groups: ArchivedRoomsModel) => Promise<void>

    /** Resets loading, so calling data getter next time will trigger request. Also resets all changes and room type data in the store */
    reset: () => void;

    /**
     * Moves room into a new room type inside existing document without saving.
     * @param hotelId any fornova id available in the compset.
     * @param roomTypeId id of room type.
     * @param provider available in the compset source.
     * @param roomName name of room to move.
     * @param targetRoomTypeId id of room type to move room in.
     */
    moveRoom: (hotelId: number, roomTypeId: number, provider: string, roomName: string, targetRoomTypeId: number) => void;

    /**
     * Moves all rooms from one room type to another and send request to BE to apply changes.
     * @param mainHotelId user selected hotel's id.
     * @param roomTypeId room type id to move from.
     * @param targetRoomTypeId room type id to move in.
     */
    moveAllRoomsToRoomType: (mainHotelId: number, roomTypeId: number, targetRoomTypeId: number) => Promise<void>;

    /**
     * Send request to save changes on BE
     * @param mainHotelId user selected hotel's id.
     */
    saveDocument: (mainHotelId: number) => Promise<void>;

    /**
     * Check if there are rooms for provided source and hotel for non archived groups
     * @param provider available in the compset source.
     * @param hotelId any fornova id available in the compset.
     * @returns true if provider has room, false otherwise.
     */
    isAllGroupsProviderHasRooms: (provider: string, hotelId: number) => boolean;

    /**
     * Check if there are rooms for provided source and hotel for archived groups
     * @param provider available in the compset source.
     * @param hotelId any fornova id available in the compset.
     * @returns true if provider has room, false otherwise.
     */
    isArchivedGroupsProviderHasRooms: (provider: string, hotelId: number) => boolean;

    /**
     * Send request to get info for which room types RMS blocked.
     * @param mainHotelId user selected hotel's id.
     * @returns list of blocked room type ids and fornova id.
     */
    getRoomsRMSState: (mainHotelId: number) => Promise<RoomsRMSStateModel[]>;

    /**
     * Send request to block RMS for provided fornova id and list of rooms.
     * @param mainHotelId user selected hotel's id.
     * @param newStates list of room types with related fornova id to block.
     */
    eliminateRoomsRMS: (mainHotelId: number, newStates: RMSStatePayload[]) => Promise<void>;

    /**
     * Send request to restore blocked RMS for provided fornova id and list of rooms.
     * @param mainHotelId user selected hotel's id.
     * @param newStates list of room types with related fornova id to restore.
     */
    restoreRoomsRMS: (mainHotelId: number, newStates: RMSStatePayload[]) => Promise<void>;

    /**
     * Downloads excel on setting room mapping.
     * Temporary solution, when dynamic excel on BE will be supported, should be
     * handled via notificationService.
     * @param hotelId
     * @param provider
     * @returns false if BE doesn't return any data, otherwise true.
     */
    downloadExcel: (hotelId: number, provider: string) => Promise<boolean>;

    /**
     * Update rooms group to change rooms inside. If no rooms in the group, it won't be shown.
     * Rooms removed from the group will be moved to their correspondent groups.
     * @param groupParams updated group params
     * @returns promise true if everything is ok
     */
    updateRoomGroups: (groupParams: MergeGroupParams) => Promise<boolean>;
}

export const RoomsTypeManagerServiceS = Symbol.for('RoomsTypeManagerServiceS');
@injectable(RoomsTypeManagerServiceS as unknown as string)
export default class RoomsTypeManagerService implements RoomsTypeManagerPublicInterface {
    @Inject(RoomsTypeManagerApiServiceS) private hotelsRoomTypeApiService!: RoomsTypeManagerApiService;
    @Inject(HotelsServiceS) private hotelsService!: HotelsService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(RoomsTypeManagerApiServiceS) private roomsTypeManagerApiService!: RoomsTypeManagerApiService;
    @Inject(OpenTelemetryServiceS) private otelService!: OpenTelemetryService;

    readonly storeState: HotelsRoomTypeStore = this.storeFacade.getState('HotelsRoomTypeStore');

    constructor() {
        this.storeFacade.watch(
            () => this.compsetsService.storeState.compsets,
            (n, o) => {
                if (JSON.stringify(n) === JSON.stringify(o)) {
                    return;
                }
                this.reset();
            },
        );
    }

    private get hotelNames() {
        return this.storeState.hotelNames;
    }

    get providers() {
        if (!this.data) return [];

        return Object
            .keys(this.data.providers)
            .filter(provider => !['all', 'cheapest'].includes(provider));
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.hotelsRoomType;
    }

    get hotels() {
        if (!this.data) return [];

        return this.data.hotels
            .map(id => ({
                id,
                name: this.hotelNames[id],
            }));
    }

    get isDocumentChanged() {
        return this.storeState.isDocumentChanged;
    }

    set isDocumentChanged(value: boolean) {
        this.storeState.isDocumentChanged = value;
    }

    get isArchiveListUpdating() {
        return this.storeState.isArchiveListUpdating;
    }

    getRoomGroups(hotelId: number, provider: string, roomTypeId?: number) {
        if (!this.data) return [];

        if (roomTypeId === undefined) {
            return Object.values(this.data.providers[provider][hotelId]).reduce((acc, g) => acc.concat(g), [] as RoomsGroup[]);
        }

        return this.data.providers[provider][hotelId][roomTypeId] || [];
    }

    getArchivedRoomGroups(hotelId: number, provider: string) {
        if (!this.storeState.archivedRoomNames || !this.storeState.archivedRoomNames.providers[provider]) return [];
        return this.storeState.archivedRoomNames.providers[provider][hotelId] || [];
    }

    async loadData() {
        const hotelsRoomType = await this.hotelsRoomTypeApiService.getHotelsRoomType();
        this.storeState.hotelsRoomType = hotelsRoomType;
        this.storeState.hotelNames = await this.hotelsService
            .getHotelNames(this.data!.hotels);

        return true;
    }

    saveSource(source: string | null) {
        this.storeState.source = source;
    }

    isRoomTypeHaveRooms(roomTypeId: number) {
        if (!this.data) return false;

        return Object.entries(this.data.providers)
            .filter(([provider]) => !['all', 'cheapest'].includes(provider))
            .some(([, hotels]) => Object.values(hotels)
                .some(roomTypes => !!(roomTypes[roomTypeId] || []).length));
    }

    async getArchivedRoomList(hotelId: number) {
        const data = await this.hotelsRoomTypeApiService
            .getArchivedRoomList(hotelId);

        if (data) {
            this.storeState.archivedRoomNames = data;
        }

        return this.storeState.archivedRoomNames;
    }

    async addRoomGroupsToArchive(groupsToArchive: ArchivedRoomsModel) {
        const { chainId } = this.userService;
        this.storeState.isArchiveListUpdating = true;
        const archivedGroups = this.storeState.archivedRoomNames;
        const updatedArchivedGroups = this.mergeArchivedModels(archivedGroups || {} as ArchivedRoomsModel, groupsToArchive);

        try {
            await this.hotelsRoomTypeApiService.updateArchivedRoomList(chainId!, updatedArchivedGroups);
            await this.loadData();
        } finally {
            this.storeState.isArchiveListUpdating = false;
        }
    }

    async restoreRoomGroupsFromArchive(groups: ArchivedRoomsModel) {
        const { chainId } = this.userService;
        this.storeState.isArchiveListUpdating = true;
        const archived = this.storeState.archivedRoomNames;

        if (!archived) {
            return;
        }

        const newArchivedState = this.splitArchivedModels(archived, groups);

        try {
            await this.hotelsRoomTypeApiService.updateArchivedRoomList(chainId!, newArchivedState);
            await this.loadData();
        } finally {
            this.storeState.isArchiveListUpdating = false;
        }
    }

    /** Merge 2nd object into 1st. Also merge nested arrays. Returns new deeply cloned object */
    private mergeArchivedModels(m1: ArchivedRoomsModel, m2: ArchivedRoomsModel) {
        const mergedModel = cloneDeep(m1);
        Object.keys(m2.providers).forEach(provider => {
            if (!mergedModel.providers[provider]) {
                mergedModel.providers[provider] = {};
            }
            Object.keys(m2.providers[provider]).forEach(hotelId => {
                if (m2.providers[provider][+hotelId].length) {
                    if (!mergedModel.providers[provider][+hotelId]) {
                        mergedModel.providers[provider][+hotelId] = cloneDeep(m2.providers[provider][+hotelId]);
                    } else {
                        mergedModel.providers[provider][+hotelId] = [
                            ...mergedModel.providers[provider][+hotelId],
                            ...cloneDeep(m2.providers[provider][+hotelId]),
                        ];
                    }
                }
            });
        });
        return mergedModel;
    }

    /** Loop through 2nd object keys and remove them from 1st one. Nested arrays are filtered by value. Returns new depply cloned object */
    private splitArchivedModels(m1: ArchivedRoomsModel, m2: ArchivedRoomsModel) {
        const splitedModel: ArchivedRoomsModel = cloneDeep(m1);
        // It has same amount of iterations as before, as will go through all archived rooms.
        // Rooms were just array before and now it is nested object which needs to be filtered.
        Object.entries(m2.providers).forEach(([providerName, hotels]: [string, { [hotelId: string]: RoomsGroup[] }]) => {
            Object.entries(hotels).forEach(([hotelId, groups]) => {
                if (splitedModel.providers[providerName][+hotelId]) {
                    splitedModel.providers[providerName][+hotelId] = splitedModel.providers[providerName][+hotelId]
                        .filter(updatedGroup => !groups.find(group => group.groupId === updatedGroup.groupId));
                }
            });
        });
        return splitedModel;
    }

    reset() {
        this.storeState.loading.reset();
        this.storeState.hotelsRoomType = null;
        this.isDocumentChanged = false;
        this.storeState.changeData = {};
    }

    moveRoom(hotelId: number, roomTypeId: number, provider: string, groupId: string, targetRoomTypeId: number) {
        if (!this.data) return;

        this.isDocumentChanged = true;

        const hotelData = this.data.providers[provider][hotelId];
        const sourceGroups = hotelData[roomTypeId];

        hotelData[targetRoomTypeId] = hotelData[targetRoomTypeId] || [];

        const targetRooms = hotelData[targetRoomTypeId];
        const sourceIndex = sourceGroups.findIndex(g => g.groupId === groupId);

        if (sourceIndex < 0) return;

        const [groupToMove] = sourceGroups.splice(sourceIndex, 1);
        groupToMove.isNew = false;
        targetRooms.push(groupToMove);

        this.addChangeData(
            hotelId,
            groupId,
            provider,
            targetRoomTypeId,
        );
    }

    private addChangeData(hotelId: number, roomName: string, provider: string, roomTypeId: number) {
        const { changeData } = this.storeState;

        changeData[hotelId] = changeData[hotelId] || {};
        changeData[hotelId][provider] = changeData[hotelId][provider] || {};
        changeData[hotelId][provider][roomName] = roomTypeId;
    }

    async moveAllRoomsToRoomType(mainHotelId: number, roomTypeId: number, targetRoomTypeId: number) {
        this.hotels.forEach(({ id: hotelId }) => {
            this.providers.forEach(provider => {
                const groups = [...this.getRoomGroups(hotelId, provider, roomTypeId)];

                groups.forEach(roomsGroup => {
                    this.moveRoom(hotelId, roomTypeId, provider, roomsGroup.groupId, targetRoomTypeId);
                });
            });
        });

        await this.saveDocument(mainHotelId);
    }

    async saveDocument(mainHotelId: number) {
        if (!this.data) return;

        await this.hotelsRoomTypeApiService
            .updateHotelsRoomType(mainHotelId, this.storeState.changeData);

        this.storeState.changeData = {};
    }

    async updateRoomGroups(params: MergeGroupParams) {
        const res = await this.hotelsRoomTypeApiService.updateRoomGroup(params);
        this.storeState.hotelsRoomType = res;
        return true;
    }

    async unflagNewGroup(params: UnflagGroupParams) {
        const res = await this.hotelsRoomTypeApiService.unflagNewGroup(params);
        this.storeState.hotelsRoomType = res;
        return true;
    }

    isAllGroupsProviderHasRooms(provider: string, hotelId: number) {
        return this.isProviderHasRooms(this.data, provider, hotelId);
    }

    isArchivedGroupsProviderHasRooms(provider: string, hotelId: number) {
        return this.isProviderHasRooms(this.storeState.archivedRoomNames, provider, hotelId);
    }

    private isProviderHasRooms(data: HotelsRoomTypeModel | ArchivedRoomsModel | null, provider: string, hotelId: number) {
        if (!data) return false;
        const providerData = data.providers[provider];
        return !!Object.keys(providerData && providerData[hotelId] || {}).length;
    }

    getRoomsRMSState(mainHotelId: number) {
        return this.hotelsRoomTypeApiService.getRoomsRMSState(mainHotelId);
    }

    eliminateRoomsRMS(mainHotelId: number, newStates: RMSStatePayload[]) {
        return this.hotelsRoomTypeApiService
            .eliminateRoomsRMS(mainHotelId, newStates);
    }

    restoreRoomsRMS(mainHotelId: number, newStates: RMSStatePayload[]) {
        return this.hotelsRoomTypeApiService
            .restoreRoomsRMS(mainHotelId, newStates);
    }

    async downloadExcel(hotelId: number, provider: string) {
        this.otelService.startSpan({ name: 'room-mapping', prefix: LOGTYPE.DOWNLOAD });
        const res = await this.roomsTypeManagerApiService.getMappingExcel(hotelId, provider);
        this.otelService.endSpan({ name: 'room-mapping', prefix: LOGTYPE.DOWNLOAD }, { sendLogs: true });

        if (!res || !res.data) {
            return false;
        }

        const blobData = res.data as Blob;
        downloadBlobAsFile(`${provider}-${hotelId}-room-mapping.xlsx`, blobData);
        return true;
    }
}
