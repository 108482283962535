import use from '@/router/use';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import { container } from 'inversify-props';
import PaginationMiddleware, { PaginationMiddlewareS } from '@/router/middlewares/pagination.middleware';
import { CommonMenuRoutes } from '../common.routes';

export default function InsightsClusterRoutes(basePath: string, prefix: string) {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/transition.page.vue'),
        beforeEnter: use([container.get<FeaturesGuard>(FeaturesGuardS), container.get<PaginationMiddleware>(PaginationMiddlewareS)]),
        children: [
            {
                name: prefix,
                path: '',
                component: () => import('@/modules/cluster/pages/insights-cluster.page.vue'),
                children: [
                    ...CommonMenuRoutes(prefix),
                    {
                        name: `${prefix}.hotels`,
                        path: ':hotelsGroupId',
                        component: () => import('@/modules/cluster/pages/insights-cluster.modal.page.vue'),
                        meta: { modal: true },
                        children: [
                            ...CommonMenuRoutes(`${prefix}.hotels`),
                        ],
                    },
                ],
            },
        ],
    };
}
