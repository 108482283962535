import { Inject } from 'inversify-props';

import { Expose, Transform } from 'class-transformer';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { i18n } from '@/modules/translations/translations.service';

export default class InsightTypeModel {
    @Inject(UserServiceS) private userService!: UserService;

    @Expose()
    @Transform((_, plain) => i18n.tc(`insights.type.${plain.type}`))
    name!: string;

    @Expose()
    @Transform((_, plain) => plain.type)
    value!: string;

    get isActive() {
        const { user } = this.userService;

        if (!user) {
            return true;
        }

        const { settings: { defaultFilters } } = user;

        return defaultFilters.targetedInsights[this.value];
    }
}
