import MarketsFiltersMiddleware, { MarketsFiltersMiddlewareS } from '@/router/middlewares/markets-filters.middleware';
import use from '@/router/use';
import { container } from 'inversify-props';
import { Route } from 'vue-router';
import { CommonMenuRoutes } from '../common.routes';

export function ScheduledReports(prefix: string) {
    return {
        name: `${prefix}.scheduled-reports`,
        path: 'scheduled-reports',
        component: () => import('@/modules/markets/pages/markets-report.modal.page.vue'),
        meta: { modal: true },
    };
}

export function DayMarkets(prefix: string, path = 'day-markets/:day/:source') {
    return {
        name: `${prefix}.day-markets-source`,
        path,
        props: (route: Route) => ({
            day: route.params.day,
            source: route.params.source,
            compsetId: route.params.compsetId,
            hotelId: route.params.hotelId,
        }),
        component: () => import('@/modules/common/modules/markets-history/pages/markets-history.modal.vue'),
        meta: { modal: true },
    };
}

export const DownloadExcelModal = (prefix: string) => ({
    name: `${prefix}.download-excel`,
    path: 'download-excel',
    component: () => import('@/modules/markets/pages/download-excel.page.vue'),
    meta: { modal: true },
});

export default function MarketsHotelLevelRoutes(basePath: string, prefix: string) {
    const userType = basePath.split('/').shift();
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/hotel-transition.page.vue'),
        props: (r: Route) => ({
            hotelId: Number(r.params.hotelId),
            clusterId: Number(r.params.clusterId),
            userType,
        }),
        beforeEnter: use([
            container.get<MarketsFiltersMiddleware>(MarketsFiltersMiddlewareS),
        ]),
        children: [
            {
                path: '',
                name: prefix, // NOTE: Default calendar view
                component: () => import('@/modules/markets/pages/markets-visibility.page.vue'),
                children: [
                    DownloadExcelModal(prefix),
                    ScheduledReports(`${prefix}`),
                    DayMarkets(`${prefix}`),
                    ...CommonMenuRoutes(`${prefix}`),
                ],
            },
            {
                name: `${prefix}.graphs`,
                path: 'graphs',
                component: () => import('@/modules/markets/pages/markets-graphs.page.vue'),
                children: [
                    ScheduledReports(`${prefix}.graphs`),
                    DownloadExcelModal(`${prefix}.graphs`),
                    DayMarkets(`${prefix}.graphs`),
                    ...CommonMenuRoutes(`${prefix}.graphs`),
                ],
            },
            {
                name: `${prefix}.table`,
                path: 'table',
                component: () => import('@/modules/markets/pages/markets-table.page.vue'),
                children: [
                    ScheduledReports(`${prefix}.table`),
                    DownloadExcelModal(`${prefix}.table`),
                    DayMarkets(`${prefix}.table`),
                    ...CommonMenuRoutes(`${prefix}.table`),
                ],
            },
        ],
    };
}
