import { Inject, injectable } from 'inversify-props';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import RankingCommonService, { RankingCommonServiceS } from '@/modules/common/modules/ranking/ranking-common.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import ClusterStore from './store/cluster.store';
import ClusterApiService, { ClusterApiServiceS } from './cluster-api.service';
import CustomNotificationService, { CustomNotificationServiceS } from '../common/modules/custom-notification/custom-notification.service';
import ClusterRankingModel from './models/cluster-ranking.model';
import PAGES from '../common/constants/pages.constant';
import ProvidersStore from '../providers/store/providers.store';
import ProvidersApiService, { ProvidersApiServiceS } from '../providers/providers-api.service';
import UserService, { UserServiceS } from '../user/user.service';

export const ClusterRankingServiceS = Symbol.for('ClusterRankingServiceS');
@injectable(ClusterRankingServiceS as unknown as string)
export default class ClusterRankingService implements Stateable {
    @Inject(StoreFacadeS) storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(RankingCommonServiceS) private rankingCommonService!: RankingCommonService;
    @Inject(ClusterApiServiceS) private clusterApiService!: ClusterApiService;
    @Inject(CustomNotificationServiceS) private customNotificationService!: CustomNotificationService;
    @Inject(ProvidersApiServiceS) private providersApiService!: ProvidersApiService;
    @Inject(UserServiceS) private userService!: UserService;

    readonly storeState: ClusterStore = this.storeFacade.getState('ClusterStore');
    readonly providerStoreState: ProvidersStore = this.storeFacade.getState('ProvidersStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.documentFiltersService.storeState.settings.month,
            this.documentFiltersService.storeState.settings.year,
        ], this.clusterService.resetLoading.bind(this));
    }

    get clusterRankingsData() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.RANKING),
        );
        return this.storeState.clusterHotels as ClusterRankingModel[];
    }

    get hotelsCount() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.RANKING),
        );
        return this.storeState.totalCount as number;
    }

    private competititveHotels(document: any, provider: string, currentHotelId: number | null) {
        if (!document || !currentHotelId) {
            return null;
        }

        const hotels = this.rankingCommonService.hotels(document, provider);

        if (!hotels) {
            return null;
        }

        const { [currentHotelId]: _, ...competitors } = hotels;

        return competitors;
    }

    minMaxCompetitiveRating(document: any, provider: string, compsetId: string): [number, number] | null {
        const currentHotelId = this.getCurrentHotelId(compsetId);
        const competitors = this.competititveHotels(document, provider, currentHotelId);

        if (!competitors || !Object.keys(competitors).length) {
            return null;
        }

        return this.rankingCommonService.minMaxRating(competitors);
    }

    minMaxCompetitiveReview(document: any, provider: string, compsetId: string): [number, number] | null {
        const currentHotelId = this.getCurrentHotelId(compsetId);
        const competitors = this.competititveHotels(document, provider, currentHotelId);

        if (!competitors || !Object.keys(competitors).length) {
            return null;
        }

        return this.rankingCommonService.minMaxReview(competitors);
    }

    private getCurrentHotelId(compsetId: string) {
        const hotel = this.getHotelBy({ compsetId });
        return hotel && hotel.hotelId;
    }

    get providers() {
        this.helperService
            .dynamicLoading(
                this.providerStoreState.loadingFilter,
                this.loadChainProviders.bind(this),
            );

        return this.providerStoreState.marketProviders;
    }

    private async loadChainProviders() {
        const { levelName, chainId } = this.userService;
        if (!chainId) return true;

        const dictionary = await this.providersApiService.getChainProviders(chainId, levelName);
        if (!dictionary) return true;

        this.providerStoreState.marketProviders = dictionary.markets;
        this.providerStoreState.providers = dictionary.rates;

        return true;
    }

    getHotelBy({ compsetId, hotelId }: { compsetId?: string, hotelId?: number}) {
        if ((!compsetId && !hotelId) || !this.clusterRankingsData) {
            return null;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const rankingData of this.clusterRankingsData) {
            if (compsetId) {
                const needCompset = rankingData.compsets.find(item => item.id === compsetId);

                if (needCompset) {
                    return rankingData;
                }
            } else if (rankingData.hotelId === hotelId) {
                return rankingData;
            }
        }

        return null;
    }

    hotels(document: any, provider: string) {
        if (!document) {
            return null;
        }

        return this.rankingCommonService.hotels(document, provider);
    }

    async downloadExcel() {
        const excelData = await this.clusterApiService.getGuestReviewsExcelDocument();

        if (excelData) {
            await this.customNotificationService
                .handleExcel(excelData);
        }
    }
}
