import BadRequestException from '@/modules/common/modules/exception-handler/exceptions/bad-request.exception';
import { injectable, Inject } from 'inversify-props';
import axios, {
    AxiosError,
    AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import UnauthorizedException from '@/modules/common/modules/exception-handler/exceptions/unauthorized.exception';
import ForbiddenException from '@/modules/common/modules/exception-handler/exceptions/forbidden.exception';
import GatewayTimeoutException from '@/modules/common/modules/exception-handler/exceptions/gateway-timeout.exception';
import PreconditionRequestException from '@/modules/common/modules/exception-handler/exceptions/precondition-request.exception';

export interface Params {
    [param: string]: any;
}

export const ApiServiceS = Symbol.for('ApiServiceS');
@injectable(ApiServiceS as unknown as string)
export default class ApiService {
    readonly client!: AxiosInstance;
    readonly retryLimit: number = 3;

    constructor() {
        this.client = axios.create();
    }

    setRequestInterceptor(cb: (config: AxiosRequestConfig) => any) {
        this.client.interceptors.request.use(cb);
    }

    setResponseInterceptor(cb: (response: AxiosResponse) => any) {
        this.client.interceptors.response.use(cb);
    }

    async get(path: string, params: Params = {}, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.errorIdentifier(() => this.client.get(path, { ...config, params }));
    }

    async post(path: string, data?: {}, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.errorIdentifier(() => this.client.post(path, data, config));
    }

    async put(path: string, data?: {}, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.errorIdentifier(() => this.client.put(path, data, config));
    }

    async delete(path: string, params: Params = {}, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return this.errorIdentifier(() => this.client.delete(path, { ...config, params }));
    }

    generateQueryByArray(queryName: string, arr: any[]) {
        return arr.map((item, index) => `${queryName}[${index}]=${item}`).join('&');
    }

    private async errorIdentifier(cb: () => Promise<AxiosResponse>, retry = 0): Promise<AxiosResponse> {
        try {
            return await cb();
        } catch (error) {
            const axiosError = error as AxiosError;

            if (!axiosError.response) {
                throw axiosError;
            }

            const { status, data }: { status: number, data: any } = axiosError.response;

            switch (status) {
                case 400: {
                    throw new BadRequestException(data && data.message ? data.message : axiosError.message);
                }
                case 401: {
                    throw new UnauthorizedException(axiosError.message);
                }
                case 403: {
                    throw new ForbiddenException(axiosError.message);
                }
                case 412: {
                    throw new PreconditionRequestException(data && data.message ? data.message : axiosError.message);
                }
                case 502: {
                    if (retry < this.retryLimit) {
                        return this.errorIdentifier(cb, retry + 1);
                    }
                    throw axiosError;
                }
                case 504: {
                    throw new GatewayTimeoutException(axiosError.message);
                }
                default: throw axiosError;
            }
        }
    }
}
