import { validate, ValidationError } from 'class-validator';
import { Inject, injectable } from 'inversify-props';
import * as _ from 'lodash';
import { $enum } from 'ts-enum-util';

import BadRequestException from '@/modules/common/modules/exception-handler/exceptions/bad-request.exception';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import EVENT_TYPE_SETTINGS from '@/modules/events/constants/event-types-settings.constant';
import EventsApiService, { EventsApiServiceS } from '@/modules/events/events-api.service';
import EventsFilterService, { EventsFilterServiceS } from '@/modules/events/events-filter.service';
import IGetEventParams from '@/modules/events/interfaces/get-event.interface';
import EventsModel from '@/modules/events/models/events.model';
import EventsStore from '@/modules/events/store/events.store';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import { checkDateIsFuture } from '@/modules/cars/utils/check-month-is-same';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import CarsFiltersService, { CarsFiltersServiceS } from '../cars/cars-filters.service';
import ClusterService, { ClusterServiceS } from '../cluster/cluster.service';
import CacheService, { CacheServiceS, EVENTS_METHODS, MODULES } from '../common/services/cache/cache.service';
import EventCollection from './models/event-collection.model';
import EventGroup from './interfaces/event-group.enum';
import { EventSet } from './models/event-set.model';

interface EventsManagerPublicInterface {
    /**
     * Returns NOT filtered events for provided date.
     * @param date
     */
    getEventCollection(date: Date): EventCollection | null

    /**
     * Returns filtered ALL events for provided date.
     * Holidays filtered by Holiday filter.
     * My, suggested and chain events filtered by Events filter but not by Status.
     * @param date
     */
    getEventsByDate(date: Date): EventsModel[] | null

    /**
     * A list of event ids that are ignored by the user
     */
    ignoredEvents: string[];

    /**
     * Adds the event into ignore list
     */
    ignoreEvent(eventId: string): Promise<ValidationError[]>;

    /**
     * Removes the event from ignore list
     */
    restoreIgnoredEvent(id: string): Promise<ValidationError[]>;
}

export const EventsManagerServiceS = Symbol.for('EventsManagerServiceS');
@injectable(EventsManagerServiceS as unknown as string)
export default class EventsManagerService implements EventsManagerPublicInterface {
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(EventsApiServiceS) private eventsApiService!: EventsApiService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ClusterServiceS) private clusterService!: ClusterService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(EventsFilterServiceS) private eventsFilterService!: EventsFilterService;
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CacheServiceS) private cacheService!: CacheService;

    readonly storeState: EventsStore = this.storeFacade.getState('EventsStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.documentFiltersService.storeState.settings.month,
            this.documentFiltersService.storeState.settings.year,
        ], ((newValue, oldValue) => {
            const [newMonth, newYear] = newValue;
            const [oldMonth, oldYear] = oldValue;

            if (newMonth === oldMonth
                && newYear === oldYear) {
                return;
            }

            this.storeState.loading.reset.call(this.storeState.loading);
        }));

        if (this.userService.isClusterUser || this.userService.isChainUser) {
            this.storeFacade.watch(() => [
                this.userService.viewAs,
                this.userService.currentHotelId,
            ], ((newValue, oldValue) => {
                const [oldViewAs, oldCurrentHotelId] = oldValue;
                const [newViewAs, newCurrentHotelId] = newValue;

                if (oldCurrentHotelId === newCurrentHotelId
                    && oldViewAs === newViewAs) {
                    return;
                }

                this.storeState.loading.reset();
                this.storeState.loadingIgnores.reset();
            }));
        }

        if (this.userService.isCarUser) {
            this.storeFacade.watch(() => this.carsFiltersService.settings, () => (
                this.storeState.loading.reset.call(this.storeState.loading)
            ));

            this.storeFacade.watch(
                () => [
                    this.carsService.storeState.settings.pos,
                    this.carsService.storeState.settings.isLoadEventByPOS,
                ],
                () => {
                    this.storeState.loadingByPOS.reset();
                    this.storeState.loadingIgnores.reset();
                },
            );
        }

        this.getEventsByMonth = this.cacheService
            .memorize(MODULES.EVENTS, EVENTS_METHODS.getEventsByMonth, this.getEventsByMonth.bind(this), 1);
    }

    get eventSet() {
        const { isLoadEventByPOS } = this.carsService.storeState.settings;

        if (isLoadEventByPOS) {
            this.helperService.dynamicLoading(this.storeState.loading, this.loadPOSData.bind(this));
        } else {
            this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        }

        this.storeState.events[this.currentHash] = this.storeState.events[this.currentHash] || new EventSet();

        return this.storeState.events[this.currentHash];
    }

    get settings() {
        return this.storeState.settings;
    }

    get isLoading() {
        if (this.userService.isCarUser) {
            this.carsFiltersService.initPos();
        }

        return this.storeState.loading.isLoading() || (this.ignoredEvents && this.storeState.loadingIgnores.isLoading());
    }

    get ignoredEvents() {
        this.helperService
            .dynamicLoading(this.storeState.loadingIgnores, async () => {
                this.storeState.ignoredEvents = (await this.eventsApiService.getIgnoredEvents()) || [];
                return true;
            });
        return this.storeState.ignoredEvents;
    }

    private get currentHash() {
        if (this.userService.isCarUser) {
            return 'cars';
        }

        const type = this.userService.viewAs;

        if (this.userService.isViewAsHotel) {
            return +this.userService.currentHotelId!;
        }

        return `${type} - ${this.userService.chainId}`;
    }

    /**
     * For CI and Cars (in events manager page)
     */
    private async loadData(): Promise<boolean> {
        const { settings } = this.documentFiltersService.storeState;
        const { previousMonthAndYear, nextMonthAndYear } = this.documentFiltersService;
        const { currentCompset } = this.compsetsService;
        const { isViewAsHotel, isChainOrClusterUser, isHotelUser } = this.userService;
        const { isCarUser } = this.userService;

        const isHotelLevel = isViewAsHotel || (!isChainOrClusterUser && isHotelUser);
        const isCompsetDefined = !!currentCompset;

        if (!isCarUser && isHotelLevel && !isCompsetDefined) {
            await this.compsetsService.storeState.loading.whenLoadingFinished();
        }

        this.resetEventMap();

        const [currentMonthEvents, previousMonthEvents, nextMonthEvents] = await Promise.all([
            this.loadMonthEvents((settings.month) as Month, settings.year),
            this.loadMonthEvents((previousMonthAndYear.month) as Month, previousMonthAndYear.year),
            this.loadMonthEvents((nextMonthAndYear.month) as Month, nextMonthAndYear.year),
        ]);

        this.storeState.currentMonthEvents = currentMonthEvents;
        this.storeState.previousMonthEvents = previousMonthEvents;
        this.storeState.nextMonthEvents = nextMonthEvents;

        await this.loadDefaultCountries();

        return true;
    }

    private async loadDefaultCountries() {
        const { isChainOrClusterUser, isCarUser, viewAs } = this.userService;
        const isClusterUser = isChainOrClusterUser && viewAs !== 'hotel';

        let defaultCountryCodes = [] as string[];

        if (isCarUser) {
            defaultCountryCodes = this.carsFiltersService.settings.pos || [];
        } else {
            defaultCountryCodes = isClusterUser
                ? await this.clusterService.getPosList()
                : this.compsetsService.poses;
        }

        this.eventsFilterService.setSettings({ countries: defaultCountryCodes || undefined });
    }

    /**
     * This is only for CARS
     */
    private async loadPOSData(): Promise<boolean> {
        const { settings } = this.documentFiltersService.storeState;
        const { previousMonthAndYear, nextMonthAndYear } = this.documentFiltersService;
        const { settings: carsSettings } = this.carsService.storeState;

        const { isLoadEventByPOS } = carsSettings;
        let { pos: posList } = this.carsFiltersService.settings;

        posList = posList || [];

        carsSettings.pos = carsSettings.pos || posList[0] || null;

        const { pos } = carsSettings;

        if (!pos || !isLoadEventByPOS) {
            return false;
        }

        this.resetEventMap();

        const [currentMonthEvents, previousMonthEvents, nextMonthEvents] = await Promise.all([
            this.loadMonthEvents((settings.month) as Month, settings.year, [pos]),
            this.loadMonthEvents((previousMonthAndYear.month) as Month, previousMonthAndYear.year, [pos]),
            this.loadMonthEvents((nextMonthAndYear.month) as Month, nextMonthAndYear.year, [pos]),
        ]);

        if (this.carsService.storeState.settings.pos) {
            this.storeState.currentMonthEventsByPOS = currentMonthEvents;
            this.storeState.previousMonthEventsByPOS = previousMonthEvents;
            this.storeState.nextMonthEventsByPOS = nextMonthEvents;
        }

        await this.loadDefaultCountries();

        return true;
    }

    /**
     * Collects loaded events into `EventCollection` and places in the store for each day
     */
    private registerEventsInCollections(events: EventsModel[], reactive = false) {
        const { eventSet } = this;

        if (!eventSet) return;

        eventSet.append(events);

        if (reactive) {
            this.storeState.events[this.currentHash] = eventSet.duplicate();
        }
    }

    private resetEventMap() {
        this.storeState.events[this.currentHash] = new EventSet();
    }

    private async loadMonthEvents(month: Month, year: Year, pos?: string[]): Promise<EventCollection> {
        const { isViewAsCluster, currentHotelId } = this.userService;
        const monthEvents = new EventCollection();

        if (!currentHotelId && !isViewAsCluster) {
            return monthEvents;
        }

        const eventPromises = [
            this.eventsApiService.getHolidaysEvents((month + 1) as Month, year, pos),
        ];

        let holidayEvents = null as EventsModel[] | null;
        let myEvents = null as EventsModel[] | null;
        let chainEvents = null as EventsModel[] | null;
        let marketEvents = null as EventsModel[] | null;

        if (isViewAsCluster) {
            const { chainId, viewAs } = this.userService;

            eventPromises.push(this.eventsApiService.getChainEvents(month as Month, year, chainId!, viewAs!));
            [holidayEvents, chainEvents] = await Promise.all(eventPromises);
        } else {
            const { currentCompset } = this.compsetsService;
            const isCurrentsHotelCompset = currentCompset && currentCompset.ownerHotelId === currentHotelId;

            const marketId = isCurrentsHotelCompset
                ? currentCompset!.marketId
                : this.compsetsService.getCompsetsByHotel(currentHotelId!)[0]?.marketId;

            eventPromises.push(
                this.eventsApiService.getMyEvents(month as Month, year, currentHotelId!, marketId),
            );
            [holidayEvents, myEvents] = await Promise.all(eventPromises);

            marketEvents = (myEvents || []).filter(event => event.entityType === 'market');
            myEvents = (myEvents || []).filter(event => event.entityType !== 'market');
        }

        monthEvents.holiday = holidayEvents || [];
        monthEvents.my = myEvents || [];
        monthEvents.chain = chainEvents || [];
        monthEvents.market = marketEvents || [];

        this.registerEventsInCollections(holidayEvents || []);
        this.registerEventsInCollections(myEvents || []);
        this.registerEventsInCollections(chainEvents || [], true);
        this.registerEventsInCollections(marketEvents || []);

        return monthEvents;
    }

    private events(month: Month, year: Year): EventCollection {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));

        const { month: selectedMonth, year: selectedYear } = this.documentFiltersService.storeState.settings;
        const dateType = checkDateIsFuture(month, year, selectedMonth, selectedYear);

        switch (dateType) {
            case -1:
                return this.storeState.previousMonthEvents;
            case 0:
                return this.storeState.currentMonthEvents;
            default:
                return this.storeState.nextMonthEvents;
        }
    }

    private eventsByPOS(month: Month, year: Year): EventCollection {
        this.helperService.dynamicLoading(this.storeState.loadingByPOS, this.loadPOSData.bind(this));

        const { month: selectedMonth, year: selectedYear } = this.documentFiltersService.storeState.settings;

        const dateType = checkDateIsFuture(month, year, selectedMonth, selectedYear);
        switch (dateType) {
            case -1:
                return this.storeState.previousMonthEventsByPOS;
            case 0:
                return this.storeState.currentMonthEventsByPOS;
            default:
                return this.storeState.nextMonthEventsByPOS;
        }
    }

    async loadHotelEvent(m: Month, y: Year, hotelId: number, marketId?: number) {
        let eventSet = this.storeState.events[hotelId];
        const hash = `${hotelId}-${m}-${y}-${marketId}`;
        const meta = this.loadHotelEvent as any;

        meta.activePromises = meta.activePromises || {};

        if (eventSet && eventSet.hasLoaded(y, m)) {
            return this.storeState.events[hotelId];
        }

        const promise = (meta.activePromises[hash] || this.eventsApiService.getMyEvents(m, y, hotelId, marketId));

        meta.activePromises[hash] = promise;

        const events = (await promise) || [];

        delete meta.activePromises[hash];

        if (!eventSet) {
            eventSet = new EventSet().append(events);
        } else {
            eventSet.append(events);
        }

        eventSet.setLoaded(y, m);

        this.storeState.events = {
            ...this.storeState.events,
            [hotelId]: eventSet,
        };

        return eventSet;
    }

    // FIXME Not needed anymore. Currently used only in Cars
    private getMyEventsByMonth(m: Month, y: Year, isPOSFilter: boolean = false) {
        const s = this.storeState;

        const { types } = s.settings;

        let myEvents = isPOSFilter
            ? this.eventsByPOS(m, y).my.filter((event: EventsModel) => types.some(type => type === event.type))
            : this.events(m, y).my.filter((event: EventsModel) => types.some(type => type === event.type));
            // Needs to show old events with unsupported types like other;
        if (types.some(type => type === EVENT_TYPE_SETTINGS.OTHER)) {
            const myOldEvents = this.events(m, y).my
                .filter((event: EventsModel) => !$enum(EVENT_TYPE_SETTINGS).getValues().some(t => t === event.type));
            myEvents = [...myEvents, ...myOldEvents];
        }
        return myEvents;
    }

    // FIXME Not needed anymore. Currently used only in Cars
    private getChainEventsByMonth(m: Month, y: Year, isPOSFilter: boolean = false) {
        const s = this.storeState;
        const { types } = s.settings;

        let chainEvents = isPOSFilter
            ? this.eventsByPOS(m, y).chain.filter((event: EventsModel) => types.some(type => type === event.type))
            : this.events(m, y).chain.filter((event: EventsModel) => types.some(type => type === event.type));
            // Needs to show old events with unsupported types like other;
        if (types.some(type => type === EVENT_TYPE_SETTINGS.OTHER)) {
            const chainOldEvents = this.events(m, y).chain
                .filter((event: EventsModel) => !$enum(EVENT_TYPE_SETTINGS).getValues().some(t => t === event.type));
            chainEvents = [...chainEvents, ...chainOldEvents];
        }

        return chainEvents;
    }

    // FIXME Not needed anymore. Currently used only in Cars
    private getHolidayEventsByMonth(m: Month, y: Year, isPOSFilter: boolean = false) {
        const s = this.storeState;
        const { countries } = s.settings;

        if (isPOSFilter) {
            return this.eventsByPOS(m, y).holiday
                .filter((event: EventsModel) => countries.some(country => country === event.country || !event.country));
        }

        return this.events(m, y).holiday
            .filter((event: EventsModel) => countries.some(country => country === event.country || !event.country));
    }

    // FIXME Not needed anymore. Currently used only in Cars
    private getMarketEventsByMonth(m: Month, y: Year, isPOSFilter: boolean = false) {
        const s = this.storeState;
        const { types } = s.settings;

        return isPOSFilter
            ? this.events(m, y).market.filter((event: EventsModel) => types.some(type => type === event.type))
            : this.eventsByPOS(m, y).market.filter((event: EventsModel) => types.some(type => type === event.type));
    }

    // FIXME Not needed anymore. Currently used only in Cars
    getCountryEventsByDay(params: IGetEventParams) {
        const {
            day, month, year, isPOSBased,
        } = params;
        const { month: currentMonth, year: currentYear } = this.documentFiltersService.storeState.settings;
        let dayEvents: EventCollection;
        if ((month || month === 0) && year) {
            dayEvents = this.getEventsByMonth(month, year, isPOSBased)[day];
            return dayEvents ? dayEvents.holiday : [];
        }
        dayEvents = this.getEventsByMonth(currentMonth, currentYear, isPOSBased)[day];
        return dayEvents ? dayEvents.holiday : [];
    }

    // FIXME Not needed anymore. Currently used only in Cars
    getMyEventsByDay(params: IGetEventParams) {
        const { day, month } = params;
        const { year, isPOSBased } = params;

        const { month: currentMonth, year: currentYear } = this.documentFiltersService.storeState.settings;

        let dayEvents: EventCollection;
        if ((month || month === 0) && year) {
            dayEvents = this.getEventsByMonth(month, year, isPOSBased)[day];
            return dayEvents ? dayEvents.my : [];
        }
        dayEvents = this.getEventsByMonth(currentMonth, currentYear, isPOSBased)[day];
        return dayEvents ? dayEvents.my : [];
    }

    // FIXME Not needed anymore. Currently used only in Cars
    getMarketEventsByDay(params: IGetEventParams) {
        const { day, month } = params;
        const { year, isPOSBased } = params;
        const { month: currentMonth, year: currentYear } = this.documentFiltersService.storeState.settings;

        let dayEvents: EventCollection;

        if ((month || month === 0) && year) {
            dayEvents = this.getEventsByMonth(month, year, isPOSBased)[day];
            return dayEvents ? dayEvents.market : [];
        }

        dayEvents = this.getEventsByMonth(currentMonth, currentYear, isPOSBased)[day];
        return dayEvents ? dayEvents.market : [];
    }

    // FIXME Not needed anymore. Currently used only in Cars
    getChainEventsByDay(params: IGetEventParams) {
        const { day, month } = params;
        const { year, isPOSBased } = params;
        const { month: currentMonth, year: currentYear } = this.documentFiltersService.storeState.settings;

        let dayEvents: EventCollection;

        if ((month || month === 0) && year) {
            dayEvents = this.getEventsByMonth(month, year, isPOSBased)[day];
            return dayEvents ? dayEvents.chain : [];
        }

        dayEvents = this.getEventsByMonth(currentMonth, currentYear, isPOSBased)[day];
        return dayEvents ? dayEvents.chain : [];
    }

    getEventById(eventId: string) {
        return this.eventSet.get(eventId);
    }

    async addEvent(newEvent: EventsModel): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = await validate(newEvent);

        if (validationErrors.length > 0) {
            return validationErrors;
        }

        try {
            const { user, currentHotelId, isCarUser } = this.userService;
            const { currentCompset } = this.compsetsService;
            const marketId = currentCompset ? +currentCompset.marketId : currentHotelId!;

            if (!user || (!marketId && !isCarUser) || (!currentHotelId && isCarUser)) {
                return validationErrors;
            }

            const creationParams = { ...newEvent, marketId };

            const event = await this.eventsApiService.createEvent(creationParams, user.id);

            if (event) {
                this.registerEventsInCollections([event], true);
            }
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }

        return validationErrors;
    }

    /**
     * Updates data of the event
     *
     * @param event is actually copy of the original event with updated fields so original event will be removed
     */
    async updateEvent(event: EventsModel): Promise<ValidationError[]> {
        const modelValue = Object.assign(new EventsModel(), event);

        let validationErrors: ValidationError[] = await validate(modelValue);

        if (validationErrors.length > 0) {
            return validationErrors;
        }

        try {
            const newEvent = await this.eventsApiService.updateEvent(event);
            if (!newEvent) {
                return validationErrors;
            }

            await this.removeEvent(event.id!, true);
            this.registerEventsInCollections([newEvent], true);
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }

        return validationErrors;
    }

    /**
     * Removes the specified event
     * @param eventId
     * @param localyOnly means that it will be removed on client-side only
     */
    async removeEvent(eventId: string, localyOnly = false): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = [];
        try {
            if (!localyOnly) {
                await this.eventsApiService.removeLocalEvent(eventId);
            }

            this.eventSet.remove(eventId);
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, error.message);
            } else {
                throw error;
            }
        }
        return validationErrors;
    }

    async ignoreEvent(eventId: string): Promise<ValidationError[]> {
        let validationErrors: ValidationError[] = [];
        try {
            await this.eventsApiService.ignoreEvents([eventId]);
            this.storeState.ignoredEvents = [...this.ignoredEvents, eventId];
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, (error as BadRequestException).message);
            } else {
                throw error;
            }
        }
        return validationErrors;
    }

    async restoreIgnoredEvent(id: string) {
        let validationErrors: ValidationError[] = [];

        try {
            await this.eventsApiService.restoreIgnoredEvent(id);
            this.storeState.ignoredEvents = this.ignoredEvents.filter(eventId => eventId !== id);
        } catch (error) {
            if (error instanceof BadRequestException) {
                validationErrors = this.updateValidationErrors(validationErrors, (error as BadRequestException).message);
            } else {
                throw error;
            }
        }

        return validationErrors;
    }

    isEventIgnored(eventId: string) {
        return this.ignoredEvents.includes(eventId);
    }

    private getEventsByMonth(month: Month, year: Year, isPOSFilter: boolean = false) {
        const { settings } = this.documentFiltersService;
        const monthEvents: { [day: number]: EventCollection } = {};

        const myEvents = this.populateMonthEventsByDays(this.getMyEventsByMonth(month, year, isPOSFilter), month, year);
        const marketEvents = this.populateMonthEventsByDays(this.getMarketEventsByMonth(month, year, isPOSFilter), month, year);
        const holidayEvents = this.populateMonthEventsByDays(this.getHolidayEventsByMonth(month, year, isPOSFilter), month, year);
        const chainEvents = this.populateMonthEventsByDays(this.getChainEventsByMonth(month, year, isPOSFilter), month, year);

        const lastDayOfMonth = new Date(settings.year, month + 1, 0).getDate();

        for (let day = 1; day <= lastDayOfMonth; day += 1) {
            if (myEvents[day]) {
                if (!monthEvents[day]) {
                    monthEvents[day] = new EventCollection();
                }
                monthEvents[day].my = myEvents[day];
            }

            if (marketEvents[day]) {
                if (!monthEvents[day]) {
                    monthEvents[day] = new EventCollection();
                }
                monthEvents[day].market = marketEvents[day];
            }

            if (holidayEvents[day]) {
                if (!monthEvents[day]) {
                    monthEvents[day] = new EventCollection();
                }
                monthEvents[day].holiday = holidayEvents[day];
            }

            if (chainEvents[day]) {
                if (!monthEvents[day]) {
                    monthEvents[day] = new EventCollection();
                }
                monthEvents[day].chain = chainEvents[day];
            }
        }

        return monthEvents;
    }

    private populateMonthEventsByDays(events: EventsModel[], month: Month, year: Year) {
        const monthEvents: {[day: number]: EventsModel[]} = {};

        if (!Array.isArray(events)) return monthEvents;

        events.forEach(event => {
            if (event.startDate && event.endDate) {
                let startDay = new Date(event.startDate).getUTCDate();
                let endDay = new Date(event.endDate).getUTCDate();
                const startDateMonth = new Date(event.startDate).getUTCMonth();
                const startDateYear = new Date(event.startDate).getFullYear();
                const endDateYear = new Date(event.endDate).getFullYear();
                const endDateMonth = new Date(event.endDate).getUTCMonth();

                if (startDateYear < year || startDateMonth < month) {
                    startDay = 1;
                }

                if (endDateYear > year || endDateMonth > month) {
                    endDay = new Date(year, startDateMonth + 1, 0).getDate();
                }

                if (startDateMonth === month || endDateMonth === month) {
                    for (let i = startDay; i <= endDay; i += 1) {
                        if (!monthEvents[i]) {
                            monthEvents[i] = [];
                        }
                        monthEvents[i].push(event);
                    }
                }
            }
        });

        return monthEvents;
    }

    updateValidationErrors(validationErrors: ValidationError[], message: string) :ValidationError[] {
        const error = new ValidationError();
        error.constraints = { message };

        return [...validationErrors, ...[error]];
    }

    getEventCollection(date: Date, hotelId?: number): EventCollection | null {
        if (!hotelId) {
            return this.eventSet.getCollection(date);
        }

        const eventSet = this.storeState.events[hotelId];

        if (!eventSet || !eventSet.hasLoaded(date.getFullYear() as Year, date.getMonth() as Month)) {
            this.loadHotelEvent(date.getMonth() as Month, date.getFullYear() as Year, hotelId);
        }

        if (!eventSet) {
            return null;
        }

        return eventSet.getCollection(date);
    }

    getHolidayEvents(date: Date) {
        const { countries } = this.storeState.settings;
        const collection = this.getEventCollection(date);
        if (!collection) return [];

        return collection.holiday
            .filter(event => countries.includes(event.country!) || !event.country);
    }

    getEventsByCollection(collection: EventCollection | null = null, ignoredOnly = false) {
        const { types, countries } = this.storeState.settings;

        if (!collection) return [];

        const { chain, my, market } = collection;
        let { holiday } = collection;

        holiday = holiday
            .filter(event => countries.includes(event.country!) || !event.country);

        return ([] as EventsModel[])
            .concat(holiday, my, market, chain)
            .filter(event => {
                if (ignoredOnly && !this.ignoredEvents.includes(event.id!)) {
                    return false;
                }

                if (!ignoredOnly && this.ignoredEvents.includes(event.id!)) {
                    return false;
                }

                return event.group === EventGroup.HOLIDAY || types.includes(event.type!);
            });
    }

    getEventsByDate(date: Date, ignoredOnly = false) {
        const collection = this.getEventCollection(date);

        return this.getEventsByCollection(collection, ignoredOnly);
    }

    hasDateHolidayEvents(date: Date): boolean {
        return !!this.getHolidayEvents(date).length;
    }

    hasDateOtherEvents(date: Date, hotelId?: number): boolean {
        const { types } = this.storeState.settings;
        const collection = this.getEventCollection(date, hotelId);

        if (!collection) return false;

        const { chain, my, market } = collection;

        return ([] as EventsModel[])
            .concat(chain, market, my)
            .some(event => types.includes(event.type!));
    }

    saveIsLoadEventByPOS(value: boolean) {
        if (this.carsService.storeState.settings.isLoadEventByPOS !== value) {
            this.carsService.storeState.settings.isLoadEventByPOS = value;
        }
    }
}
