export enum DATA_TYPE {
    RATES = 'titles.rates',
    MARKETS = 'titles.markets',
    RANKING = 'titles.ranking',
    DI_LITE = 'titles.dilite',
    RATES_COMPARE = 'titles.analysis',
    RATES_CLUSTER = 'titles.ratescluster',
    MARKETS_CLUSTER = 'titles.marketscluster',
    RANKING_CLUSTER = 'titles.rankingcluster',
    HOTEL_ALL = 'titles.hotelall',
    CLUSTER_ALL = 'titles.clusterall',
}

export enum SCHEDULER_CONFIG {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    YEARLY = 'yearly',
}

export enum DAY_CONFIG {
    FIRST = 'first',
    SECOND = 'second',
    THIRD = 'third',
    LAST = 'last',
}

export enum DAY_TYPE {
    DYNAMIC = 'dynamic',
    SPECIFIC = 'specific',
}

export enum SECTIONS {
    PROPERTIES = 'properties',
    CUSTOM_COLUMNS = 'customColumns',
    FILTERS = 'filters',
    FREQUENCY = 'frequency',
    RECIPIENTS = 'recipients',
}

export const TIME_BUFFER = 15;
