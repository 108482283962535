import { Expose, plainToClass, Transform } from 'class-transformer';
import { IsNotEmpty, Length, ValidateIf } from 'class-validator';
import EventsLocationModel from '@/modules/events/models/events-location.model';
import EventGroup from '../interfaces/event-group.enum';
import type EventCollection from './event-collection.model';
import EVENT_TYPE_SETTINGS from '../constants/event-types-settings.constant';

const validateNumberOfGuests = (_: any, value: any) => value !== null
    && value !== undefined
    && value.length === 2
    && value[0] >= 0
    && value[1] >= 0
    && value[0] <= value[1];

export default class EventsModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain._id)
    id: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.marketId)
    marketId : number | null = null;

    @Expose()
    @Transform((_, plain) => plain.name)
    @Length(2, 60, { message: 'events.validation.invalidNameLength' })
    @IsNotEmpty({ message: 'validation.emptyName' })
    name: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.type && plain.type.toLowerCase())
    @IsNotEmpty({ message: 'events.validation.emptyEventType' })
    type: EVENT_TYPE_SETTINGS | null = null;

    @Expose()
    @Transform((_, plain) => new Date(plain.starts))
    @IsNotEmpty({ message: 'events.validation.emptyStartDate' })
    startDate: Date | null = null;

    @Expose()
    @Transform((_, plain) => new Date(plain.ends))
    @IsNotEmpty({ message: 'events.validation.emptyEndDate' })
    endDate: Date | null = null;

    @Expose()
    @Transform((_, plain) => plain.country)
    country: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.country_code && plain.country_code.toLowerCase())
    countryCode: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.description)
    @Length(2, 100, { message: 'events.validation.incorrectDescription' })
    @IsNotEmpty({ message: 'events.validation.incorrectDescription' })
    description: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.no_of_guests)
    @ValidateIf(validateNumberOfGuests, {
        message: 'events.validation.incorrectAttendees',
    })
    numberOfGuests: [number, number] | [number] = [0, 0];

    @Expose()
    @Transform((_, plain) => plain.creator_id)
    creatorId: string | number | null = null;

    @Expose()
    @Transform((_, plain) => plain.fornovaId)
    fornovaId: number | null = null;

    @Expose()
    @Transform((_, plain) => plainToClass(EventsLocationModel, <EventsLocationModel> plain.location, { excludeExtraneousValues: true }))
    location: EventsLocationModel | null = null;

    @Expose()
    @Transform((_, plain) => plain.entity_type)
    entityType: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.entity_id)
    entityId: string | number | null = null;

    group: EventGroup = EventGroup.MAIN;

    /**
     * Collections that the event belongs to
     */
    collections: EventCollection[] = [];
}

export class HolidayEventModel extends EventsModel {
    group = EventGroup.HOLIDAY;
}

export class MarketEventModel extends EventsModel {
    group = EventGroup.MARKET;
}

export class ChainEventModel extends EventsModel {
    group = EventGroup.CHAIN;
}
