import { Inject, injectable } from 'inversify-props';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import CarFilterModel from '@/modules/cars/models/cars-filters.model';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';

export const FleetFilterApiServiceS = Symbol.for('FleetFilterApiServiceS');
@injectable(FleetFilterApiServiceS as unknown as string)
export default class FleetFilterApiService {
    @Inject(ApiServiceS) private apiService!: ApiService;
    @Inject(ValidatorServiceS) private validatorService!: ValidatorService;

    async getCarFilters(): Promise<CarFilterModel | null> {
        const { data } = await this.apiService.get('car/supported/car-filters');

        const carFiltersDocument = plainToClass(CarFilterModel, <CarFilterModel> data, { excludeExtraneousValues: true });

        // const error = await this.validatorService.validateResponse(carFiltersDocument);
        // if (error) {
        //     throw new ResponseValidationException(error);
        // }

        return carFiltersDocument;
    }
}
