import EventGroup from '../interfaces/event-group.enum';
import EventCollection from './event-collection.model';
import EventModel from './events.model';

type ISODate = string;
type EventID = string;

export class EventSet {
    /**
     * A dictionary where key is Date in ISO format and value is EventCollection
     */
    private eventMap: Record<ISODate, EventCollection> = {};

    /**
     * A dictionary where key is EventID and value is event itself
     */
    private eventDictionary: Record<EventID, EventModel> = {};

    /**
     * Contains already loaded dates
     */
    private loadedDates: string[] = [];

    /**
     * Creates an new instance of `EventSet` with
     * the same `eventMap` and `eventDictionary`
     *
     * Used in case to trigger reactivity
     */
    duplicate() {
        const eset = new EventSet();
        eset.eventMap = this.eventMap;
        eset.eventDictionary = this.eventDictionary;

        return eset;
    }

    /**
     * Returns event by id
     */
    get(id: string): EventModel | null {
        return this.eventDictionary[id] || null;
    }

    /**
     * Returns EventCollection by date
     */
    getCollection(date: Date) {
        const iso = date.toISOString().split('T')[0];

        return this.eventMap[iso] || null;
    }

    /**
     * Removed event from this EventSet
     */
    remove(id: string) {
        const e = this.get(id);

        if (!e) {
            return;
        }

        const group = e.group as ((typeof EventGroup)[keyof typeof EventGroup]);

        e.collections.forEach(eventCollection => {
            const evIndex = eventCollection[group].indexOf(e);
            eventCollection[group].splice(evIndex, 1);
        });

        for (let i = 0; i < e.collections.length; i++) {
            e.collections.splice(0, 1);
        }

        delete this.eventDictionary[id];
    }

    /**
     * Returns true if this EventSet has loaded data for this date.
     */
    hasLoaded(year: number, month: number) {
        return this.loadedDates.includes(`${year}-${month}`);
    }

    setLoaded(year: number, month: number) {
        if (this.hasLoaded(year, month)) {
            return;
        }

        this.loadedDates.push(`${year}-${month}`);
    }

    /**
     * Register the events in the internal structures
     */
    append(events: EventModel[]) {
        const { eventMap, eventDictionary } = this;

        events.forEach(event => {
            const { startDate, endDate } = event;
            const counter = new Date(endDate!);

            const month = startDate!.getMonth() + 1;
            const year = startDate!.getFullYear();

            if (!this.hasLoaded(year, month)) {
                this.loadedDates.push(`${year}-${month}`);
            }

            if (!eventDictionary[event.id!]) {
                eventDictionary[event.id!] = event;
            } else {
                return;
            }

            while (counter >= startDate!) {
                const date = new Date(counter);

                const iso = date.toISOString().split('T')[0];
                const group = event.group as ((typeof EventGroup)[keyof typeof EventGroup]);

                eventMap[iso] = eventMap[iso] || new EventCollection(date);

                const collection = eventMap[iso];

                if (!collection[group].includes(event)) {
                    collection[group].push(event);
                }

                if (!event.collections.includes(collection)) {
                    event.collections.push(collection);
                }

                counter.setDate(counter.getDate() - 1);
            }
        });

        return this;
    }
}
