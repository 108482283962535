import { Inject, injectable } from 'inversify-props';
import moment from 'moment';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import COMPARED_TO_SETTINGS from '@/modules/score/constants/compared-to.settings.constant';
import METRICS_SCORE from '@/modules/score/constants/metrics-score.constant';
import SCORE_THRESHOLDS from './constants/score-thresholds.constant';
import ScoreStore from './store/score.store';
import ScoreApiService, { ScoreApiServiceS } from './score-api.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';

export const ScoreServiceS = Symbol.for('ScoreServiceS');
@injectable(ScoreServiceS as unknown as string)
export default class ScoresService {
    @Inject(ScoreApiServiceS) private scoreApiService!: ScoreApiService;
    @Inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @Inject(HelperServiceS) private helperService!: HelperService;
    @Inject(UserServiceS) private userService!: UserService;

    readonly storeState: ScoreStore = this.storeFacade.getState('ScoreStore');
    readonly thresholdColors = ['#52C161', '#8CD446', '#FFD205', '#FF9E21', '#EA7877'];

    constructor() {
        this.storeFacade.watch(
            () => this.userService.isUserLoaded,
            this.storeState.loading.reset.bind(this.storeState.loading),
        );
    }

    async loadData(): Promise<boolean> {
        const [
            scoreDocument,
            usersScoreHistory,
            usersMarketScoreHistory,
            usersChainScoreHistory,
        ] = await Promise.all([
            this.scoreApiService.getScore(),
            this.scoreApiService.getUsersScoreHistory(),
            this.scoreApiService.getUsersMarketScoreHistory(),
            this.scoreApiService.getUsersChainScoreHistory(),
        ]);

        if (scoreDocument && usersScoreHistory) {
            this.storeState.document = scoreDocument;
            this.storeState.usersScoreHistory = usersScoreHistory;
            this.storeState.usersMarketScoreHistory = usersMarketScoreHistory;
            this.storeState.usersChainScoreHistory = usersChainScoreHistory;
        }

        return true;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState;
    }

    get scoreHistory() {
        if (!this.storeState.usersScoreHistory) {
            return null;
        }
        return this.storeState.usersScoreHistory.scoreHistory;
    }

    get marketScoreHistory() {
        if (!this.storeState.usersMarketScoreHistory) {
            return [];
        }
        const arr: {date: Date, value: number | null}[] = [];

        const dataMax = this.createDataMax();
        Object.assign(dataMax, this.storeState.usersMarketScoreHistory.scoreHistory);

        Object.entries(dataMax).forEach(([key, val]) => {
            arr.push({ date: new Date(key), value: this.roundScoreData(val) });
        });
        return arr;
    }

    get chainScoreHistory() {
        if (!this.storeState.usersChainScoreHistory) {
            return [];
        }
        const arr: {date: Date, value: number | null}[] = [];

        const dataMax = this.createDataMax();
        Object.assign(dataMax, this.storeState.usersChainScoreHistory.scoreHistory);

        Object.entries(dataMax).forEach(([key, val]) => {
            arr.push({ date: new Date(key), value: this.roundScoreData(val) });
        });
        return arr;
    }

    roundScoreData(score: number | null): number | null {
        if (score === null) {
            return null;
        }
        return Math.round(Number(score));
    }

    switchComparedToGraphs(compareTo: COMPARED_TO_SETTINGS, metric?: METRICS_SCORE): (number | null)[] {
        const data = compareTo === COMPARED_TO_SETTINGS.CHAIN ? this.chainScoreHistory : this.marketScoreHistory;
        const newData: (number | null)[] = [];
        /* eslint no-unneeded-ternary: "error" */
        const metrics = metric ? metric : METRICS_SCORE.MONTH;
        const newArray = this.changeMetrics(data, metrics);

        const sortedData = newArray.sort((a, b) => Number(a.date) - Number(b.date));
        sortedData.forEach(element => newData.push(element.value));
        return newData;
    }

    createDataMax() {
        const currentDay = new Date();
        const startDate = new Date(`${currentDay.getFullYear() - 2}-${currentDay.getMonth() + 1}-${currentDay.getDate()}`);
        const obj: {[date: string]: number | null} = {};
        for (;startDate <= currentDay; startDate.setDate(startDate.getDate() + 1)) {
            obj[`${startDate.getFullYear()}-${(`0${startDate.getMonth() + 1}`).slice(-2)}-${(`0${startDate.getDate()}`).slice(-2)}`] = null;
        }
        return obj;
    }

    changeMetrics(array: {date: Date, value: number | null}[], metric: METRICS_SCORE) {
        let newArray: {date: Date, value: number | null}[] = [];
        switch (metric) {
            case METRICS_SCORE.MONTH: {
                newArray = array.slice(array.length - 30, array.length);
                return newArray;
            }
            case METRICS_SCORE.MONTH3: {
                newArray = array.slice(array.length - 90, array.length);
                return newArray;
            }
            case METRICS_SCORE.MONTH6: {
                newArray = array.slice(array.length - 180, array.length);
                return newArray;
            }
            case METRICS_SCORE.YTD: {
                const startDate = new Date(new Date().getFullYear(), 0, 1);
                const startDateIndex = array.findIndex(d => moment(startDate).isSame(d.date, 'day'));
                newArray = array.slice(startDateIndex);
                return newArray;
            }
            case METRICS_SCORE.YEAR: {
                newArray = array.slice(array.length - 365, array.length);
                return newArray;
            }
            case METRICS_SCORE.MAX: {
                return array;
            }
            default: {
                newArray = array.slice(array.length - 30, array.length);
                return newArray;
            }
        }
    }

    get scoreThresholdsRange() {
        const entries = Object.entries(SCORE_THRESHOLDS);

        const thresholds = entries.slice(0, entries.length / 2).map(([key, value], index) => {
            if (index > 0) {
                const prevValue = Number(entries[index - 1][0]);

                return {
                    name: String(value).toLowerCase(),
                    range: [Number(key), prevValue === 0 ? prevValue : prevValue + 1],
                };
            }

            return null;
        }).filter(item => item) as {
            name: string,
            range: number[],
        }[];

        return thresholds.reverse();
    }

    get scoreAssessment() {
        if (!this.data.document) {
            return null;
        }

        const { document } = this.data;
        let assessment = SCORE_THRESHOLDS[SCORE_THRESHOLDS.CRITICAL];

        if (document.score > SCORE_THRESHOLDS.GOOD) {
            assessment = SCORE_THRESHOLDS[SCORE_THRESHOLDS.EXCELLENT];
        } else if (document.score > SCORE_THRESHOLDS.FAIR) {
            assessment = SCORE_THRESHOLDS[SCORE_THRESHOLDS.GOOD];
        } else if (document.score > SCORE_THRESHOLDS.POOR) {
            assessment = SCORE_THRESHOLDS[SCORE_THRESHOLDS.FAIR];
        } else if (document.score > SCORE_THRESHOLDS.CRITICAL) {
            assessment = SCORE_THRESHOLDS[SCORE_THRESHOLDS.POOR];
        }

        return assessment;
    }

    get scoreDif() {
        if (!this.data.document) {
            return null;
        }
        return this.data && (this.data.document.newScore || null);
    }
}
