import { injectable } from 'inversify-props';

export const NotifyServiceS = Symbol.for('NotifyServiceS');
@injectable(NotifyServiceS as unknown as string)
export default class NotifyService {
    private errorNotification = (message?: string) => {};
    private warningNotification = (message?: string) => {};
    private successNotification = (message?: string) => {};
    private infoNotification = (message?: string) => {};

    init({
        errorNotification, warningNotification, successNotification, infoNotification,
    }: { errorNotification: (message?: string) => void,
        warningNotification: (message?: string) => void,
        successNotification: (message?: string) => void,
        infoNotification: (message?: string) => void, }) {
        this.errorNotification = errorNotification;
        this.warningNotification = warningNotification;
        this.successNotification = successNotification;
        this.infoNotification = infoNotification;
    }

    error(message?: string) {
        this.errorNotification(message);
    }

    warning(message?: string) {
        this.warningNotification(message);
    }

    success(message?: string) {
        this.successNotification(message);
    }

    info(message?: string) {
        this.infoNotification(message);
    }
}
